import * as React from 'react';
import LoginWrapper from '../components/loginWrapper';
import { Form, Input, Button, message } from 'antd';
import API_SERVICE from '../API/api';
import Functions from '../functions';

class RecoveryPassword extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.onInit();
    }

    onInit = () => {
        const params = new URLSearchParams(this.props.location.search);
        const hash = params.get('guid') || '';
        API_SERVICE.User_ValidRecoveryPassword(hash)
            .then((res: any) => {
                const { result } = res.data;
                if (result === false) window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;

                this.setState({ hash });
            })
            .catch((err: any) => {
                window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
            });
    }

    enterLoading = () => {
        //this.setState({ loading: true });
    };

    onCancel = () => {
        // this.setState({ loading: true });
        window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
    };

    renderTitle = () => (
        <div>
            <h2>Nueva contraseña</h2>
            <p>Por favor, establece tu nueva contraseña. Por seguridad, esta debe contener por lo menos una letra mayúscula, un número y un carácter especial.</p>
        </div>
    )

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: any) => {
            console.log(err);
            if (!err) {
                this.setState({ loading: true });
                API_SERVICE.User_RecoveryUpdatePassword({
                    email: values.email,
                    password: values.password,
                    hash: this.state.hash
                })
                    .then((res: any) => {
                        message.success(`${res.data.message}`);
                        this.setState({ loading: false });
                        setTimeout(() => {
                            window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
                        }, 1000)
                    })
                    .catch((err: any) => {
                        const errors = err.response.data.responseException.exceptionMessage;
                        const newErr = Functions.getExeceptions(errors);
                        Functions.showException(newErr);
                        this.setState({ loading: false });
                    })
            }
        });
    }

    compareToFirstPassword = (rule:any, value:any, callback:Function) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
          callback('Las contraseñas no coinciden');
        } else {
          callback();
        }
    };

    render() {

        const { getFieldDecorator } = this.props.form;

        return (
            <LoginWrapper Title={this.renderTitle}>
                <div className="form">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item label="Correo Electrónico">
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: 'El correo no puede estar vacio.' }],
                            })(
                                <Input
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="Contraseña">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'La contraseña no puede estar vacia.' }],
                            })(
                                <Input.Password
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="Repite Contraseña">
                            {getFieldDecorator('repitepassword', {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Confirme su contraseña',
                                    },
                                    {
                                        validator: this.compareToFirstPassword
                                    }
                                ],
                            })(
                                <Input.Password
                                />,
                            )}
                        </Form.Item>
                        <Form.Item className="cancel__button">
                            <Button type="primary" htmlType="button" className="cancel-form-button" onClick={this.onCancel}>
                                CANCELAR
                            </Button>
                        </Form.Item>
                        <Form.Item className="enviar__button">
                            <Button type="primary" htmlType="submit" className="enviar-form-button" loading={this.state.loading} onClick={this.enterLoading}>
                                ESTABLECER
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{ paddingBottom: '110px' }} />
            </LoginWrapper>
        )
    }

}

export default Form.create({ name: 'recovery-password' })(RecoveryPassword);