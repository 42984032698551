declare var window: any;
export var API_URL = window.config.apiUrl;

export const USER_LOGIN = 'v1/admin/user/login/';
export const USER_RECOVERY_PASSWORD = 'v1/admin/user/recovery-password/';
export const USER_VALID_ACTIVE_ACCOUNT = 'v1/admin/user/valid-activate-account/';
export const USER_VALID_RECOVERY_PASSWORD = 'v1/admin/user/valid-recovery-password/';
export const USER_RECOVERY_UPDATE_PASSWORD = 'v1/admin/user/recovery-update-password/';
export const USER_UPDATE_PASSWORD = 'v1/admin/user/update-password';
export const USER_ACTIVE_ACCOUNT = 'v1/admin/user/activate-account';

export const INTERNAL_PAGE_METADATA_LIST = 'v1/admin/internal-page/metadata-lista/';
export const INTERNAL_PAGE_LIST = 'v1/admin/internal-page/list/';
export const INTERNAL_PAGE_GET = 'v1/admin/internal-page/get-full/';
export const INTERNAL_PAGE_CREATE_INIT = 'v1/admin/internal-page/create-init';
export const INTERNAL_PAGE_UPDATE = 'v1/admin/internal-page/update/';
export const INTERNAL_PAGE_CREATE = 'v1/admin/internal-page/create/';
export const INTERNAL_PAGE_HIERARCHICALLIST = 'v1/admin/internal-page/hierarchicalList';
export const INTERNAL_PAGE_DELETE = 'v1/admin/internal-page/batch-delete';
export const INTERNAL_PAGE_TYPE = 'v1/admin/internal-page/list-pages-types';
export const INTERNAL_PAGE_EXPORT_EXCEL = 'v1/admin/internal-page/export-excel/';

export const PAGE_ZONE_GROUP_METADATA_LIST = 'v1/admin/page-zone/group/metadata-lista';
export const PAGE_ZONE_GROUP_EXPORT_EXCEL = 'v1/admin/page-zone/export-excel/';
export const PAGE_ZONE_GROUP_LIST = 'v1/admin/page-zone/group/list';
export const PAGE_ZONE_GROUP_CREATE = 'v1/admin/page-zone/group/create/';
export const PAGE_ZONE_GROUP_UPDATE = 'v1/admin/page-zone/group/update/';
export const PAGE_ZONE_GROUP_GET = 'v1/admin/page-zone/group/get/';
export const PAGE_ZONE_GROUP_DELETE = 'v1/admin/page-zone/batch-delete';
export const PAGE_ZONE_GROUP_CREATE_INIT = 'v1/admin/page-zone/create-init';
export const PAGE_ZONE_METADATA_LIST = 'v1/admin/page-zone/metadata-lista';
export const PAGE_ZONE_CREATE = 'v1/admin/page-zone/create/';
export const PAGE_ZONE_CREATE_INIT = 'v1/admin/page-zone/create-init/';
export const PAGE_ZONE_UPDATE = 'v1/admin/page-zone/update/';
export const PAGE_ZONE_GET = 'v1/admin/page-zone/get/';
export const PAGE_ZONE_DELETE = 'v1/admin/page-zone/batch-delete/';
export const PAGE_ZONE_LIST = 'v1/admin/page-zone/list/';
export const PAGE_ZONE_EXPORT_EXCEL = 'v1/admin/page-zone/export-excel-content/';
export const PAGE_ZONE_ORDER_CONTENT = 'v1/admin/page-zone/order-content';

export const BANNER_ORDER_CONTENT = 'v1/admin/banner/order-content';
export const BANNER_CREATE = 'v1/admin/banner/create';
export const BANNER_UPDATE = 'v1/admin/banner/update';
export const BANNER_GET = 'v1/admin/banner/get/';
export const BANNER_DELETE = 'v1/admin/banner/batch-delete/';
export const BANNER_LIST = 'v1/admin/banner/list';
export const BANNER_CREATE_INIT = 'v1/admin/banner/create-init/';

export const BANNER_EXPORT_EXCEL = 'v1/admin/banner/export-excel';
export const BANNER_EXPORT_EXCEL_CONTENT = 'v1/admin/banner/export-excel-content/';
export const BANNER_METADATA_LIST = 'v1/admin/banner/metadata-lista';
export const BANNER_GROUP_METADATA_LIST = 'v1/admin/banner/metadata-group';
export const BANNER_GROUP_LIST = 'v1/admin/banner/group/list';
export const BANNER_GROUP_CREATE = 'v1/admin/banner/group/create';
export const BANNER_GROUP_UPDATE = 'v1/admin/banner/group/update';
export const BANNER_GROUP_GET = 'v1/admin/banner/group/get-full/';
export const BANNER_GROUP_DELETE = 'v1/admin/banner/group/batch-delete';
export const BANNER_GROUP_CREATE_INIT = 'v1/admin/banner/group/create-init';

export const NEWS_DUPLICATE = 'v1/admin/news/duplicate/';
export const NEWS_METADATA_LIST = 'v1/admin/news/metadata-lista';
export const NEWS_LIST = 'v1/admin/news/list';
export const NEWS_CREATE = 'v1/admin/news/create';
export const NEWS_UPDATE = 'v1/admin/news/update';
export const NEWS_GET = 'v1/admin/news/get-full/';
export const NEWS_CREATE_INIT = 'v1/admin/news/create-init';
export const NEWS_DELETE = 'v1/admin/news/batch-delete';
export const NEWS_EXPORT_EXCEL = 'v1/admin/news/export-excel/'
export const PAGE_ZONE_CATEGORY = 'v1/admin/news/categories';
export const NEWS_SETTINGS = 'v1/admin/news/settings';
export const NEWS_UPDATE_SETTINGS = 'v1/admin/news/update-settings';


export const GALLERY_CATEGORY_METADATALIST = 'v1/admin/gallery/category/metadata-lista';
export const GALLERY_CATEGORY_LIST = 'v1/admin/gallery/category/list';
export const GALLERY_CATEGORY_CREATE = 'v1/admin/gallery/category/create';
export const GALLERY_CATEGORY_UPDATE = 'v1/admin/gallery/category/update';
export const GALLERY_CATEGORY_BATCH_DELETE = 'v1/admin/gallery/category/batch-delete';
export const GALLERY_CATEGORY_EXPORT_EXCEL = 'v1/admin/gallery/category/export-excel';

export const GALLERY_SETTINGS = 'v1/admin/gallery/settings';
export const GALLERY_UPDATE_SETTINGS = 'v1/admin/gallery/update-settings';

export const GALLERY_ORDER_CONTENT = 'v1/admin/gallery/order-content';
export const GALLERY_CATEGORIES = 'v1/admin/gallery/categories';
export const GALLERY_CREATE_INIT = 'v1/admin/gallery/create-init';
export const GALLERY_GET = 'v1/admin/gallery/get-full/';
export const GALLERY_CREATE = 'v1/admin/gallery/create';
export const GALLERY_UPDATE = 'v1/admin/gallery/update';
export const GALLERY_DELETE = 'v1/admin/gallery/batch-delete';
export const GALLERY_LIST = 'v1/admin/gallery/list';
export const GALLERY_METADATA_LIST = 'v1/admin/gallery/metadata-lista';
export const GALLERY_CONTENT_GET = 'v1/admin/gallery/content/get/';
export const GALLERY_CONTENT_CREATE = 'v1/admin/gallery/content/create';
export const GALLERY_CONTENT_UPDATE = 'v1/admin/gallery/content/update';
export const GALLERY_CONTENT_DELETE = 'v1/admin/gallery/content/batch-delete/';
export const GALLERY_CONTENT_LIST = 'v1/admin/gallery/content/list';
export const GALLERY_CONTENT_METADATA_LIST = 'v1/admin/gallery/content/metadata-lista';
export const GALLERY_CONTENT_LIST_CONTENT_TYPES = 'v1/admin/gallery/content/list-content-types';
export const GALLERY_EXPORT_EXCEL = 'v1/admin/gallery/export-excel/';
export const GALLERY_CONTENT_EXPORT_EXCEL = 'v1/admin/gallery/content/export-excel/';


export const EVENT_CREATE_INIT = 'v1/admin/events/create-init';
export const EVENT_GET = 'v1/admin/events/get-full/';
export const EVENT_CREATE = 'v1/admin/events/create';
export const EVENT_UPDATE = 'v1/admin/events/update';
export const EVENT_DELETE = 'v1/admin/events/batch-delete';
export const EVENT_METADATA_LIST = 'v1/admin/events/metadata-lista';
export const EVENT_LIST = 'v1/admin/events/list';
export const EVENT_CATEGORY_LIST = 'v1/admin/events/categories';
export const EVENT_EXPORT_EXCEL = 'v1/admin/events/export-excel/';
export const EVENT_SETTINGS = 'v1/admin/events/settings';
export const EVENT_UPDATE_SETTINGS = 'v1/admin/events/update-settings';


export const HTML_TEMPLATE_GET = 'v1/admin/html-template/get-full/';
export const HTML_TEMPLATE_CREATE_INIT = 'v1/admin/html-template/create-init';
export const HTML_TEMPLATE_CREATE = 'v1/admin/html-template/create';
export const HTML_TEMPLATE_UPDATE = 'v1/admin/html-template/update';
export const HTML_TEMPLATE_DELETE = 'v1/admin/html-template/batch-delete';
export const HTML_TEMPLATE_METADATA_LIST = 'v1/admin/html-template/metadata-lista';
export const HTML_TEMPLATE_LIST = 'v1/admin/html-template/list';
export const HTML_TEMPLATE_LIST_MODULES = 'v1/admin/html-template/list-modules';
export const HTML_TEMPLATE_LIST_BY_MODULES = 'v1/admin/html-template/list-by-module';
export const HTML_TEMPLATE_EXPORT_EXCEL = 'v1/admin/html-template/export-excel';


export const EMAIL_TEMPLATE_CREATE_INIT = 'v1/admin/email-template/create-init'
export const EMAIL_TEMPLATE_GET = 'v1/admin/email-template/get-full/';
export const EMAIL_TEMPLATE_CREATE = 'v1/admin/email-template/create';
export const EMAIL_TEMPLATE_UPDATE = 'v1/admin/email-template/update';
export const EMAIL_TEMPLATE_DELETE = 'v1/admin/email-template/batch-delete';
export const EMAIL_TEMPLATE_LIST = 'v1/admin/email-template/list';
export const EMAIL_TEMPLATE_METADATA_LIST = 'v1/admin/email-template/metadata-lista';
export const EMAIL_TEMPLATE_EXPORT_EXCEL = 'v1/admin/email-template/export-excel';


export const REPORTS_MODULES = 'v1/reports/modules';

export const REPORTS_ACCESS_LIST = 'v1/reports/access/list';
export const REPORTS_ACCESS_GET = 'v1/reports/access/get/';
export const REPORTS_ACCESS_EXPORT_PDF = 'v1/reports/access/export-pdf';
export const REPORTS_ACCESS_EXPORT_EXCEL = 'v1/reports/access/export-excel';
export const REPORTS_ACCESS_METADATA_LIST = 'v1/reports/access/metadata-lista';

export const REPORTS_AUDIT_LIST = 'v1/reports/audit/list';
export const REPORTS_AUDIT_GET = 'v1/reports/audit/get/';
export const REPORTS_AUDIT_EXPORT_PDF = 'v1/reports/audit/export-pdf';
export const REPORTS_AUDIT_EXPORT_EXCEL = 'v1/reports/audit/export-excel';
export const REPORTS_AUDIT_METADATA_LIST = 'v1/reports/audit/metadata-lista';


export const FORM_GET_REGISTRY_FILE = 'v1/admin/form/get-registry-filebase64';
export const FORM_GET = 'v1/admin/form/get-full/';
export const FORM_CREATE_INIT = 'v1/admin/form/create-init';
export const FORM_CREATE = 'v1/admin/form/create-full';
export const FORM_UPDATE = 'v1/admin/form/update-full';
export const FORM_DELETE = 'v1/admin/form/batch-delete';
export const FORM_LIST = 'v1/admin/form/list';
export const FORM_METADATA_LIST = 'v1/admin/form/metadata-lista';

export const FORM_SECTION_LIST = 'v1/admin/form/section/list/';
export const FORM_SECTION_GET = 'v1/admin/form/section/get/';
export const FORM_SECTION_CREATE = 'v1/admin/form/section/create';
export const FORM_SECTION_UPDATE = 'v1/admin/form/section/update';
export const FORM_SECTION_DELETE = 'v1/admin/form/section/delete/';

export const FORM_FIELD_LIST = 'v1/admin/form/field/list/';
export const FORM_FIELD_GET = 'v1/admin/form/field/get/';
export const FORM_FIELD_CREATE = 'v1/admin/form/field/create';
export const FORM_FIELD_UPDATE = 'v1/admin/form/field/update';
export const FORM_FIELD_DELETE = 'v1/admin/form/field/delete/';

export const FORM_CONDITION_LIST = 'v1/admin/form/condition/list/';
export const FORM_CONDITION_GET = 'v1/admin/form/condition/get/';
export const FORM_CONDITION_CREATE = 'v1/admin/form/condition/create';
export const FORM_CONDITION_UPDATE = 'v1/admin/form/condition/update';
export const FORM_CONDITION_DELETE = 'v1/admin/form/condition/delete/';

export const FORM_CONDITIONED_LIST = 'v1/admin/form/conditioned/list/';
export const FORM_CONDITIONED_GET = 'v1/admin/form/conditioned/get/';
export const FORM_CONDITIONED_CREATE = 'v1/admin/form/conditioned/create';
export const FORM_CONDITIONED_UPDATE = 'v1/admin/form/conditioned/update';
export const FORM_CONDITIONED_DELETE = 'v1/admin/form/conditioned/delete/';

export const FORM_EMAIL_LIST = 'v1/admin/form/email/list/';
export const FORM_EMAIL_GET = 'v1/admin/form/email/get/';
export const FORM_EMAIL_CREATE = 'v1/admin/form/email/create';
export const FORM_EMAIL_UPDATE = 'v1/admin/form/email/update';
export const FORM_EMAIL_DELETE = 'v1/admin/form/email/delete/';

export const FORM_LISTS_METADATA = 'v1/admin/form/metadata-lista-lista';
export const FORM_LISTS_LIST = 'v1/admin/form/lists/list';
export const FORM_LISTS_GET = 'v1/admin/form/lists/get/';
export const FORM_LISTS_CREATE_INIT = 'v1/admin/form/lists/create-init/';
export const FORM_LISTS_CREATE = 'v1/admin/form/lists/create';
export const FORM_LISTS_UPDATE = 'v1/admin/form/lists/update';
export const FORM_LISTS_DELETE = 'v1/admin/form/lists/batch-delete';
export const FORM_LISTS_EXPORT = 'v1/admin/form/lists/export-excel';
export const FORM_LISTS_IMPORT_EXCEL = 'v1/admin/form/lists/import-excel';

export const FORM_CONTROL_TYPE = 'v1/admin/form/list-control-type';
export const FORM_DATA_TYPE = 'v1/admin/form/list-data-type';
export const FORM_LIST_BEHAVIOR = 'v1/admin/form/list-behavior';
export const FORM_LIST_REGISTRY = 'v1/admin/form/list-registry/';
export const FORM_GET_PREVIEW = 'v1/admin/form/get-preview/';
export const FORM_GENERATE_FORM = 'v1/admin/form/generate-form/';

export const FORM_REGISTRY_EXPORT_EXCEL = 'v1/admin/form/registry-export-excel/';
export const FORM_EXPORT_EXCEL = 'v1/admin/form/export-excel/';

export const RECYCLE_LIST = 'v1/super/recyclebin/list';
export const RECYCLE_RETRIEVE_ITEM = 'v1/super/recyclebin/retrieve-item';
export const RECYCLE_BATCH_RETRIEVE = 'v1/super/recyclebin/batch-retrieve';
export const RECYCLE_METADATA_LIST = 'v1/super/recyclebin/metadata-lista';
export const RECYCLE_EXPORT_EXCEL = 'v1/super/recyclebin/export-excel';
export const RECYCLE_PERMANENTLY_DELETE = 'v1/super/recyclebin/batch-delete';
export const RECYCLE_MODULES = 'v1/super/recyclebin/modules';

export const ADMIN_LIST = 'v1/super/admin/list';
export const ADMIN_METADATA = 'v1/super/admin/metadata-lista';
export const ADMIN_REGISTER = 'v1/super/admin/register';
export const ADMIN_UPDATE = 'v1/super/admin/update';
export const ADMIN_GET = 'v1/super/admin/';
export const ADMIN_DELETE = 'v1/super/admin/';
export const ADMIN_BATCH_DELETE = 'v1/super/admin/batch-delete';
export const ADMIN_ASSIGN_ROLES = 'v1/super/admin/assign-roles';
export const ADMIN_UPDATE_ROLES = 'v1/super/admin/update-roles';
export const ADMIN_EXPORT_EXCEL = 'v1/super/admin/export-excel';

export const ROL_LIST = 'v1/super/admin/rol/list';
export const ROL_METADATA = 'v1/super/admin/rol/metadata-lista';
export const ROL_CREATE = 'v1/super/admin/rol/create';
export const ROL_CREATE_INIT = 'v1/super/admin/rol/create-init';
export const ROL_UPDATE = 'v1/super/admin/rol/update';
export const ROL_GET = 'v1/super/admin/rol/';
export const ROL_BATCH_DELETE = 'v1/super/admin/rol/batch-delete';
export const ROL_EXPORT = 'v1/super/admin/rol/export-excel';
export const ROL_MODULE_ITEMS = 'v1/super/admin/rol/module-items/';

export const ADMIN_BATCH_PRE_ACTIVE = 'v1/super/admin/batch-pre-activate';
export const ADMIN_ACTIVE_ACCOUNT = 'v1/super/admin/activate-account';



export const ELEARNING_CANVAS_COURSE_SYNCHRONIZE = 'v1/elms/canvas/synchronize-courses';
export const ELEARNING_CANVAS_COURSE_LIST = 'v1/elms/canvas/courses';
export const ELEARNING_CANVAS_COURSE_GET = 'v1/elms/canvas/course-detail/';
export const ELEARNING_CANVAS_COURSE_UPDATE = 'v1/elms/canvas/update-course';
export const ELEARNING_CANVAS_COURSE_METADATA = 'v1/elms/canvas/metadata-courses';
export const ELEARNING_CANVAS_COURSE_EXPORT_EXCEL = 'v1/elms/canvas/export-excel-courses';

export const ELEARNING_COURSES_METADATA = 'v1/elms/metadata-courses';
export const ELEARNING_COURSES_LIST = 'v1/elms/courses';
export const ELEARNING_COURSES_GET = 'v1/elms/course-detail/';
export const ELEARNING_COURSES_UPDATE = 'v1/elms/update-course';
export const ELEARNING_COURSES_EXPORT_EXCEL = 'v1/elms/export-excel-courses';
export const ELEARNING_COURSES_SYNCHRONIZE = 'v1/elms/synchronize-courses';
export const ELEARNING_COURSES_ASSIGN = 'v1/elms/assign-courses/';

export const ELEARNING_GROUP_METADATA = 'v1/elms/metadata-groups';
export const ELEARNING_GROUP_LISTA = 'v1/elms/groups';
export const ELEARNING_GROUP_GET = 'v1/elms/group-detail/';
export const ELEARNING_GROUP_CREATE_INIT = 'v1/elms/create-group-init';
export const ELEARNING_GROUP_UPDATE = 'v1/elms/update-group';
export const ELEARNING_GROUP_CREATE = 'v1/elms/create-group';
export const ELEARNING_GROUP_DELETE = 'v1/elms/batch-delete-group';
export const ELEARNING_GROUP_BATCH_DELETE = 'v1/elms/create-group';
export const ELEARNING_GROUP_EXPORT_EXCEL = 'v1/elms/export-excel-groups';


export const MASTER_PAGE_HOME_GET = 'v1/admin/home/get/';
export const MASTER_PAGE_HOME_ASSIGNED_ELEMENT = 'v1/admin/home/assigned-element';
export const MASTER_PAGE_HOME_UPDATE = 'v1/admin/home/update-data';
export const MASTER_PAGE_HOME_LIST = 'v1/admin/home/list';
export const MASTER_PAGE_HOME_EXPORT_EXCEL = 'v1/admin/home/export-excel';
export const MASTER_PAGE_HOME_METADATA = 'v1/admin/home/metadata-lista';
export const MASTER_PAGE_HOME_UPDATE_COOKIES = 'v1/admin/home/update-cookies';
export const MASTER_PAGE_HOME_MENU_LIST_ITEM = 'v1/admin/home/menu/list-items/';
export const MASTER_PAGE_HOME_MENU_COPY_MENU = 'v1/admin/home/copy-menu/';
export const MASTER_PAGE_HOME_MENU_ADD_MENU = 'v1/admin/home/save-menu';
export const MASTER_PAGE_HOME_MENU_UPDATE_MENU = 'v1/admin/home/update-menu';


export const SETTINGS_LIST_RRSS = 'v1/super/settings/list-rrss';
export const SETTINGS_LIST = 'v1/super/settings/list';
export const SETTINGS_UPDATE = 'v1/super/settings/update';
export const SETTINGS_UPDATE_GENERAL = 'v1/super/settings/update-general/';
export const SETTINGS_UPDATE_TAGS = 'v1/super/settings/update-tags/';
export const SETTINGS_EXPORT = 'v1/super/settings/export-excel-rrss';

export const UPLOAD = 'v1/admin/file/upload-file';

export const COMMON_CANCEL_CREATION = 'v1/admin/common/cancel-creation';
