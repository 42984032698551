import React from 'react';
import ModuleList from '../components/dashboard/moduleList';
import { PrivateRouteAdmin } from '../components/privateRouter';
import { Route, Switch } from 'react-router-dom';
import Admin from './admin';
import * as Components from '../modules/loadable';
import RoutesELearning from './e-learning';
import errorNotFound from '../modules/others/errorNotFound';
import TemplateBase from '../components/global/templateBase';

const Dashboard = (props:any) => {
    return (
        <TemplateBase>
            <Switch>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`} component={Components.MiDatos} />
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_CAMBIAR_CONTRASENA}`} component={Components.ChangePassword} />
                
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_WIDGETS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.PageZoneCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_WIDGETS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.PageZoneCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_WIDGETS}`} component={ Components.ListPageZone }/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PAGINAS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}/:parentId`} component={Components.InternalPageCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PAGINAS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.InternalPageCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PAGINAS}`} component={ Components.ListInternalPage }/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_NOTICIAS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.NewsCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_NOTICIAS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.NewsCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_NOTICIAS}`} component={Components.ListNews}/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EVENTOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.EventCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EVENTOS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.EventCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EVENTOS}`} component={Components.ListEvent}/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_GALERIA}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.GalleryCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_GALERIA}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.GalleryCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_GALERIA}`} component={Components.ListGallery}/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_BANNER}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={ Components.BannerCreateEdit }/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_BANNER}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={ Components.BannerCreateEdit }/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_BANNER}`} component={Components.ListBanner}/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EMAIL_TEMPLATE}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.EmailTemplateCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EMAIL_TEMPLATE}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.EmailTemplateCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EMAIL_TEMPLATE}`} component={Components.ListEmailTemplate}/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_HTML_TEMPLATE}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.HtmlTemplateCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_HTML_TEMPLATE}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.HtmlTemplateCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_HTML_TEMPLATE}`} component={Components.ListHtmlTemplate}/>

                <Route path='/master-page-home/:id' component={Components.MasterPageConfiguration}/>
                <Route path='/master-page-home' component={Components.MasterPage}/>
                
                {/*-----------------------FORM------------------------------------ */}

                <Route exact path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO_LISTS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.FormListForm}/>
                <Route exact path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO_LISTS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={Components.FormListForm}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={Components.FormCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`}  component={Components.FormCreateEdit}/>
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}`} component={Components.Form}/>

                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_REPORTS}`} component={Components.Reports}/>
                {/*--------------------------------------------------------------- */}
                <PrivateRouteAdmin path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}`} component={ Admin }/>
                <Route path="/e-learning" component={RoutesELearning}/>
                
                <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`} component={ModuleList}/>


                <Route component={errorNotFound}/>
            </Switch>
        </TemplateBase>
    );
}

export default Dashboard;