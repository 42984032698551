import React from 'react';
import { Helmet } from "react-helmet";
import header from './../../constants/header';

interface IHeader {
    title: string,
    action?: string
};

export const HeaderHelmet = (props:IHeader) => {
    return (
        <Helmet>
            <title>{props.title} - {header.proyect}</title>
        </Helmet>
    )
}

export const HeaderHelmetAction = (props:IHeader) => {
    return (
        <Helmet>
            <title>{props.title} {header.signo} {props.action} - {header.proyect}</title>
        </Helmet>
    )
}