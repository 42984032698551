import ActionType from "../constants";

const initialState = [{}]

const RHtmlTemplate = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.HTMLTEMPLATE_ADD_GET: 
            return {
                ...state,
                onlyOne: action.payload
            }
        case ActionType.HTMLTEMPLATE_ADD_LIST:
            return {
                ...state,
                list: action.payload.items || action.payload,
                count: action.payload.total
            }
        case ActionType.HTMLTEMPLATE_ADD_METADATA_LIST:
            return {
                ...state,
                metadata: action.payload
            }
        case ActionType.HTMLTEMPLATE_ADD_LIST_MODULES:
            return {
                ...state,
                listModule: action.payload
            }
        default: 
            return state
    }
}

export default RHtmlTemplate;