import { message } from "antd";

export const replaceData = (data:any, module: string) => {
    const DataReplace = DataReplaceModules[module];
    DataReplace.forEach((item:any) => {
        if(!data[item.content]){
            data[item.content] = data[item.replace];
        }
    })
}

export const redirectAfterSubmitInFormModules = (props:any) => {
    message.success(props.response.message);
    if(props.actionTypeNumber === 1) { //type save
        return props.history.replace(props.urls.URL_LIST);
    } 
    else if(props.actionTypeNumber === 2) { //type save and edit
        return props.history.replace(props.urls.URL_EDIT);
    }
    else { //type save and create
        window.scrollTo(0, 0)
        return props.history.replace(props.urls.URL_CREATE);
    }
}

export const convertTextTitleToSlug = (textTitle: string) => {
    let textWithoutAccents = textTitle.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let convertTextAlfaNumeric = textWithoutAccents.replaceAll(/[^A-Za-z0-9ñ ]/g, '');
    let converTextSpaceXScreenplay = convertTextAlfaNumeric.replaceAll(/ /g, '-');
    return converTextSpaceXScreenplay.toLowerCase().toString();
}

export const dateToDayWithoutFormat = () => {
    const fecha = new Date();
    let Month = fecha.getMonth() + 1;
    let newFormatMonth = Month < 10 ? `0${Month}` : Month;
    let Day = fecha.getDate();
    let newFormatDate = Day < 10 ? `0${Day}` : Day;
    return `${fecha.getFullYear()}${newFormatMonth}${newFormatDate}`;
}

export const getBase64 = (file: File) => {
    return new Promise(resolve => {

        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
            const data:any = reader.result;
            const index = data.indexOf("base64,");
            let result:String = "";
            if(index !== -1) {
                result = data.substring((index + 7))
            }
            resolve(result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    });
}

const DataReplaceModules:any = {
    generic: [
        {
            content: 'navigatortitle',
            replace: 'title'
        },
        {
            content: 'rrsstitle',
            replace: 'title'
        },
        {
            content: 'rrssimage',
            replace: 'mainphotourl'
        }
    ],
    internalPage: [],
    news: [],
    events: [],
    gallery: [
        {
            content: 'navigatortitle',
            replace: 'title'
        },
        {
            content: 'ssrrtitle',
            replace: 'title'
        },
        {
            content: 'rrssimage',
            replace: 'mainphotourl'
        }
    ],
    form: []

}