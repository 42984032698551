import React, { Key, Suspense } from 'react';
import ModuleButton from './moduleButton';
import { HeaderHelmet } from '../global/Helmet';
import header from '../../constants/header';
import { Icon } from 'antd'; 

const logoMagia = 'logo';

class ModuleList extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            dashboard: {
                normal: [],
                superA: {

                }
            }
        }
    }

    componentDidMount() {
        const modules = (JSON.parse((localStorage.getItem('user') || {}).toString()))?.user_dashboard || null;
        modules && this.setState({ dashboard: modules.dashboard });
    }

    onNavigate = (route?: string) => {
        if (route !== undefined && route !== null) {
            window.location.href = route;
            //this.props.history.replace(route)
            // let history = useHistory('/' + route);
            // history.push('/' + route);
        }
    }

    render() {
        const { dashboard: { normal } } = this.state;

        return (
            <>
                <HeaderHelmet title={header.dashboard} />
                <div className="title__zone">
                    <div className="logo">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Icon component={React.lazy(() => import(`./../../assets/img/${logoMagia}.svg`))} />
                        </Suspense>
                    </div>
                    <div className="title">
                        <h2>Fénix CMS</h2>
                        <h3>Dashboard</h3>
                    </div>
                </div>
                <div className="mainbody">
                    <div className="button__more__using">
                        <div className="row">
                            {
                                normal.map((item: any, i: Key) =>
                                    <ModuleButton key={i}
                                        Image={item.name}
                                        Text={item.label}
                                        Action={this.onNavigate}
                                        Url={item.url}
                                    />
                                )
                            }
                        </div>
                    </div>
                    {/* <div className="button__less__using">
                        {
                            cuadrosPequenos.map((item: any, i: Key) =>
                                <ModuleButton key={i}
                                    Image={item.name}
                                    Text={item.label}
                                    Action={this.onNavigate}
                                    Url={item.url}
                                />
                            )
                        }
                    </div> */}
                </div>
            </>
        )
    }
}

export default ModuleList;