import ActionType from "../constants";

const initialState = {
    metadata: [],
    list: [],
    total: 0
}

const RZonePage = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.PAGE_ZONE_GROUP_ADD_METADATA_LIST :
            return {
                ...state,
                metadata: action.payload
            };
        case ActionType.PAGE_ZONE_GROUP_ADD_LIST : 
            return {
                ...state,
                list: action.payload.items,
                total: action.payload.total
            };
        case ActionType.PAGE_ZONE_GROUP_GET : 
            return {
                ...state,
                data : action.payload
            }
        case ActionType.PAGE_ZONE_GROUP_ADD_CREATE:
            return {
                ...state,
                id : action.payload
            }


        case ActionType.PAGE_ZONE_ADD_LIST:
            return {
                ...state,
                list_: action.payload.items,
                total_: action.payload.total,
            }
        case ActionType.PAGE_ZONE_ADD_METADATALIST:
            return {
                ...state,
                metadata_:action.payload,
            }
        case ActionType.PAGE_ZONE_ADD_GET :
            return {
                ...state,
                onlyOne : action.payload,
            }
        default:
        return state;
    }
}

export default RZonePage;  