import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as Actions from '../actions';
import Functions from '../../functions';

function* EMasterPage_MetadataList(action:any):any {
    try{
        const metadata = (yield call(API_SERVICE.MASTER_PAGE_HOME_METADATA)).data.result || [];
        const data = (yield call(API_SERVICE.MASTER_PAGE_HOME_LIST)).data.result || [];
        yield put(Actions.AMASTERPAGE_ADD_METADATA(metadata));
        yield put(Actions.AMASTERPAGE_ADD_LIST(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EMasterPage_Get(action:any):any {
    try{
        const data = (yield call(API_SERVICE.MASTER_PAGE_HOME_GET, action.payload)).data.result;
        const jsonScheme:Array<any> = [];

        //armar plantilla para el formulario dinámico
        if(data?.masterPage.general){

            const jsonMetadata = data.masterPage.general.jsonMetadata;
            const keys = Object.keys(jsonMetadata);
            let type:any = {
                0: '<h3> &nbsp;</h3>',
                1: '<h3>LATERAL IZQUIERDO</h3>',
                2: '<h3>LATERAL DERECHO</h3>',
                3: '<h3>FOOTER</h3>',
            }
            
            keys?.map((item, index:number) => {

                const temp = jsonMetadata[item];
                const FieldsKey = Object.keys(temp);


                const itemTemp:any = {
                    sectionHeader: type[index] || '',
                    sectionFooter: '',
                    sectionFields: []
                }

                FieldsKey?.map((key) => {
                    const findField = temp[key];
                    const tempField = {
                        id: key,
                        behaviour: findField.behaviour || '01', 
                        label: findField.label,
                        //value: findField.value,
                        type: findField.controlType || 'text',
                        placeholder: key,
                        validationType: 'string',
                        validations: findField.validations || []
                    }
                    itemTemp.sectionFields.push(tempField);
                })

                jsonScheme.push(itemTemp);
            })
        }

        //setear valores
        data.templateJsonScheme = { jsonScheme };
        data.values = {...data.masterPage.general.jsonData}; 
        data.id = action.payload;

        //guardar en el reducer
        yield put(Actions.AMASTERPAGE_ADD_GET(data));
        //aceptar la promesa como válido
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EMasterPage_Update(action:any):any {
    try{
        const message = (yield call(API_SERVICE.MASTER_PAGE_HOME_UPDATE, action.payload)).data.message;
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EMasterPage_AssignedElement(action:any):any {
    try{
        const message = (yield call(API_SERVICE.MASTER_PAGE_HOME_ASSIGNED_ELEMENT, action.payload)).data.message;
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EMasterPage_ExportExcel(action:any):any {
    try{
        const data = (yield call(API_SERVICE.MASTER_PAGE_HOME_EXPORT_EXCEL)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WMasterPage_Get():any { return yield takeLatest( ActionType.MASTER_PAGE_CALL_GET, EMasterPage_Get); }
export function* WMasterPage_AssignedElement():any { return yield takeLatest( ActionType.MASTER_PAGE_ASSIGNED_ELEMENT, EMasterPage_AssignedElement); }
export function* WMasterPage_ExportExcel():any { return yield takeLatest( ActionType.MASTER_PAGE_CALL_EXPORT_EXCEL, EMasterPage_ExportExcel); }
export function* WMasterPage_Update():any { return yield takeLatest( ActionType.MASTER_PAGE_CALL_UPDATE, EMasterPage_Update); }
export function* WMasterPage_MetadataList():any { return yield takeLatest( ActionType.MASTER_PAGE_CALL_METADATA, EMasterPage_MetadataList); }