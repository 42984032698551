import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Login from './login';
import Dashboard from './dashboard';
import RecoveryPassword from './recoveryPassword';
import ForgotPassword from './forgotPassword';
import UpdatePassword from './updatePassword';
import { PrivateRoute } from '../components/privateRouter';
import './../assets/style/general.scss';

class Master extends React.Component<any, any> {

    render() {
        return (
                <Switch>
                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`} component={ Login }/>
                    <Route exact path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ACTIVAR_CUENTA}`} component={ UpdatePassword }/>
                    <Route exact path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_RECUPERAR_CUENTA}`} component={ RecoveryPassword }/>
                    <Route exact path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PERDIO_CUENTA}`} component={ ForgotPassword }/>
                    <Redirect from="/" exact to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN}`}/> 
                    <PrivateRoute path="/" component={ Dashboard }/>
                    {/* <Route exact path="/" component={Home}/> */}
                    {/* <Route path="/about" component={About}/>
                    <Route path="/:user" component={User}/>
                    <Route component={NoMatch}/> */}
                </Switch>
        );
    }
}

export default Master;