import axios from 'axios';

const header = {
    headers : {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
}

export const METHOD_POST_WITOUT_AUTH = (url:string, body:any) => {
    return new Promise((resolve:Function, reject:Function) => {
        axios.post(url, body, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
}

export const METHOD_POST = (url:string, body:any) => {
    return new Promise((resolve:Function, reject:Function) => {
        axios.post(url, body, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
}

export const METHOD_POST_PARAMS = (url:string, body:any) => {
    return new Promise((resolve:Function, reject:Function) => {
        axios.post(url, {}, {...header, params: body})
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
}

export const METHOD_GET = (url:string) => {
    return new Promise((resolve:Function, reject:Function) => {
        axios.get(url, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
}

export const METHOD_UPDATE = (url:string, body:object) => {
    return new Promise((resolve:Function, reject:Function) => {
        axios.post(url, body, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
}

export const METHOD_DELETE = (url:string) => {
    return new Promise((resolve:Function, reject:Function) => {
        axios.delete(url, header)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            reject(err)
        })
    }) 
}

export const METHOD_MULTIPLE_DELETE = (url:string, ids:Array<number>) => {
    const payload = {data: ids, ...header};
    return new Promise((resolve:Function, reject:Function) => {
        axios.delete(url, payload)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })  
}