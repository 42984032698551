import ActionType from "../constants";

const initialState = [{}]

const REmailTemplate = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.EMAILTEMPLATE_ADD_GET: 
            return {
                ...state,
                onlyOne: action.payload
            }
        case ActionType.EMAILTEMPLATE_ADD_LIST:
            return {
                ...state,
                list: action.payload.items,
                total: action.payload.total
            }
        case ActionType.EMAILTEMPLATE_ADD_METADATA_LIST:
            return {
                ...state,
                metadata: action.payload
            }
        default: 
            return state
    }
}

export default REmailTemplate;