import React, { Suspense } from 'react';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';

interface IModuleButton {
    Image?: string;
    Text: string;
    Action: Function;
    Url?: string;
    key: any;
}

const existImage = (Image: string) => {
    var fileImage: any = {
        fileImage: null,
        default: false
    };

    try {
        fileImage.fileImage = require(`./../../assets/icons/${Image.toLowerCase()}.svg`);
        //import(`./../../assets/icons/${Image}.svg`).then((res) => fileImage = res);
    }
    catch (e) {
        //fileImage.fileImage = require('./../../assets/icons/default.svg');
        fileImage.default = true;
    }

    return fileImage;
}

const ModuleButton = (props: IModuleButton) => {
    //onClick={ () => props.Action(props.Url) }
    const res = (existImage(props.Image || ''));

    return (
        <Link to={props.Url + ""} key={props.key}>
            <button type="button">
                {props.Image !== null ?
                    (
                        res.default === false ? 
                        <Suspense fallback={<div>Loading...</div>}>
                            <Icon style={{width: '40px'}} component={React.lazy(() => import(`./../../assets/icons/${props.Image?.toLowerCase()}.svg`))} />
                        </Suspense> : 
                        <Suspense fallback={<div>Loading...</div>}>
                            <Icon style={{width: '40px'}} component={React.lazy(() => import(`./../../assets/icons/${'default'}.svg`))} />
                        </Suspense>
                    )
                    : null
                }
                <p dangerouslySetInnerHTML={{ __html: props.Text.replace(' ', '<br/>') }}></p>
            </button>
        </Link>
    );
}

export default ModuleButton;