import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import { AEvent_Add_MetadataList, AEvent_Add_List, AEvent_Add_Get, AEvent_Add_CategoryList, AEvent_Add_Create_Init } from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { EyeOutlined } from '@ant-design/icons';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EEvent_Create_Init(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Event_Create_Init, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        yield put(AEvent_Add_Create_Init(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_Get(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Event_Get, action.payload)).data.result;
        data.data = Functions.ObjectLowerCase(data.data);
        yield put(AEvent_Add_Get(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_Update(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Event_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_Create(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Event_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_Delete(action:any): any{
    const searchList = {profile: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.Event_Delete, action.payload.ids)).data.message;

        const data = (yield call(API_SERVICE.Event_List, searchList)).data.result;
        yield put (AEvent_Add_List(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_MetadataList(action:any): any{
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.Event_MetadataList)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={ "/event/edit/" + record.Id }>{text}</Link>;
                }
                if(element.link && element.key === "portal") {
                    element.render = (text:any, record: any) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <a href={`${element.linkPortal}${record.PermanentLink}`} target="_blank" rel="noopener noreferrer">
                                <EyeOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
                            </a>
                        </div>
                    )
                }
            });
            yield put (AEvent_Add_MetadataList(metadata));
        }

        const data = (yield call(API_SERVICE.Event_List, action.payload)).data.result;
        yield put (AEvent_Add_List(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_CategoryList(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Event_CategoryList)).data.result;
        yield put(AEvent_Add_CategoryList(data));
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_Export_Excel(action:any): any {
    try {
        const data = (yield call(API_SERVICE.Event_Export_Excel, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}



function* EEvent_Settings_Call(action:any):any {
    try{
        let data = (yield call(API_SERVICE.Event_Settings)).data.result;
        data = Functions.ObjectLowerCase(data);

        let fields:Array<any> = [];
        let initialValue:any = {};

        data.lista?.forEach((item:any) => { 
            let temp:any = {};
            if(item.identifier === "events_header_type"){
                temp.conditionals = [
                    {
                        operator: "01", valueOperator: "I",
                        conditioneds: [{
                            behaviour: "01",
                            id: "events_header_image",
                            type: "F"
                        }]
                    },
                    {
                        operator: "01", valueOperator: "B",
                        conditioneds: [{
                            behaviour: "01",
                            id: "events_header_banner",
                            type: "F"
                        }]
                    }
                ];
                temp.type = "radioWidget";
                temp.options = [
                    {text:"Imagen Principal", value:"I"},
                    {text:"Banner", value:"B"},
                ]
                temp.useConditional = true;
                temp.validations = [
                    {
                      type: "required",
                      params: [
                        "Selecciona Banner / Imagen Principal"
                      ]
                    }
                ]
            } 
            else if(item.identifier === "events_header_image"){
                temp.type = "image";
                temp.behaviour = "03";
            }
            else if(item.identifier === "events_header_banner"){
                temp.type = "selectDetail"; 
                temp.options = data.banners?.map((item:any) => ({id: item.Id, value: item.Name}));
                temp.validations = [];
                temp.behaviour = "03";
            } 
            else if(item.identifier === "events_header_description") {
                temp.typeInput = "string";
                temp.type = "editor";
            }
            else {
                temp.typeInput = "string";
                temp.type = "text";
            }

            //temp.idOriginal = item.id;
            temp.id = item.identifier;
            temp.label = item.description;
            temp.value = item.value;
            initialValue[item.identifier] = item.value;
            fields.push(temp);
        });

        data.fields = fields;
        data.initialValue = initialValue;
        delete data.banners;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEvent_Update_Settings_Call(action:any):any {
    try{
        let msg = (yield call(API_SERVICE.Event_Update_Settings, action.payload)).data.message;
        action.resolve(msg);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}


export function* WEvent_Create_Init():any { return yield takeLatest(ActionType.EVENT_CALL_CREATE_INIT, EEvent_Create_Init); }
export function* WEvent_Get(): any { return yield takeLatest(ActionType.EVENT_CALL_GET, EEvent_Get); }
export function* WEvent_Update(): any { return yield takeLatest(ActionType.EVENT_CALL_UPDATE, EEvent_Update); }
export function* WEvent_Create(): any { return yield takeLatest(ActionType.EVENT_CALL_CREATE, EEvent_Create); }
export function* WEvent_Delete(): any { return yield takeLatest(ActionType.EVENT_CALL_DELETE, EEvent_Delete); }
export function* WEvent_MetadataList(): any { return yield takeLatest(ActionType.EVENT_CALL_METADATA_LIST, EEvent_MetadataList); }
export function* WEvent_CategoryList(): any { return yield takeLatest(ActionType.EVENT_CALL_CATEGORY_LIST, EEvent_CategoryList); }
export function* WEvent_Export_Excel(): any { return yield takeLatest(ActionType.EVENT_EXPORT_EXCEL_CALL, EEvent_Export_Excel); }

export function* WEvent_Settings_Call():any { return yield takeLatest(ActionType.EVENT_SETTINGS_CALL, EEvent_Settings_Call); }
export function* WEvent_Update_Settings_Call():any { return yield takeLatest(ActionType.EVENT_UPDATE_SETTINGS_CALL, EEvent_Update_Settings_Call); }