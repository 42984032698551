import React from 'react'; 
import { takeLatest, call, put, select } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as Actions from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { EyeOutlined } from '@ant-design/icons';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EBannerGroup_MetadataList(action:any): any{
    try {

        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.BannerGroup_MetadataList)).data.result;
            metadata.forEach((element:any) => {
                if(element.link){
                    element.render = (text: any, record: any) => <Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_BANNER}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.id}`}>{text}</Link>
                }
                if(element.key === 'preview')
                    element.render = (text:any, record:any) => 
                        <div style={{width: '100%', textAlign:'center'}}>
                            <EyeOutlined onClick={() => action.openPreview(record)} style={{fontSize: '20px', color:'#1890ff'}} />
                        </div>
            });

            yield put(Actions.ABannerGroup_Add_MetadataList(metadata));
        }

        const data = (yield call(API_SERVICE.BannerGroup_List, action.payload)).data.result;

        yield put(Actions.ABannerGroup_add_List(data));
        action.resolve();

    } catch(error) {
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBannerGroup_Create(action:any): any {
    try{
        const result = (yield call(API_SERVICE.BannerGroup_Create, action.payload)).data;
        //yield put(Actions.ABanner_Group_Add_Create(data))
        action.resolve(result); 
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBannerGroup_Get(action:any): any{
    try{
        //Get onlyOne
        const data = (yield call(API_SERVICE.BannerGroup_Get, action.payload)).data.result;
        data.data = Functions.ObjectLowerCase(data.data);

        if(data.list.items) data.list.items = Functions.ListUpperLowerCase(data.list.items);
        yield put(Actions.ABannerGroup_Add_Get(data));
        action.resolve();
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBannerGroup_Create_Init(action:any): any{
    try{
        const data = (yield call(API_SERVICE.BannerGroup_Create_Init, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        yield put(Actions.ABannerGroup_Add_Get(data));
        action.resolve();
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBannerGroup_Update(action:any): any{
    let useMasterPage = action.payload?.useFormFromOtherModule; 
    try{
        delete action.payload?.useFormFromOtherModule;
        const result = (yield call(API_SERVICE.BannerGroup_Update, action.payload)).data;

        if(useMasterPage){
            const onlyOne = (yield select((state) => state.masterPage.onlyOne));
            yield put(Actions.AMASTERPAGE_CALL_GET(onlyOne.id, () => null, () => null));
        }

        action.resolve(result);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBannerGroup_Delete(action:any): any{
    try{
        const filter = {perfil: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
        const message = (yield call(API_SERVICE.BannerGroup_Delete, action.payload.ids)).data.message;;
        const data = (yield call(API_SERVICE.BannerGroup_List, filter)).data.result;
        yield put(Actions.ABannerGroup_add_List(data));

        action.resolve(message);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Export_Excel(action:any): any{
    try{
        const excel = (yield call(API_SERVICE.Banner_Export_Excel, action.payload)).data.result;
        action.resolve(excel);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}


function* EBanner_MetadataList(action:any): any{
    try{
        const metadata:Array<any> = (yield call(API_SERVICE.Banner_MetadataList)).data.result;
        let list = (yield call(API_SERVICE.Banner_List,action.payload)).data.result;
        if(Array.isArray(list.items)) list.items = Functions.ListUpperLowerCase(list.items);
        yield put(Actions.ABanner_Add_Metadata_List(metadata));
        yield put(Actions.ABanner_Add_List(list));
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_List(action:any) : any{
    try{
        const data = (yield call(API_SERVICE.Banner_List, {perfil:0, "filter":[{"key": "status","value": "A"}]})).data.result;
        yield put(Actions.ABanner_Add_List(data));
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Create(action:any) : any{
    const filter = {perfil:0, "filter":[{"key": "idBanner","value": `${action.payload.idbanner}`}], "sort": {key: "order",value: "asc" }}
    try{
        const result  = (yield call(API_SERVICE.Banner_Create, action.payload)).data;
        let list = (yield call(API_SERVICE.Banner_List, filter)).data.result;
        if(Array.isArray(list.items)) list.items = Functions.ListUpperLowerCase(list.items);

        yield put(Actions.ABanner_Add_List(list));
        action.resolve({...result, list});
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Get(action:any): any{
    try{
        let data = (yield call(API_SERVICE.Banner_Get, action.payload)).data.result;
        if(data) data = Functions.ObjectLowerCase(data);
        yield put(Actions.ABanner_Add_Get(data))
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Update(action:any): any{
    const filter = {perfil:0, "filter":[{"key": "idBanner","value": `${action.payload.idbanner}`}], "sort": {key: "order",value: "asc" }}
    try{
        const result = (yield call(API_SERVICE.Banner_Update, action.payload)).data;
        let list = (yield call(API_SERVICE.Banner_List, filter)).data.result;
        if(Array.isArray(list.items)) list.items = Functions.ListUpperLowerCase(list.items);
        yield put(Actions.ABanner_Add_List(list));
        action.resolve({...result, list});
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Delete(action:any): any{
    try{
        const message = (yield call(API_SERVICE.Banner_Delete, action.payload)).data.message;
        const filter = {perfil:0, "filter":[{"key": "idBanner","value": `${action.payload.idBanner}`}], "sort": {key: "order",value: "asc" }}
        let list = (yield call(API_SERVICE.Banner_List, filter)).data.result;
        if(Array.isArray(list.items)) list.items = Functions.ListUpperLowerCase(list.items);
        yield put(Actions.ABanner_Add_List(list));
        action.resolve({message, list});
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Create_Init(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Banner_Create_Init, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        action.resolve(data);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EBanner_Export_Excel_Content(action:any): any{
    try{
        const excel = (yield call(API_SERVICE.Banner_Export_Excel_Content, action.payload)).data.result;
        action.resolve(excel);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}



export function* WBanner_MetadataList(){ yield takeLatest (ActionType.BANNER_CALL_METADATA_LIST, EBanner_MetadataList); }
export function* WBanner_List(){ yield takeLatest (ActionType.BANNER_CALL_LIST, EBanner_List); }
export function* WBanner_Create_Init(){ yield takeLatest (ActionType.BANNER_CALL_CREATE_INIT, EBanner_Create_Init); }
export function* WBanner_Create(){ yield takeLatest(ActionType.BANNER_CALL_CREATE, EBanner_Create); }
export function* WBanner_Get(){ yield takeLatest(ActionType.BANNER_CALL_GET, EBanner_Get); }
export function* WBanner_Update(){ yield takeLatest(ActionType.BANNER_CALL_UPDATE, EBanner_Update); }
export function* WBanner_Delete(){ yield takeLatest(ActionType.BANNER_CALL_DELETE, EBanner_Delete) }
export function* WBanner_Export_Excel_Content(){ yield takeLatest(ActionType.BANNER_EXPORT_EXCEL_CONTENT, EBanner_Export_Excel_Content) }


export function* WBanner_Export_Excel(){ yield takeLatest(ActionType.BANNER_EXPORT_EXCEL, EBanner_Export_Excel) }
export function* WBannerGroup_MetadataList(){ yield takeLatest(ActionType.BANNER_GROUP_CALL_METADATA_LIST, EBannerGroup_MetadataList); }
export function* WBannerGroup_Create(){ yield takeLatest(ActionType.BANNER_GROUP_CALL_CREATE, EBannerGroup_Create); }
export function* WBannerGroup_Create_Init(){ yield takeLatest(ActionType.BANNER_GROUP_CALL_CREATE_INIT, EBannerGroup_Create_Init); }
export function* WBannerGroup_Get(){ yield takeLatest(ActionType.BANNER_GROUP_CALL_GET, EBannerGroup_Get); }
export function* WBannerGroup_Update(){ yield takeLatest(ActionType.BANNER_GROUP_CALL_UPDATE, EBannerGroup_Update); }
export function* WBannerGroup_Delete(){ yield takeLatest(ActionType.BANNER_GROUP_CALL_DELETE, EBannerGroup_Delete); }