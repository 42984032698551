import ActionType from "./../../constants";

const initialState = {
    count: 0,
    total: 0,
    onlyOne: {},
    metadata: []
}

const FormLists = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.FORM_LISTS_ADD_LIST:
            return {
                ...state,
                list: action.payload.items || action.payload,
                total: action.payload.total,
                metadata: action.payload?.metadata || state.metadata
            }
        case ActionType.FORM_LISTS_ADD_GET:
            return {
                ...state,
                onlyOne: action.payload
            }
        default: 
            return state
    }
}

export default FormLists;