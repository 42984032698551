import { takeLatest, call, put, select } from 'redux-saga/effects';
import React from 'react';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as actionsForm from '../actions';
import Functions from '../../functions';
import * as Actions from './../actions';
import { EyeOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EForm_Call_Get_Registry(action:any): any {
    try {
        const data = (yield call(API_SERVICE.Form_Get_Registry_File, action.payload)).data.result;
        action.resolve(data);
    }    
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Get(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Get, action.payload)).data.result;
        let _data:any = {}

        _data = Functions.ObjectLowerCase(data);
        _data.data = Functions.ObjectLowerCase(_data.data)
        
        _data.behaviorsfield = listWithOutTemplate(_data.behaviorsfield);
        _data.behaviorssection = listWithOutTemplate(_data.behaviorssection);

        yield put(actionsForm.AForm_Add_Get(_data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Update(action:any): any{
    let useMasterPage = action.payload?.useFormFromOtherModule; 
    try{
        delete action.payload?.useFormFromOtherModule;
        const result = (yield call(API_SERVICE.Form_Update, action.payload)).data;

        if(useMasterPage){
            const onlyOne = (yield select((state) => state.masterPage.onlyOne));
            yield put(Actions.AMASTERPAGE_CALL_GET(onlyOne.id, () => null, () => null));
        }

        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject({newErr, errMessage});
    }
}

function* EForm_Create(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Form_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject({newErr, errMessage});
    }
}

function* EForm_Create_Init(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Create_Init, action.payload)).data.result;

        let _data:any = {};
        _data = Functions.ObjectLowerCase(data);
        if(_data.data) {
            _data.data = Functions.ObjectLowerCase(_data.data)
            _data.data.formemail?.map((item:any) => item.id = 0)
            _data.data.formsection?.map((item:any) => {
                item.id = 0;
                item.idForm = 0;
                item.formField?.map((field:any) => {
                    field.id = 0;
                    field.idSection = 0;
                    field.idForm = 0;
                })
            })
        }
        
        _data.behaviorsfield = listWithOutTemplate(_data.behaviorsfield);
        _data.behaviorssection = listWithOutTemplate(_data.behaviorssection);
        //if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        
        yield put(actionsForm.AForm_Add_Create_Init(_data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EFormDelete(action:any): any{
    const filters = { perfil: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page }
    try{
        const message = (yield call(API_SERVICE.Form_Delete, action.payload.ids)).data.message;
        const data = (yield call(API_SERVICE.Form_List, filters)).data.result;
        yield put (actionsForm.AForm_Add_List(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_MetadataList(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_List, action.payload || {profile:1, pageSize: APP_SETTINGS().items_per_page, pageNumber: 1})).data.result;
        yield put (actionsForm.AForm_Add_List(data));

        if([null, undefined].includes(action.payload)) {
            const metadata = (yield call(API_SERVICE.Form_MetadataList)).data.result;

            metadata.map((element: any) => {
                if (element.link) {
                    if (element.key === "preview") 
                        return element.render = (text: any, record: any) => 
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <EyeOutlined
                                    onClick={(e) => action.handlePreview(e, record.id)}
                                    style={{ fontSize: "20px", color: "#1890ff" }}
                                />
                            </div>
                    if(element.key === "portal") {
                        return element.render = (text:any, record: any) => (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <a href={`${element.linkPortal}${record.permanentLink}`} target="_blank" rel="noreferrer">
                                    <EyeOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
                                </a>
                            </div>
                        )
                    }
                    element.render = (text: any, record: any) => <Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.Id}`}>{text}</Link>;
                }

                return element;
            });

            yield put (actionsForm.AForm_Add_MetadataList(metadata));
        }

        action.resolve();
    }
    catch(error){ 
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_ListRegistry(action:any): any{
    try{
        let data  = (yield call(API_SERVICE.Form_List_Registry, parseInt(action.payload.id), action.payload)).data.result;
        //const metadata:any = [];
        const { metadataList } = data;
        metadataList?.map((item:any) => {
            const dataDownload = (record:any, text:string) => ({
                idForm: parseInt(action.payload.id), 
                idRegistry: record.Id, 
                fieldName: item.dataIndex, 
                fileName: text
            });

            if(item.type === "file") {
                item.render = (text:string="", record: any) =>  {
                    const textArray = text?.split(",") || [];
                    return (
                        <> 
                            {
                                textArray?.map((textItem:string) => 
                                    <><span className='id_links' onClick={() => action.FunctionDownload(dataDownload(record, textItem))}>{textItem}</span> <br/> </>
                                )
                            }
                        </>
                    )
                }
                //isPrivateFiles ? 
                    //<a onClick={() => action.FunctionDownload(dataDownload(record, text))}>{text}</a> 
                    //    : 
                    //<a download={record[item.Name]} href={`${repopath}${record.Id}/${record[item.dataIndex]}`} target="_blank">{text}</a>
                item.link = true;    
            }
        });
        
        yield put(actionsForm.AForm_Add_List_Registry(data));
        action.resolve();
    }
    catch(error){ 
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}


function* EForm_Get_Preview(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Get_Preview, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Generate_Form(action:any): any{
    try {

        const data =  (yield call(API_SERVICE.Form_Generate_Form, action.payload)).data.result;
        yield put(actionsForm.AForm_Add_Generate_Form(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        action.reject(err.response)

    }
}

/*-----------------------------FORM LIST------------------------------------------- */

function* EForm_Lists_List(action:any): any{
    try{
        let data:any ={};
        if(!action.payload){
            const metadata = (yield call(API_SERVICE.Form_Lists_Metadata)).data.result;
            metadata?.map((item:any) => {
                if(item.link) item.render = (text:any, record:any) => <a href={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO}/${process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO_LISTS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.Id}`}> {text}</a>
            })
            data.metadata = metadata;
        } 

        const list = (yield call( API_SERVICE.Form_Lists_List, action.payload || {} )).data.result;
        data.list = list.items;
        data.total = list.total;
        yield put( actionsForm.AForm_Lists_Add_List(data) )
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Lists_Get(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Lists_Get, action.payload)).data.result;
        
        const items:any = data.data.type === 'S' ? data.items : nestedChildren(data.items.children);
        const newData = {
            ...data.data,
            formListLevel: [
                ...data.levels
            ],
            formListItem: items
        }

        yield put (actionsForm.AForm_Lists_Add_Get(newData));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Lists_Create(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Form_Lists_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Lists_Update(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Form_Lists_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Lists_Delete(action:any): any{
    try{
        const message = (yield call(API_SERVICE.Form_Lists_Delete, action.payload)).data.message;
        const list = (yield call( API_SERVICE.Form_Lists_List, {} )).data.result;
        let data:any = {};
        data.list = list.items;
        data.total = list.total;
        yield put( actionsForm.AForm_Lists_Add_List(data) )
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

/*-----------------------------EXPORT EXCEL------------------------------------------ */

function* EForm_Registry_Export_Excel(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Registry_Export_Excel, action.payload.id, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Export_Excel(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Export_Excel, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EForm_Lists_Excel(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Form_Lists_Export, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

/*----------------------------------------------------------------------------------- */

export function* WForm_Call_Get_Registry(): any { return yield takeLatest(ActionType.FORM_CALL_GET_REGISTRY_FILE, EForm_Call_Get_Registry) }
export function* WForm_Generate_Form(): any {return yield takeLatest(ActionType.FORM_CALL_GENERATE_FORM, EForm_Generate_Form) }
export function* WForm_Get_Preview(): any {return yield takeLatest(ActionType.FORM_CALL_GET_PREVIEW, EForm_Get_Preview )}

export function* WForm_Lists_List(): any {return yield takeLatest(ActionType.FORM_LISTS_CALL_LIST, EForm_Lists_List)}
export function* WForm_Lists_Get(): any {return yield takeLatest(ActionType.FORM_LISTS_CALL_GET,    EForm_Lists_Get)}
export function* WForm_Lists_Create(): any {return yield takeLatest(ActionType.FORM_LISTS_CALL_CREATE, EForm_Lists_Create)}
export function* WForm_Lists_Update(): any {return yield takeLatest(ActionType.FORM_LISTS_CALL_UPDATE, EForm_Lists_Update)}
export function* WForm_Lists_Delete(): any {return yield takeLatest(ActionType.FORM_LISTS_CALL_DELETE, EForm_Lists_Delete)}


function* EForm_Type(action:any): any{
    try{ 
        const control = (yield call(API_SERVICE.Form_Control_Type)).data.result;
        const data = (yield call(API_SERVICE.Form_Data_Type)).data.result;
        const behavior = (yield call(API_SERVICE.Form_List_Behavior)).data.result;
        const list_list = (yield call( API_SERVICE.Form_Lists_List, action.payload )).data.result;

        yield put(actionsForm.AForm_Lists_Add_List(list_list) )
        yield put(actionsForm.AForm_Add_Control_Type(control) );
        yield put(actionsForm.AForm_Add_Data_Type(data));
        yield put(actionsForm.AForm_Add_List_Behavior(behavior))
        action.resolve();
    }
    catch(e){
        action.reject();
    }
}

export function* WForm_Get(): any {return yield takeLatest(ActionType.FORM_CALL_GET, EForm_Get)};
export function* WForm_Update(): any {return  yield takeLatest(ActionType.FORM_CALL_UPDATE, EForm_Update)};
export function* WForm_Create(): any {return yield takeLatest(ActionType.FORM_CALL_CREATE, EForm_Create)};
export function* WForm_Create_Init(): any {return yield takeLatest(ActionType.FORM_CALL_CREATE_INIT, EForm_Create_Init)};
export function* WForm_Delete(): any {return yield takeLatest(ActionType.FORM_CALL_DELETE, EFormDelete)};
export function* WForm_MetadataList(): any {return yield takeLatest(ActionType.FORM_CALL_METADATA_LIST, EForm_MetadataList)};
export function* WForm_ListRegistry(): any {return yield takeLatest(ActionType.FORM_CALL_LIST_REGISTRY, EForm_ListRegistry)};

export function* WForm_Registry_Export_Excel():any {return yield takeLatest(ActionType.FORM_CALL_REGISTRY_EXPORT_EXCEL, EForm_Registry_Export_Excel)};
export function* WForm_Export_Excel():any {return yield takeLatest(ActionType.FORM_CALL_EXPORT_EXCEL, EForm_Export_Excel)};
export function* WForm_Lists_Export():any {return yield takeLatest(ActionType.FORM_LISTS_CALL_EXPORT, EForm_Lists_Excel)};
export function* WForm_Type(): any {return yield takeLatest(ActionType.FORM_CALL_CONTROL_TYPE, EForm_Type)}

const nestedChildren = (data: any) => {
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      item.expanded = true;

      if(item.children.length !== 0) {
        nestedChildren(item.children);
      }
      else {
        item.children = null;
      }
    }

    return data;
}

const listWithOutTemplate = (data:any) => {
    const keys = Object.keys(data);
    const newData:any = [];

    keys?.map((item:any) => {
        let newItem = {
            Key: item,
            Value: data[item]
        }
        newData.push(newItem);
    })
    
    return newData;
}