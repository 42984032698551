import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import { ARecyclebin_Add_List, ARecyclebin_Add_MetadataList, ARecyclebin_Add_Modules } from '../actions';
import { Button } from 'antd';
import Functions from '../../functions';

function funCompoundKey(temp:any){
    const  { id, keyModule} = temp;
    return `${id}-${keyModule}`;
}

function* ERecyclebin_MetadataList(action:any):any {
    try{
        const { payload: { callMetadata, filter } } = action;
        if(callMetadata) {
            const modules = (yield call(API_SERVICE.Recyclebin_Modules)).data.result;
            const _modules = Object.entries(modules);
            const resultModules = _modules.map((item)  => ({
                value: item[0],
                name: item[1], //name is for search
                text: item[1] //text is for table
            }))

            const metadata = (yield call(API_SERVICE.Recyclebin_MetadataList)).data.result;
            metadata.map((item:any, index:any) => {
                if(item.link && item.dataIndex === 'recuperar') {
                    metadata[index] = {
                        ...item,
                        render: (text:any, record:any) => <Button onClick={ (e:any) => action.funcRestore(record) }>Restaurar</Button>
                    }
                }
                if(item.dataIndex === 'AlertTime'){
                    metadata[index] = {
                        ...item,
                        link: true,
                        render: (text:any, record:any) => <p style={{color: 'red'}}> {text} </p>
                    }
                }
                if(item.filterItem){
                    metadata[index].filters = resultModules;
                }

                return item;
            })

            yield put(ARecyclebin_Add_Modules(resultModules));
            yield put(ARecyclebin_Add_MetadataList(metadata));
        }

        const list = (yield call(API_SERVICE.Recyclebin_List, filter)).data.result;
        if(list.items) list.items = Functions.ListUpperLowerCase(list.items, funCompoundKey);
        yield put(ARecyclebin_Add_List(list));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERecyclebin_RetrieveItem(action:any):any {
    try{
        const data = {
            modules: [],
            perfil: 1
        }

        const message = (yield call(API_SERVICE.Recyclebin_RetrieveItem, action.payload)).data.message;
        
        const list = (yield call(API_SERVICE.Recyclebin_List, data)).data.result;
        if(list.items) list.items = Functions.ListUpperLowerCase(list.items, funCompoundKey);
        yield put(ARecyclebin_Add_List(list));

        action.resolve(message);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERecyclebin_BatchRetrieve(action:any):any {
    try{
        const data = { modules: [], perfil: 1 };
        const message = (yield call(API_SERVICE.Recyclebin_Batch_Retrieve, action.payload)).data.message;

        const list = (yield call(API_SERVICE.Recyclebin_List, data)).data.result;
        if(list.items) list.items = Functions.ListUpperLowerCase(list.items, funCompoundKey);
        yield put(ARecyclebin_Add_List(list));

        action.resolve(message);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERecyclebin_Export_Excel(action:any):any {
    try{
        const excel = (yield call(API_SERVICE.Recyclebin_Export_Excel, action.payload)).data.result;
        action.resolve(excel);
    }
    catch{
        action.reject();
    }
}

export function* WRecyclebin_BatchRetrieve():any { return yield takeLatest(ActionType.RECYCLEBIN_CALL_BATCHRETRIEVE, ERecyclebin_BatchRetrieve); }
export function* WRecyclebin_MetadataList():any { return yield takeLatest(ActionType.RECYCLEBIN_CALL_METADATALIST, ERecyclebin_MetadataList); }
export function* WRecyclebin_RetrieveItem():any { return yield takeLatest(ActionType.RECYCLEBIN_CALL_RETRIEVEITEM, ERecyclebin_RetrieveItem); }
export function* WRecyclebin_Export_Excel():any { return yield takeLatest(ActionType.RECYCLEBIN_EXPORT_EXCEL, ERecyclebin_Export_Excel); }