import ActionType from "./../../constants";

const initialState = {
    list: [],
    metadata: [],
    count: 0
}

const FormForm = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.FORM_ADD_GET: 
            return {
                ...state,
                onlyOne: action.payload
            }
        case ActionType.FORM_ADD_CREATE_INIT:
            return {
                ...state,
                createInit: action.payload
            }
        case ActionType.FORM_ADD_LIST:
            return {
                ...state,
                list: action.payload.items,
                count: action.payload.total
            }
        case ActionType.FORM_ADD_METADATA_LIST:
            return {
                ...state,
                metadata: action.payload
            }
        case ActionType.FORM_ADD_GET_PREVIEW:
            return {
                ...state,
                preview: action.payload
            }
        default: 
            return state
    }
}

export default FormForm;
