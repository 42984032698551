import ActionType from "../constants";

const initialState = {
    onlyOne: {
        name: '',
        banners: [],
        events: [],
        forms: [],
        pageZones: [],
        news: [],
        templateJsonScheme: {
            jsonScheme: []
        },
        values: {},
        metadataList: []
    }
};

const RMaster_Page = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.MASTER_PAGE_ADD_METADATA :
            return {
                ...state,
                metadata: action.payload
            };
        case ActionType.MASTER_PAGE_ADD_LIST :
            return {
                ...state,
                list: action.payload
            };
        case ActionType.MASTER_PAGE_ADD_GET :
            return {
                ...state,
                onlyOne: action.payload
            };
        default:
            return state;
    }
}

export default RMaster_Page;  