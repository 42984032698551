import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import { ANews_Add_MetadataList, ANews_add_List, ANews_Add_Get, ANews_Add_Category, ANews_Export_Excel_Add } from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { EyeOutlined } from '@ant-design/icons';
import { APP_SETTINGS } from '../../constants/app_settings';

function* ENews_Duplicate(action:any):any {
    try{
        const data = (yield call(API_SERVICE.News_Duplicate, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_MetadataList(action:any):any {
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.News_MetadataList)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={ "/news/edit/" + record.Id }>{text}</Link>;
                }
                if(element.link && element.key === "portal") {
                    element.render = (text:any, record: any) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <a href={`${element.linkPortal}${record.PermanentLink}`} target="_blank" rel="noreferrer">
                                <EyeOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
                            </a>
                        </div>
                    )
                }
            });
            yield put(ANews_Add_MetadataList(metadata));
        }

        const data = (yield call(API_SERVICE.News_List, action.payload)).data.result;
        
        yield put(ANews_add_List(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Create(action:any):any {
    try{
        const result = (yield call(API_SERVICE.News_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Update(action:any):any {
    try{
        const result = (yield call(API_SERVICE.News_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Get(action:any):any {
    try{
        let data = (yield call(API_SERVICE.News_Get, action.payload)).data.result;
        data.data = Functions.ObjectLowerCase(data.data);
        yield put(ANews_Add_Get(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Create_Init(action:any):any {
    try{
        let data = (yield call(API_SERVICE.News_Create_Init, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        yield put(ANews_Add_Get(data)); 
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Delete(action:any):any {
    const searchList = {profile: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.News_Delete, action.payload.ids)).data.message;
        const data = (yield call(API_SERVICE.News_List, searchList)).data.result;
        
        yield put(ANews_add_List(data));
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Category(action:any):any {
    try{
        const category = (yield call(API_SERVICE.News_Category)).data.result
        yield put(ANews_Add_Category(category))
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Export_Excel(action:any):any {
    try {
        const data = (yield call(API_SERVICE.News_Export_Excel, action.payload)).data.result;
        yield put(ANews_Export_Excel_Add(data));
        
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Settings_Call(action:any):any {
    try{
        let data = (yield call(API_SERVICE.News_Settings)).data.result;
        data = Functions.ObjectLowerCase(data);

        let fields:Array<any> = [];
        let initialValue:any = {};

        data.lista?.forEach((item:any) => { 
            let temp:any = {};
            if(item.identifier === "news_header_type"){
                temp.conditionals = [
                    {
                        operator: "01", valueOperator: "I",
                        conditioneds: [{
                            behaviour: "01",
                            id: "news_header_image",
                            type: "F"
                        }]
                    },
                    {
                        operator: "01", valueOperator: "B",
                        conditioneds: [{
                            behaviour: "01",
                            id: "news_header_banner",
                            type: "F"
                        }]
                    }
                ];
                temp.type = "radioWidget";
                temp.options = [
                    {text:"Imagen Principal", value:"I"},
                    {text:"Banner", value:"B"},
                ]
                temp.useConditional = true;
                temp.validations = [
                    {
                      type: "required",
                      params: [
                        "Selecciona Banner / Imagen Principal"
                      ]
                    }
                ]
            } 
            else if(item.identifier === "news_header_image"){
                temp.type = "image";
                temp.behaviour = "03";
            }
            else if(item.identifier === "news_header_banner"){
                temp.type = "selectDetail"; 
                temp.options = data.banners?.map((item:any) => ({id: item.Id, value: item.Name}));
                temp.validations = [];
                temp.behaviour = "03";
            } 
            else if(item.identifier === "news_header_description") {
                temp.typeInput = "string";
                temp.type = "editor";
            }
            else {
                temp.typeInput = "string";
                temp.type = "text";
            }

            //temp.idOriginal = item.id;
            temp.id = item.identifier;
            temp.label = item.description;
            temp.value = item.value;
            initialValue[item.identifier] = item.value;
            fields.push(temp);
        });

        data.fields = fields;
        data.initialValue = initialValue;
        delete data.banners;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ENews_Update_Settings_Call(action:any):any {
    try{
        let msg = (yield call(API_SERVICE.News_Update_Settings, action.payload)).data.message;
        action.resolve(msg);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WNews_Duplicate():any { return yield takeLatest( ActionType.NEWS_CALL_DUPLICATE, ENews_Duplicate ) }
export function* WNews_Export_Excel():any { return yield takeLatest( ActionType.NEWS_EXPORT_EXCEL_CALL, ENews_Export_Excel ) }
export function* WNews_MetadataList():any { return yield takeLatest( ActionType.NEWS_CALL_METADATA_LIST, ENews_MetadataList); }
export function* WNews_Create():any { return yield takeLatest(ActionType.NEWS_CALL_CREATE, ENews_Create); }
export function* WNews_Update():any { return yield takeLatest(ActionType.NEWS_CALL_UPDATE, ENews_Update); }
export function* WNews_Get():any { return yield takeLatest(ActionType.NEWS_CALL_GET, ENews_Get); }
export function* WNews_Create_Init():any { return yield takeLatest(ActionType.NEWS_CALL_CREATE_INIT, ENews_Create_Init); }
export function* WNews_Delete():any { return yield takeLatest(ActionType.NEWS_CALL_DELETE, ENews_Delete); }
export function* WNews_Category():any { return yield takeLatest(ActionType.NEWS_CALL_CATEGORY, ENews_Category); }
export function* WNews_Settings_Call():any { return yield takeLatest(ActionType.NEWS_SETTINGS_CALL, ENews_Settings_Call); }
export function* WNews_Update_Settings_Call():any { return yield takeLatest(ActionType.NEWS_UPDATE_SETTINGS_CALL, ENews_Update_Settings_Call); }