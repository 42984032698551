import * as React from 'react';
import LoginWrapper from '../components/loginWrapper';
import { Form, Input, Button, message, Spin } from 'antd';
import API_SERVICE from '../API/api';
import Functions from '../functions';

class RecoveryPassword extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            messageError: ''
        };
    }

    enterLoading = () => {
        this.setState({ loading: true });
    };

    onCancel = () => {
        // this.setState({ loading: true });
        window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
    };

    renderTitle = () => (
        <div>
            <h2>¿Has olvidado la<br/>contraseña?</h2>
            <p>Te enviaremos una nueva clave de acceso al correo electrónico registrado.</p>
        </div>
    )

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {
                this.setState({ loading: true });
                API_SERVICE.User_RecoveryPassword({
                    email: values.email
                })
                .then((res: any) => {
                    message.success(`${res.data.message}`);
                    this.props.history.push(`/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`);
                    this.setState({ loading: false });
                })
                .catch((err: any) => {
                    const errMessage = err.response.data.responseException.exceptionMessage;
                    const newErr = Functions.getExeceptions(errMessage);

                    this.setState({ loading: false, messageError: Array.isArray(newErr) ? newErr.toString() : newErr });
                })
            }
        });
    }

    render() {
        const { messageError } = this.state;
        const { getFieldDecorator } = this.props.form;

        return(
            <LoginWrapper Title={ this.renderTitle }>
                <div className="message">
                    {messageError && <Spin spinning={this.state.loading}><div className="msgError"> <span>x</span><label dangerouslySetInnerHTML={{__html: messageError || ""}}></label> </div> </Spin>}
                </div>
                <div className="form">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item label="Correo Electrónico">
                            { getFieldDecorator('email', {
                                rules: [{ required: true, message: 'El correo no puede estar vacio' }],
                            })(
                                <Input
                                /*prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Username"*/
                                disabled={this.state.loading}
                                />,
                            )}
                        </Form.Item>
                        <Form.Item className="cancel__button">
                            <Button type="primary" htmlType="button" className="cancel-form-button" onClick={ this.onCancel } disabled={this.state.loading}>
                                CANCELAR
                            </Button>
                        </Form.Item>
                        <Form.Item className="enviar__button">
                            <Button type="primary" htmlType="submit" className="enviar-form-button" loading={ this.state.loading } onClick={ this.enterLoading }>
                                ENVIAR
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div style={ { paddingBottom: '110px' } }/>
            </LoginWrapper>
        )
    }

}

export default Form.create({ name: 'recovery-password' })(RecoveryPassword);