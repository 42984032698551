import ActionType from "../constants";

const initialState = [{ }];

const RSettings = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.SETTINGS_LIST_RRSS_ADD :
            return {
                ...state,
                list: action.payload
            }
        default:
        return state;
    }
}

export default RSettings;  