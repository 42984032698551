import * as React from 'react';
import LoginWrapper from '../components/loginWrapper';
import { Form, Button, Input, message } from 'antd';
import API_SERVICE from '../API/api';
import Functions from '../functions';

class UpdatePassword extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        this.onInit();
    }

    onInit = () => {
        const params = new URLSearchParams(this.props.location.search);
		const hash = params.get('guid') || '';
        API_SERVICE.User_ValidActiveAccount(hash)
        .then((res: any) => {
            const { message, result } = res.data;
			if(result === false) window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
            console.log(message, result);
			this.setState({data:{...this.state.data, hash}});
        })
        .catch((err: any) => {
            window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
        });
    }


    enterLoading = () => {
        this.setState({ loading: true });
    };

    renderTitle = () => (
        <div>
            <h2>Nueva contraseña</h2>
            <p>Por favor, establece tu contraseña. Por seguridad, esta debe contener por lo menos una letra mayúscula, un número y un carácter especial.</p>
        </div>
    )

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err: any, values: any) => {
            if (!err) {
                if(values.nuevacontraseña === values.confirmarcontraseña) {
                    this.setState({ loading: true });
                    API_SERVICE.User_ActiveAccount({
                        hash: this.state.data.hash,
                        email: values.correo,
                        password: values.nuevacontraseña,
                      })
                    .then((res: any) => { 
                        message.success('Activación exitosa.')
                        this.setState({ loading: false });
                        setTimeout(() => {
                            window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`; 
                        }, 1000)
                    })
                    .catch((err:any) => { 
                        const errMessage = err.response.data.responseException.exceptionMessage;
                        const newErr = Functions.getExeceptions(errMessage);
                        Functions.showException(newErr);
                        this.setState({ loading: false });
                    })
                }
                else {
                    this.setState({ loading: false });
                    message.error("Las contraseñas no coinciden")
                }
            }
        });
    }

    render() {

        const { getFieldDecorator } = this.props.form;

        return(
            <LoginWrapper Title={ this.renderTitle }>
                <div className="form">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item label="Correo Electrónico">
                            { getFieldDecorator('correo', {
                                rules: [{ required: true, message: 'El correo no puede estar vacio' }],
                            })(
                                <Input
                                /*prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Username"*/
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="Nueva contraseña">
                            { getFieldDecorator('nuevacontraseña', {
                                rules: [{ required: true, message: 'La contraseña no puede estar vacio' }],
                            })(
                                <Input.Password
                                /*prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Username"*/
                                />,
                            )}
                        </Form.Item>
                        <Form.Item label="Confirmar nueva contraseña">
                            { getFieldDecorator('confirmarcontraseña', {
                                rules: [{ required: true, message: 'Repita la contraseña' }],
                            })(
                                <Input.Password
                                /*prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder="Username"*/
                                />,
                            )}
                        </Form.Item>
                        <Form.Item className="aceptar__button">
                            <Button type="primary" htmlType="submit" className="aceptar-form-button" loading={ this.state.loading } onClick={ this.enterLoading }>
                                Establecer
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div style={ { paddingBottom: '40px' } }/>
            </LoginWrapper>
        )
    }
}

export default Form.create({ name: 'update-password' })(UpdatePassword);