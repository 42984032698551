import { takeLatest, call } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import Functions from '../../functions';

function* EUpload (action:any):any {
    try{
        const data = (yield call(API_SERVICE.Upload, action.payload, action.file)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WUpload():any { return yield takeLatest(ActionType.UPLOAD_CALL, EUpload) }