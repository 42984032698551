import * as constants from './url_apis';
import IApiPropTypes from './api.proptypes';
import * as _ from './methods';

import * as gallery_functions from './gallery';
import * as form_function from './form';

const API_URL = constants.API_URL;

const getHeader = () => {
    return {
        headers : {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    };
}

const API_SERVICE: IApiPropTypes = {} as IApiPropTypes;

/**
 * functions for user api
 * @keyword USER
*/

API_SERVICE.User_Login = (body: object) =>  _.METHOD_POST_WITOUT_AUTH(constants.API_URL + constants.USER_LOGIN, body)// user_functions.User_Login(body, API_URL, constants);
API_SERVICE.User_RecoveryPassword = (body: object) =>  _.METHOD_POST_WITOUT_AUTH(constants.API_URL + constants.USER_RECOVERY_PASSWORD, body); //user_functions.User_RecoveryPassword(body, API_URL, constants);
API_SERVICE.User_RecoveryUpdatePassword = (body: object) =>  _.METHOD_POST_WITOUT_AUTH(constants.API_URL + constants.USER_RECOVERY_UPDATE_PASSWORD, body); //user_functions.User_RecoveryUpdatePassword(body, API_URL, constants);
API_SERVICE.User_ValidRecoveryPassword = (hash: string) =>  _.METHOD_GET(constants.API_URL + constants.USER_VALID_RECOVERY_PASSWORD + hash); //user_functions.User_ValidRecoveryPassword(hash, API_URL, constants);
API_SERVICE.User_ValidActiveAccount = (hash:string) => _.METHOD_GET(constants.API_URL + constants.USER_VALID_ACTIVE_ACCOUNT + hash); //user_functions.User_ValidActiveAccount(hash, API_URL, constants);
API_SERVICE.User_Update_Password = (body:any) => _.METHOD_POST(constants.API_URL + constants.USER_UPDATE_PASSWORD, body); //user_functions.User_Update_Password(body, getHeader());
API_SERVICE.User_ActiveAccount = (data:any) => _.METHOD_POST_WITOUT_AUTH(constants.API_URL + constants.USER_ACTIVE_ACCOUNT, data); //user_functions.User_ActiveAccount(data);

/**
 * functions for internal page api
 * @keyword INTERNAL PAGE
 */

API_SERVICE.InternalPage_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.INTERNAL_PAGE_METADATA_LIST); //internalPage_functions.InternalPage_MetadataList(API_URL, constants, getHeader());
API_SERVICE.InternalPage_List = (body: object) => _.METHOD_POST(constants.API_URL + constants.INTERNAL_PAGE_LIST, body); //internalPage_functions.InternalPage_List(body, API_URL, constants, getHeader());
API_SERVICE.InternalPage_Create = (body: object) => _.METHOD_POST(constants.API_URL + constants.INTERNAL_PAGE_CREATE, body); //internalPage_functions.InternalPage_Create(body, API_URL, constants, getHeader());
API_SERVICE.InternalPage_Get = (Id: number) => _.METHOD_GET(constants.API_URL + constants.INTERNAL_PAGE_GET + Id); //internalPage_functions.InternalPage_Get(API_URL, constants, Id, getHeader());
API_SERVICE.InternalPage_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.INTERNAL_PAGE_CREATE_INIT + (Id ? `/${Id}` : '')) //internalPage_functions.InternalPage_Create_Init(Id, getHeader());
API_SERVICE.InternalPage_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.INTERNAL_PAGE_UPDATE, body); //internalPage_functions.InternalPage_Update(body, API_URL, constants, getHeader());
API_SERVICE.InternalPage_HierarchicalList = (body:object) => _.METHOD_POST(constants.API_URL + constants.INTERNAL_PAGE_HIERARCHICALLIST, body); //internalPage_functions.InternalPage_HierarchicalList(body, API_URL, constants, getHeader());
API_SERVICE.InternalPage_Delete = (ids: number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.INTERNAL_PAGE_DELETE, ids); //internalPage_functions.InternalPage_Delete(Id, API_URL, constants, getHeader());
API_SERVICE.InternalPage_Type = () => _.METHOD_GET(constants.API_URL + constants.INTERNAL_PAGE_TYPE); //internalPage_functions.InternalPage_TypeContent(API_URL, constants, getHeader());
API_SERVICE.InternalPage_Export_Excel = (payload:any) => _.METHOD_POST(constants.API_URL + constants.INTERNAL_PAGE_EXPORT_EXCEL, payload); // internalPage_functions.InternalPage_Export_Excel(payload, getHeader());

/**
 * functions for page zone api
 * @keyword PAGE ZONE
 */

API_SERVICE.PageZone_Order_Content = (body:any) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_ORDER_CONTENT, body); // pagezone_functions.PageZone_Order_Content(body, getHeader());
API_SERVICE.PageZone_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_CREATE_INIT + Id) // pagezone_functions.PageZone_Create_Init(Id, getHeader());
API_SERVICE.PageZone_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_CREATE, body) // pagezone_functions.PageZone_Create(body, API_URL, constants, getHeader());
API_SERVICE.PageZone_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_UPDATE, body) // pagezone_functions.PageZone_Update(body, API_URL, constants, getHeader());
API_SERVICE.PageZone_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_GET + Id) // pagezone_functions.PageZone_Get(Id, API_URL, constants, getHeader());
API_SERVICE.PageZone_Delete = (Ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.PAGE_ZONE_DELETE, Ids) // pagezone_functions.PageZone_Delete(Ids, API_URL, constants, getHeader());
API_SERVICE.PageZone_List = (Id:number) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_LIST + Id, {}) // pagezone_functions.PageZone_List(Id, API_URL, constants, getHeader());
API_SERVICE.PageZone_MetadaList = () => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_METADATA_LIST) // pagezone_functions.PageZone_MetadataList(API_URL, constants,getHeader());
API_SERVICE.PageZone_Export_Excel = (id:number) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_EXPORT_EXCEL + id, {}) // pagezone_functions.PageZone_Export_Excel(id, getHeader());

API_SERVICE.PageZoneGroup_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_GROUP_CREATE_INIT + (Id ? `/${Id}` : '')) // pagezone_functions.PageZoneGroup_Create_Init(Id, getHeader());
API_SERVICE.PageZoneGroup_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_GROUP_CREATE, body) // pagezone_functions.PageZoneGroup_Create(body, API_URL, constants, getHeader());
API_SERVICE.PageZoneGroup_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_GROUP_UPDATE, body) // pagezone_functions.PageZoneGroup_Update(body, API_URL, constants, getHeader());
API_SERVICE.PageZoneGroup_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_GROUP_GET + Id) // pagezone_functions.PageZoneGroup_Get(Id, API_URL, constants, getHeader());
API_SERVICE.PageZoneGroup_List = (body: object) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_GROUP_LIST, body) // pagezone_functions.PageZoneGroup_List(body, API_URL,constants, getHeader());
API_SERVICE.PageZoneGroup_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_GROUP_METADATA_LIST) // pagezone_functions.PageZoneGroup_MetadataList(API_URL, constants, getHeader());
API_SERVICE.PageZoneGroup_Delete = (ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.PAGE_ZONE_GROUP_DELETE, ids) // pagezone_functions.PageZoneGroup_Delete(id, API_URL, constants, getHeader());
API_SERVICE.PageZoneGroup_Excel = (idPerfil:number) => _.METHOD_POST(constants.API_URL + constants.PAGE_ZONE_GROUP_EXPORT_EXCEL, idPerfil) // pagezone_functions.PageZoneGroup_Export_Excel(idPerfil, getHeader());

/**
 * functions for page zone api
 * @keyword BANNER
*/

API_SERVICE.Banner_Order_Content = (body:any) => _.METHOD_POST_PARAMS(constants.API_URL + constants.BANNER_ORDER_CONTENT, body);
API_SERVICE.Banner_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.BANNER_CREATE, body);
API_SERVICE.Banner_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.BANNER_UPDATE, body);
API_SERVICE.Banner_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.BANNER_GET + Id);
API_SERVICE.Banner_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.BANNER_GET + Id);
API_SERVICE.Banner_Delete = (data: any) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.BANNER_DELETE + data.idBanner, data.ids);
API_SERVICE.Banner_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.BANNER_LIST, body);
API_SERVICE.Banner_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.BANNER_METADATA_LIST);
API_SERVICE.Banner_Export_Excel_Content = (id:number) => _.METHOD_POST(constants.API_URL + constants.BANNER_EXPORT_EXCEL_CONTENT + id, {});


API_SERVICE.Banner_Export_Excel = (payload:any) => _.METHOD_POST(constants.API_URL + constants.BANNER_EXPORT_EXCEL, payload);
API_SERVICE.BannerGroup_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.BANNER_GROUP_CREATE_INIT + (Id ? `/${Id}` : ''));
API_SERVICE.BannerGroup_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.BANNER_GROUP_CREATE, body);
API_SERVICE.BannerGroup_Update = (body:object) =>  _.METHOD_POST(constants.API_URL + constants.BANNER_GROUP_UPDATE, body);
API_SERVICE.BannerGroup_Get = (Id: number) => _.METHOD_GET(constants.API_URL + constants.BANNER_GROUP_GET + Id);
API_SERVICE.BannerGroup_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.BANNER_GROUP_METADATA_LIST);
API_SERVICE.BannerGroup_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.BANNER_GROUP_LIST, body);
API_SERVICE.BannerGroup_Delete = (Ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.BANNER_GROUP_DELETE, Ids);
/**
 * functions for news api 
 * @keyword NEWS
*/

API_SERVICE.News_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.NEWS_METADATA_LIST); //news_functions.News_MetadaList(API_URL, constants, getHeader());
API_SERVICE.News_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.NEWS_CREATE, body); //news_functions.News_Create(API_URL,constants,body, getHeader());
API_SERVICE.News_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.NEWS_LIST, body); //news_functions.News_List(API_URL, constants, body, getHeader());
API_SERVICE.News_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.NEWS_UPDATE, body); //news_functions.News_Update(API_URL, constants, body, getHeader());
API_SERVICE.News_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.NEWS_GET + Id) //news_functions.News_Get(API_URL, constants, Id, getHeader());
API_SERVICE.News_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.NEWS_CREATE_INIT + (Id ? `/${Id}` : '')) //news_functions.News_Create_Init(Id, getHeader());
API_SERVICE.News_Delete = (Ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.NEWS_DELETE, Ids); //news_functions.News_Delete(API_URL, constants, Id, getHeader());
API_SERVICE.News_Category = () => _.METHOD_GET(constants.API_URL + constants.PAGE_ZONE_CATEGORY); //news_functions.News_Category(API_URL, constants, getHeader());
API_SERVICE.News_Export_Excel = (payload:any) => _.METHOD_POST(constants.API_URL + constants.NEWS_EXPORT_EXCEL, payload); //news_functions.News_Export_Excel(API_URL, constants, payload, getHeader());
API_SERVICE.News_Duplicate = (id:number) => _.METHOD_POST(constants.API_URL + constants.NEWS_DUPLICATE + id, {id}); // news_functions.News_Duplicate(id, getHeader()); 
API_SERVICE.News_Settings = () => _.METHOD_GET(constants.API_URL + constants.NEWS_SETTINGS); 
API_SERVICE.News_Update_Settings = (data:any) => _.METHOD_POST(constants.API_URL + constants.NEWS_UPDATE_SETTINGS, data); 
/**
 * functions for gallery api 
 * @keyword GALLERY
*/

API_SERVICE.Gallery_Category_MetadataList = () => gallery_functions.Gallery_Category_MetadataList(getHeader()); 
API_SERVICE.Gallery_Category_List = () => gallery_functions.Gallery_Category_List(getHeader()); 
API_SERVICE.Gallery_Category_Create = (body:object) => gallery_functions.Gallery_Category_Create(body, getHeader());
API_SERVICE.Gallery_Category_Update = (body:object) => gallery_functions.Gallery_Category_Update(body, getHeader());
API_SERVICE.Gallery_Category_Batch_Delete = (ids:number[]) => gallery_functions.Gallery_Category_Delete(ids, getHeader());
API_SERVICE.Gallery_Category_Export_Excel = () => _.METHOD_POST(constants.API_URL + constants.GALLERY_CATEGORY_EXPORT_EXCEL, {})

API_SERVICE.Gallery_Order_Content = (body:any) => gallery_functions.Gallery_Order_Content(body, getHeader());
API_SERVICE.Gallery_Categories = () => gallery_functions.Gallery_Categories(API_URL, constants, getHeader());
API_SERVICE.Gallery_Get = (id:number) => gallery_functions.Gallery_Get(API_URL, constants, id, getHeader());
API_SERVICE.Gallery_Create_Init = (Id:number) => gallery_functions.Gallery_Create_Init(Id, getHeader());
API_SERVICE.Gallery_Create = (body:object) => gallery_functions.Gallery_Create(API_URL, constants, body, getHeader());
API_SERVICE.Gallery_Update = (body:object) => gallery_functions.Gallery_Update(API_URL, constants, body, getHeader());
API_SERVICE.Gallery_Delete = (ids:number[]) => gallery_functions.Gallery_Delete(API_URL, constants, ids, getHeader());
API_SERVICE.Gallery_List = (body:object) => gallery_functions.Gallery_List(API_URL, constants,body, getHeader()); 
API_SERVICE.Gallery_MetadataList = () => gallery_functions.Gallery_MetadataList(API_URL, constants, getHeader());
API_SERVICE.Gallery_Export_Excel = (payload:any) => gallery_functions.Gallery_Export_Excel(payload, getHeader());
API_SERVICE.Gallery_Content_Get = (id:number) => gallery_functions.Gallery_Content_Get(API_URL, constants,id, getHeader());
API_SERVICE.Gallery_Content_Create = (body:object) => gallery_functions.Gallery_Content_Create(API_URL, constants, body, getHeader());
API_SERVICE.Gallery_Content_Update = (body:object) => gallery_functions.Gallery_Content_Update(API_URL, constants, body, getHeader());
API_SERVICE.Gallery_Content_Delete = (id:any) =>  gallery_functions.Gallery_Content_Delete(API_URL, constants, id, getHeader());
API_SERVICE.Gallery_Content_List = (body:object) => gallery_functions.Gallery_Content_List(API_URL, constants, body, getHeader());
API_SERVICE.Gallery_Content_MetadataList = () => gallery_functions.Gallery_Content_MetadataList(API_URL, constants, getHeader());
API_SERVICE.Gallery_Content_Export_Excel = (payload:any) => gallery_functions.Gallery_Content_Export_Excel(payload, getHeader());
API_SERVICE.Gallery_List_Content_Type = () => gallery_functions.Gallery_List_Content_Types(API_URL, constants, getHeader());

API_SERVICE.Gallery_Update_Settings = (data:any) => _.METHOD_POST(constants.API_URL + constants.GALLERY_UPDATE_SETTINGS, data);
API_SERVICE.Gallery_Settings = () => _.METHOD_GET(constants.API_URL + constants.GALLERY_SETTINGS);

/**
 * functions for events api 
 * @keyword EVENT
*/

API_SERVICE.Event_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.EVENT_CREATE_INIT + (Id ? `/${Id}` : ``)); // event_functions.Event_Create_Init(Id, getHeader());
API_SERVICE.Event_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.EVENT_GET + Id); // event_functions.Event_get(Id, API_URL, constants, getHeader());
API_SERVICE.Event_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.EVENT_CREATE, body); // event_functions.Event_create(body, API_URL, constants, getHeader()); 
API_SERVICE.Event_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.EVENT_UPDATE, body); // event_functions.Event_update(body, API_URL, constants, getHeader())
API_SERVICE.Event_Delete = (Ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.EVENT_DELETE, Ids); // event_functions.Event_delete(Id, API_URL, constants, getHeader());
API_SERVICE.Event_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.EVENT_LIST, body); // event_functions.Event_List(body, API_URL, constants, getHeader());
API_SERVICE.Event_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.EVENT_METADATA_LIST); // event_functions.Event_MetadataList(API_URL, constants, getHeader());
API_SERVICE.Event_CategoryList = () => _.METHOD_GET(constants.API_URL + constants.EVENT_CATEGORY_LIST); // event_functions.Event_Category_List(API_URL, constants, getHeader()); 
API_SERVICE.Event_Export_Excel = (body:any) => _.METHOD_POST(constants.API_URL + constants.EVENT_EXPORT_EXCEL, body); // event_functions.Event_Export_Excel(body, getHeader());

API_SERVICE.Event_Update_Settings = (data:any) => _.METHOD_POST(constants.API_URL + constants.EVENT_UPDATE_SETTINGS, data);
API_SERVICE.Event_Settings = () => _.METHOD_GET(constants.API_URL + constants.EVENT_SETTINGS);

/**
 * functions for htmlTemplate api 
 * @keyword HTMLTEMPLATE
*/

API_SERVICE.HtmlTemplate_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.HTML_TEMPLATE_GET + Id); // htmlTemplate_function.htmlTemplate_Get(Id, API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.HTML_TEMPLATE_CREATE_INIT + (Id ? `/${Id}` : '')); // htmlTemplate_function.htmlTemplate_Create_Init(Id, getHeader());
API_SERVICE.HtmlTemplate_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.HTML_TEMPLATE_CREATE, body); // htmlTemplate_function.htmlTemplate_Create(body, API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.HTML_TEMPLATE_UPDATE, body); // htmlTemplate_function.htmlTemplate_Update(body, API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_Delete = (Ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.HTML_TEMPLATE_DELETE, Ids); // htmlTemplate_function.htmlTemplate_Delete(Ids, API_URL, constants, getHeader());  
API_SERVICE.HtmlTemplate_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.HTML_TEMPLATE_METADATA_LIST); // htmlTemplate_function.htmlTemplate_MetadataList(API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.HTML_TEMPLATE_LIST, body); // htmlTemplate_function.htmlTemplate_List(body, API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_ListModules = () => _.METHOD_GET(constants.API_URL + constants.HTML_TEMPLATE_LIST_MODULES); // htmlTemplate_function.htlTemplate_ListModules(API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_ListByModule = (body:object) => _.METHOD_POST(constants.API_URL + constants.HTML_TEMPLATE_LIST_BY_MODULES + "?module=" + body, body); // htmlTemplate_function.htmlTemplate_ListByModule(body, API_URL, constants, getHeader());
API_SERVICE.HtmlTemplate_Export_Excel = (body:object) => _.METHOD_POST(constants.API_URL + constants.HTML_TEMPLATE_EXPORT_EXCEL, body); // htmlTemplate_function.htmlTemplate_Export_Excel(body, getHeader());

/**
 * functions for emailTemplate api 
 * @keyword EMAILTEMPLATE
*/

API_SERVICE.EmailTemplate_Get = (Id:number) => _.METHOD_GET(constants.API_URL + constants.EMAIL_TEMPLATE_GET + Id); // emailTemplate_function.emailTemplate_Get(Id, API_URL, constants, getHeader());
API_SERVICE.EmailTemplate_Create = (body:object) => _.METHOD_POST(constants.API_URL + constants.EMAIL_TEMPLATE_CREATE, body); // emailTemplate_function.emailTemplate_Create(body, API_URL, constants, getHeader());
API_SERVICE.EmailTemplate_Create_Init = (Id:number) => _.METHOD_GET(constants.API_URL + constants.EMAIL_TEMPLATE_CREATE_INIT + (Id ? `/${Id}` : '')); // emailTemplate_function.emailTemplate_Create_Init(Id, getHeader());
API_SERVICE.EmailTemplate_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.EMAIL_TEMPLATE_UPDATE, body); // emailTemplate_function.emailTemplate_Update(body, API_URL, constants, getHeader());
API_SERVICE.EmailTemplate_Delete = (Ids:number[]) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.EMAIL_TEMPLATE_DELETE, Ids); // emailTemplate_function.emailTemplate_Delete(Id, API_URL, constants, getHeader());  
API_SERVICE.EmailTemplate_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.EMAIL_TEMPLATE_METADATA_LIST); // emailTemplate_function.emailTemplate_MetadaList(API_URL, constants, getHeader());
API_SERVICE.EmailTemplate_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.EMAIL_TEMPLATE_LIST, body); // emailTemplate_function.emailTemplate_List(body, API_URL, constants, getHeader());
API_SERVICE.EmailTemplate_Export_Excel = (body:object) => _.METHOD_POST(constants.API_URL + constants.EMAIL_TEMPLATE_EXPORT_EXCEL, body); // emailTemplate_function.emailTemplate_Export_Excel(body, getHeader());

/**
 * functions for form api 
 * @keyword FORM
*/

API_SERVICE.Reports_Modules = () => _.METHOD_POST(constants.API_URL + constants.REPORTS_MODULES, {});
API_SERVICE.Reports_Access_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.REPORTS_ACCESS_METADATA_LIST);
API_SERVICE.Reports_Access_Lists = (data:any) => _.METHOD_POST(constants.API_URL + constants.REPORTS_ACCESS_LIST, data);
API_SERVICE.Reports_Access_Get = (id:any) => _.METHOD_GET(constants.API_URL + constants.REPORTS_ACCESS_GET + id);
API_SERVICE.Reports_Access_Export_EXCEL = (data:any) => _.METHOD_POST(constants.API_URL + constants.REPORTS_ACCESS_EXPORT_EXCEL, data);
API_SERVICE.Reports_Access_Export_PDF = (data:any) => _.METHOD_POST(constants.API_URL + constants.REPORTS_ACCESS_EXPORT_PDF, data);

API_SERVICE.Reports_Audit_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.REPORTS_AUDIT_METADATA_LIST);
API_SERVICE.Reports_Audit_Lists = (data:any) => _.METHOD_POST(constants.API_URL + constants.REPORTS_AUDIT_LIST, data);
API_SERVICE.Reports_Audit_Get = (id:any) => _.METHOD_GET(constants.API_URL + constants.REPORTS_AUDIT_GET + id);
API_SERVICE.Reports_Audit_Export_EXCEL = (data:any) => _.METHOD_POST(constants.API_URL + constants.REPORTS_AUDIT_EXPORT_EXCEL, data);
API_SERVICE.Reports_Audit_Export_PDF = (data:any) => _.METHOD_POST(constants.API_URL + constants.REPORTS_AUDIT_EXPORT_PDF, data);

/**
 * functions for form api 
 * @keyword FORM
*/

API_SERVICE.Form_Get_Registry_File = (data:any) => form_function.Form_Get_Registry_File(data, getHeader());
API_SERVICE.Form_Get = (Id:number) => form_function.Form_Get(Id, API_URL, constants, getHeader());
API_SERVICE.Form_Create = (body:object) => form_function.Form_Create(body, API_URL, constants, getHeader());
API_SERVICE.Form_Create_Init = (Id:number) => form_function.Form_Create_Init(Id, getHeader());
API_SERVICE.Form_Update = (body:object) => form_function.Form_Update(body, API_URL, constants, getHeader());
API_SERVICE.Form_Delete = (Id:number[]) => form_function.Form_Delete(Id, API_URL, constants, getHeader());  
API_SERVICE.Form_MetadataList = () => form_function.Form_MetadataList(API_URL, constants, getHeader());
API_SERVICE.Form_List = (body:object) => form_function.Form_List(body, API_URL, constants, getHeader());

API_SERVICE.Form_Lists_Metadata = () => form_function.Form_Lists_Metadata(getHeader());
API_SERVICE.Form_Lists_List = (payload:object) => form_function.Form_Lists_List(payload, API_URL, constants, getHeader());
API_SERVICE.Form_Lists_Get = (Id:number) => form_function.Form_Lists_Get(Id, API_URL, constants, getHeader());
API_SERVICE.Form_Lists_Create_Init = (id:number) => _.METHOD_GET(constants.API_URL + constants.FORM_LISTS_CREATE_INIT + id);
API_SERVICE.Form_Lists_Create = (body:object) => form_function.Form_Lists_Create(body, API_URL, constants, getHeader());
API_SERVICE.Form_Lists_Update = (body:object) => form_function.Form_Lists_Update(body, API_URL, constants, getHeader());
API_SERVICE.Form_Lists_Delete = (Ids:Array<number>) => form_function.Form_Lists_Delete(Ids, API_URL, constants, getHeader());
API_SERVICE.Form_Lists_Export = (data:any) => _.METHOD_POST(constants.API_URL + constants.FORM_LISTS_EXPORT, data);
API_SERVICE.Form_Lists_Import_Excel = (data:any) => _.METHOD_POST(constants.API_URL + constants.FORM_LISTS_IMPORT_EXCEL + data.extra, data.payload);

API_SERVICE.Form_List_Registry = (id:number, filters:any) => _.METHOD_POST(constants.API_URL + constants.FORM_LIST_REGISTRY + id, filters);
API_SERVICE.Form_Data_Type = () => form_function.Form_Data_Type(API_URL, constants,getHeader());
API_SERVICE.Form_List_Behavior = () => form_function.Form_List_Behavior(API_URL, constants, getHeader());
API_SERVICE.Form_Get_Preview= (body:object) => form_function.Form_Get_Preview(API_URL, constants, body, getHeader());
API_SERVICE.Form_Generate_Form= (body:object) => form_function.Form_Generate_Form(API_URL, constants, body, getHeader());

API_SERVICE.Form_Registry_Export_Excel = (Id:number, filter:any) => _.METHOD_POST(constants.API_URL + constants.FORM_REGISTRY_EXPORT_EXCEL + Id, filter); //form_function.Form_Registry_Export_Excel(Id, getHeader());
API_SERVICE.Form_Export_Excel = (payload:any) => form_function.Form_Export_Excel(payload, getHeader());
/**
 * functions for recyclebin api 
 * @keyword RECYCLEBIN
*/

API_SERVICE.Recyclebin_List = (module:string) => _.METHOD_POST(constants.API_URL + constants.RECYCLE_LIST, module);// recyclebin_function.Recycle_List(module, getHeader());
API_SERVICE.Recyclebin_RetrieveItem = (data:any) => _.METHOD_POST(constants.API_URL + constants.RECYCLE_RETRIEVE_ITEM+ '?id='+ (data.id || data.Id) + '&module='+ data.keyModule , {}); // recyclebin_function.Recycle_RetrieveItem(data, API_URL, constants, getHeader());
API_SERVICE.Recyclebin_Batch_Retrieve = (data:any) => _.METHOD_POST(constants.API_URL + constants.RECYCLE_BATCH_RETRIEVE, data); // recyclebin_function.Recycle_BatchRetrieve(data, getHeader());
API_SERVICE.Recyclebin_MetadataList = () => _.METHOD_GET(constants.API_URL + constants.RECYCLE_METADATA_LIST); // recyclebin_function.Recycle_MetadataList(API_URL, constants, getHeader());
API_SERVICE.Recyclebin_Export_Excel = (data:any) => _.METHOD_POST(constants.API_URL + constants.RECYCLE_EXPORT_EXCEL, data); // recyclebin_function.Recycle_Export_Excel(data, getHeader());
API_SERVICE.Recyclebin_Modules = () => _.METHOD_POST(constants.API_URL + constants.RECYCLE_MODULES, {}); // recyclebin_function.Recycle_Modules(getHeader());
API_SERVICE.Recyclebin_Permanently_Delete = (modulesIds: any) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.RECYCLE_PERMANENTLY_DELETE, modulesIds);

/**
 * functions for news api 
 * @keyword ADMINS
*/

API_SERVICE.Admin_Assign_Roles = (body:any) => _.METHOD_POST(constants.API_URL + constants.ADMIN_ASSIGN_ROLES, body) // admin_function.Admin_Assign_Roles(body, getHeader());
API_SERVICE.Admin_Update_Roles = (body:any) => _.METHOD_POST(constants.API_URL + constants.ADMIN_UPDATE_ROLES, body);
API_SERVICE.Admin_List = (body:object) => _.METHOD_POST(constants.API_URL + constants.ADMIN_LIST, body) // admin_function.Admin_List(body, getHeader());
API_SERVICE.Admin_Metadata = () => _.METHOD_GET(constants.API_URL + constants.ADMIN_METADATA) // admin_function.Admin_Metadata(getHeader());
API_SERVICE.Admin_Register = (body:object) => _.METHOD_POST(constants.API_URL + constants.ADMIN_REGISTER, body) // admin_function.Admin_Register(body, getHeader());
API_SERVICE.Admin_Update = (body:object) => _.METHOD_POST(constants.API_URL + constants.ADMIN_UPDATE, body) // admin_function.Admin_Update(body, getHeader());
API_SERVICE.Admin_Get = (id:number) => _.METHOD_GET(constants.API_URL + constants.ADMIN_GET + id) // admin_function.Admin_Get(id, getHeader());
API_SERVICE.Admin_Delete = (id:number) => _.METHOD_DELETE(constants.API_URL + constants.ADMIN_DELETE + id) // admin_function.Admin_Delete(id, getHeader());
API_SERVICE.Admin_Batch_Delete = (ids:Array<number>) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.ADMIN_BATCH_DELETE, ids) // admin_function.Admin_Batch_Delete(ids, getHeader());
API_SERVICE.Admin_Batch_Pre_Active = (ids:Array<number>) => _.METHOD_POST(constants.API_URL + constants.ADMIN_BATCH_PRE_ACTIVE, ids) // admin_function.Admin_Batch_Pre_Active(ids, getHeader());
API_SERVICE.Admin_Active_Account = (body:object) => _.METHOD_POST(constants.API_URL + constants.ADMIN_ACTIVE_ACCOUNT, body) // admin_function.Admin_Active_Account(body, getHeader());
API_SERVICE.Admin_Export_Excel = (body:object) => _.METHOD_POST(API_URL + constants.ADMIN_EXPORT_EXCEL, body);

/**
 * functions for courses api 
 * @keyword ROL
*/

API_SERVICE.Rol_List = (data:any) => _.METHOD_POST(API_URL + constants.ROL_LIST, data);
API_SERVICE.Rol_Metadata = () => _.METHOD_GET(API_URL + constants.ROL_METADATA);
API_SERVICE.Rol_Update = (data:any) => _.METHOD_UPDATE(API_URL + constants.ROL_UPDATE, data);
API_SERVICE.Rol_Create = (data:any) => _.METHOD_POST(API_URL + constants.ROL_CREATE, data);
API_SERVICE.Rol_Create_Init = (id:any) => _.METHOD_GET(API_URL + constants.ROL_CREATE_INIT + id);
API_SERVICE.Rol_Get = (id:any) => _.METHOD_POST(API_URL + constants.ROL_GET + id, {id});
API_SERVICE.Rol_Delete = (id:any) => _.METHOD_MULTIPLE_DELETE(API_URL + constants.ROL_BATCH_DELETE, id);
API_SERVICE.Rol_Export = (data:any) => _.METHOD_POST(constants.API_URL + constants.ROL_EXPORT, data);
API_SERVICE.Rol_Module_Items = (params:string) => _.METHOD_POST(constants.API_URL + constants.ROL_MODULE_ITEMS  + params, {});

/**
 * functions for courses api 
 * @keyword COURSES
*/

API_SERVICE.ELEARNING_COURSES_METADATA = () => _.METHOD_GET(constants.API_URL + constants.ELEARNING_COURSES_METADATA); // courses_function.ELEARNING_COURSES_METADATA(getHeader());
API_SERVICE.ELEARNING_COURSES_LIST = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_COURSES_LIST, data); // courses_function.ELEARNING_COURSES_LIST(data, getHeader());
API_SERVICE.ELEARNING_COURSES_GET = (id:number) => _.METHOD_GET(constants.API_URL + constants.ELEARNING_COURSES_GET + id); // courses_function.ELEARNING_COURSES_GET(id, getHeader());
API_SERVICE.ELEARNING_COURSES_UPDATE = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_COURSES_UPDATE, data); // courses_function.ELEARNING_COURSES_UPDATE(data, getHeader());
API_SERVICE.ELEARNING_COURSES_EXPORT_EXCEL = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_COURSES_EXPORT_EXCEL, data); // courses_function.ELEARNING_COURSES_EXPORT_EXCEL(data, getHeader());
API_SERVICE.ELEARNING_COURSES_SYNCHRONIZE = () => _.METHOD_GET(constants.API_URL + constants.ELEARNING_COURSES_SYNCHRONIZE); // courses_function.ELEARNING_COURSES_SYNCHRONIZE(getHeader());
API_SERVICE.ELEARNING_COURSES_ASSIGN = (id:number, data:Array<number>) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_COURSES_ASSIGN + id, data); // courses_function.ELEARNING_COURSES_ASSIGN(id, data, getHeader());

/**
 * functions for canvas api 
 * @keyword CANVAS
*/

API_SERVICE.ELEARNING_CANVAS_COURSE_METADATA = () => _.METHOD_GET(constants.API_URL + constants.ELEARNING_CANVAS_COURSE_METADATA); //canvas_function.ELEARNING_CANVAS_COURSE_METADATA(getHeader());
API_SERVICE.ELEARNING_CANVAS_COURSE_LIST = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_CANVAS_COURSE_LIST, data); // canvas_function.ELEARNING_CANVAS_COURSE_LIST(data, getHeader());
API_SERVICE.ELEARNING_CANVAS_COURSE_GET = (id:number) => _.METHOD_GET(constants.API_URL + constants.ELEARNING_CANVAS_COURSE_GET + id); // canvas_function.ELEARNING_CANVAS_COURSE_GET(id, getHeader());
API_SERVICE.ELEARNING_CANVAS_COURSE_UPDATE = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_CANVAS_COURSE_UPDATE, data); // canvas_function.ELEARNING_CANVAS_COURSE_UPDATE(data, getHeader());
API_SERVICE.ELEARNING_CANVAS_COURSE_SYNCHRONIZE = () => _.METHOD_GET(constants.API_URL + constants.ELEARNING_CANVAS_COURSE_SYNCHRONIZE); // canvas_function.ELEARNING_CANVAS_COURSE_SYNCHRONIZE(getHeader());
API_SERVICE.ELEARNING_CANVAS_COURSE_EXPORT_EXCEL = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_CANVAS_COURSE_EXPORT_EXCEL, data); // canvas_function.ELEARNING_CANVAS_COURSE_EXPORT_EXCEL(data, getHeader());

/**
 * functions for masterPage-Home api 
 * @keyword MASTER-PAGE HOME
*/

API_SERVICE.MASTER_PAGE_HOME_GET = (Id:number) => _.METHOD_GET(constants.API_URL + constants.MASTER_PAGE_HOME_GET + Id); // masterPage_function.MASTER_PAGE_HOME_GET(Id, getHeader()); 
API_SERVICE.MASTER_PAGE_HOME_ASSIGNED_ELEMENT = (data:object) => _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_ASSIGNED_ELEMENT, data); // masterPage_function.MASTER_PAGE_HOME_ASSIGNED_ELEMENT(data, getHeader()); 
API_SERVICE.MASTER_PAGE_HOME_UPDATE = (data:object) =>  _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_UPDATE, data); // masterPage_function.MASTER_PAGE_HOME_UPDATE(data, getHeader()); 
API_SERVICE.MASTER_PAGE_HOME_LIST = () => _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_LIST, null); // masterPage_function.MASTER_PAGE_HOME_LIST(getHeader()); 
API_SERVICE.MASTER_PAGE_HOME_EXPORT_EXCEL = () => _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_EXPORT_EXCEL, null); // masterPage_function.MASTER_PAGE_HOME_EXPORT_EXCEL(getHeader()); 
API_SERVICE.MASTER_PAGE_HOME_METADATA = () => _.METHOD_GET(constants.API_URL + constants.MASTER_PAGE_HOME_METADATA); // masterPage_function.MASTER_PAGE_HOME_METADATA(getHeader()); 
API_SERVICE.MASTER_PAGE_HOME_UPDATE_COOKIES = (data:any) => _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_UPDATE_COOKIES, data); 
API_SERVICE.MASTER_PAGE_HOME_MENU_LIST_ITEM = (module:string) => _.METHOD_GET(constants.API_URL + constants.MASTER_PAGE_HOME_MENU_LIST_ITEM + module); 
API_SERVICE.MASTER_PAGE_HOME_MENU_COPY_MENU = (id:number) => _.METHOD_GET(constants.API_URL + constants.MASTER_PAGE_HOME_MENU_COPY_MENU + id); 
API_SERVICE.MASTER_PAGE_HOME_MENU_ADD_MENU = (id:string, data:any) => _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_MENU_ADD_MENU + id, data); 
API_SERVICE.MASTER_PAGE_HOME_MENU_UPDATE_MENU = (data:any) => _.METHOD_POST(constants.API_URL + constants.MASTER_PAGE_HOME_MENU_UPDATE_MENU, data); 
/**
 * functions for group api 
 * @keyword GROUP
*/

API_SERVICE.ELEARNING_GROUP_METADATA = () => _.METHOD_GET(constants.API_URL + constants.ELEARNING_GROUP_METADATA); // group_function.ELEARNING_GROUP_METADATA(getHeader());
API_SERVICE.ELEARNING_GROUP_LISTA = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_GROUP_LISTA, data); // group_function.ELEARNING_GROUP_LISTA(data, getHeader());
API_SERVICE.ELEARNING_GROUP_GET = (id:number) => _.METHOD_GET(constants.API_URL + constants.ELEARNING_GROUP_GET + id); // group_function.ELEARNING_GROUP_GET(id, getHeader());
API_SERVICE.ELEARNING_GROUP_CREATE_INIT = (id:number) => _.METHOD_GET(constants.API_URL + constants.ELEARNING_GROUP_CREATE_INIT + (id ? `/${id}` : ``)); // group_function.ELEARNING_GROUP_CREATE_INIT(id, getHeader())
API_SERVICE.ELEARNING_GROUP_UPDATE = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_GROUP_UPDATE, data); // group_function.ELEARNING_GROUP_UPDATE(data, getHeader());
API_SERVICE.ELEARNING_GROUP_CREATE = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_GROUP_CREATE, data); // group_function.ELEARNING_GROUP_CREATE(data, getHeader());
API_SERVICE.ELEARNING_GROUP_DELETE = (id:number) => _.METHOD_DELETE(constants.API_URL + constants.ELEARNING_GROUP_DELETE + id); //group_function.ELEARNING_GROUP_DELETE(id, getHeader());
API_SERVICE.ELEARNING_GROUP_BATCH_DELETE = (ids:Array<number>) => _.METHOD_MULTIPLE_DELETE(constants.API_URL + constants.ELEARNING_GROUP_BATCH_DELETE, ids); // group_function.ELEARNING_GROUP_BATCH_DELETE(data, getHeader());
API_SERVICE.ELEARNING_GROUP_EXPORT_EXCEL = (data:object) => _.METHOD_POST(constants.API_URL + constants.ELEARNING_GROUP_EXPORT_EXCEL, data); // group_function.ELEARNING_GROUP_EXPORT_EXCEL(data, getHeader());

/**
 * functions for settings 
 * @keyword Settings
*/

API_SERVICE.Settings_List_RRSS = () => _.METHOD_GET(constants.API_URL + constants.SETTINGS_LIST_RRSS) //settings_function.Settings_List_RRSS(getHeader());
API_SERVICE.Settings_List = () =>  _.METHOD_GET(constants.API_URL + constants.SETTINGS_LIST);
API_SERVICE.Settings_Update = (data) => _.METHOD_POST(constants.API_URL + constants.SETTINGS_UPDATE, data)// settings_function.Settings_Update(data, getHeader());
API_SERVICE.Settings_Update_General = (data) => _.METHOD_POST(constants.API_URL + constants.SETTINGS_UPDATE_GENERAL + data.category, data)// settings_function.Settings_Update(data, getHeader());
API_SERVICE.Settings_Update_Tags = (data) => _.METHOD_POST(constants.API_URL + constants.SETTINGS_UPDATE_TAGS + data.category, data)// settings_function.Settings_Update(data, getHeader());
API_SERVICE.Settings_Export_Excel_RRSS = () => _.METHOD_POST(constants.API_URL + constants.SETTINGS_EXPORT, {}); // settings_function.Settings_Expot_Excel_RRSS(getHeader());

/**
 * functions for upload 
 * @keyword UPLOAD
*/

API_SERVICE.Upload = (body:FormData, fileName:string) => _.METHOD_POST(API_URL + constants.UPLOAD + '?path='+ fileName, body); //Upload(body,API_URL,constants,getHeader(), file);

/**
 * functions for upload 
 * @keyword COMMON
*/

API_SERVICE.Commmon_Cancel_Creation = (route_path:string) => _.METHOD_POST(`${constants.API_URL}${constants.COMMON_CANCEL_CREATION}?temp_path=${route_path}`, {temp_path: route_path}); // common_function.Common_Cancel_Creation(route, getHeader());
API_SERVICE.tinyJwt = () => _.METHOD_POST(`${constants.API_URL}v1/admin/file/tiny-jwt`, {})

export default API_SERVICE;