import { takeLatest, put } from 'redux-saga/effects';
import ActionType from '../constants';
import * as Actions from './../actions';

function* EConfirmChangeModule (action:any):any { yield put(Actions.AConfirmChangeModule_ADD(action.payload)); }
function* EIsSuperModule (action:any):any { yield put(Actions.AIsSuperModule_ADD(action.payload)); }
function* EChange_Value_Buttons_General (action:any):any { yield put(Actions.ACHANGE_VALUE_BUTTONS_GENERAL_ADD(action.payload)); }

export function* WConfirmChangeModule():any { return yield takeLatest(ActionType.CONFIRM_CHANGE_MODULE_CALL, EConfirmChangeModule) };
export function* WIsSuperModule():any { return yield takeLatest(ActionType.IS_SUPER_MODULE_CALL, EIsSuperModule) };
export function* WChange_Value_Buttons_General():any { return yield takeLatest(ActionType.ACHANGE_VALUE_BUTTONS_GENERAL_CALL, EChange_Value_Buttons_General) };