import * as React from "react";
import { Route } from "react-router";
import jwtDecode from 'jwt-decode';
import NotPermission from './../modules/others/errorNotPermission';
import TemplateBase from "./global/templateBase";

export const JwtVerify = () => {
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  if (token && user) {
    const payload = JSON.stringify(jwtDecode(token));
    const exp = JSON.parse(payload).exp;
    if (exp < Date.now() / 1000) {
      localStorage.clear();
      return false;
    }
    return true;
  }
  return false;
}

const allModulesAndRoutes:any = {
  SUP: process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN,
  HOM: 'master-page-home',
  REP: process.env.REACT_APP_ROUTE_PATH_MAIN_REPORTS,
  ZOP: process.env.REACT_APP_ROUTE_PATH_MAIN_WIDGETS,
  PAG: process.env.REACT_APP_ROUTE_PATH_MAIN_PAGINAS,
  NOT: process.env.REACT_APP_ROUTE_PATH_MAIN_NOTICIAS,
  EVE: process.env.REACT_APP_ROUTE_PATH_MAIN_EVENTOS,
  GAL: process.env.REACT_APP_ROUTE_PATH_MAIN_GALERIA,
  FRM: process.env.REACT_APP_ROUTE_PATH_MAIN_FORMULARIO,
  BAN: process.env.REACT_APP_ROUTE_PATH_MAIN_BANNER,
  EML: process.env.REACT_APP_ROUTE_PATH_MAIN_EMAIL_TEMPLATE,
  HTM: process.env.REACT_APP_ROUTE_PATH_MAIN_HTML_TEMPLATE,

  //custom
  DSH: process.env.REACT_APP_ROUTE_PATH_MAIN
}

const defaultRoutes:string[] = [
  `${process.env.REACT_APP_ROUTE_PATH_MAIN}`,
  `${process.env.REACT_APP_ROUTE_PATH_MAIN_CAMBIAR_CONTRASENA}`,
  `${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`
];

const getModulesAsigned = (): string | string[] => {
  const user:string | null = localStorage.getItem('user');
  if(user){
    const userParse = JSON.parse(user);
    if(typeof userParse?.modules === 'string')
      return userParse?.modules;
    return Object.keys(userParse?.modules || {});
  }

  return [];
}


const canAccessThePath = (path:string):boolean => {
  let valueReturn:boolean = false;
  const modulesAsigned = getModulesAsigned();

  // si tiene permiso total
  if(typeof modulesAsigned === 'string') return true;

  // si entra a una ruta por default
  const findedRoute = defaultRoutes.filter(item => path.indexOf(item) >= 0);
  if(findedRoute.length > 0) return true;

  // si no tiene ningún permiso
  if(modulesAsigned.length === 0) return valueReturn;

  (modulesAsigned as string[]).forEach(item => {
    if(valueReturn) return;
    const route:string = allModulesAndRoutes[item];
    if(route){
      if(path.indexOf(route) >= 0)
        valueReturn = true;
    }
  })

  return valueReturn;
}

const theRouteExists = (path:string):boolean => {
  let valueReturn:boolean = false;
  Object.values(allModulesAndRoutes).forEach(route => {
    if(valueReturn) return;

    if(path.indexOf(route as string) >= 0)
      valueReturn = true;
  })

  return valueReturn;
}

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const pathName = (rest.location.pathname || '' as string).toLowerCase();
  return (
    <Route
      {...rest}
      render={props =>
        JwtVerify() ? (

          theRouteExists(pathName) ? (
            canAccessThePath(pathName) ? (
              <Component {...props} />
            )  : (
              <TemplateBase>
                <NotPermission />
              </TemplateBase>
            )
          ) : (
            // hay un componente que muestra una pantalla not found :D
            <Component {...props} /> 
          ) 

        ) : (
          window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}?continue=${encodeURIComponent(window.location.pathname)}`
        )
      }
    />
);
}

/*export const isAdmin = () => {
  const user = (JSON.parse( (localStorage.getItem('user') || {}).toString() )).user_info;
  if(user.isSuper === true) return true;
  else return false;
}*/

export const PrivateRouteAdmin = ({component: Component, ...rest}: any) => {
  const pathName = (rest.location.pathname || '' as string).toLowerCase();
  const value = canAccessThePath(pathName);

  return (
    <Route
      {...rest}
      render={props =>
        value ? (
          <Component {...props} />
        ) : (
          <NotPermission />
        )
      }
    />
  )
};