import React, { Suspense } from 'react';
import { Result, Button } from 'antd';
import { withRouter } from 'react-router';
import { Icon } from 'antd';

const logoMagia = 'logo';

class ErrorNotFound extends React.PureComponent<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            time: 5
        }
    }

    componentDidMount(){
        this.redirectTime();
    }

    redirectTime = () => {
        /*setTimeout(() => {
            this.onRedirect();
        }, 7500);*/
    }

    onRedirect = () => {
        return this.props.history.replace('/dashboard')
    }

    discountTime = () => {
        const { time } = this.state;
        setTimeout(() => { 
           if(time === 0) return clearTimeout();
            this.setState({time: time - 1})
        }, 1500);
    }

    render() {
        this.discountTime();
        return (
            <>
                <div className="title__zone">
                    <div className="logo">
                        <Suspense fallback={<div>Loading...</div>}>
                            <Icon component={React.lazy(() => import(`./../../assets/img/${logoMagia}.svg`))} />
                        </Suspense>
                    </div>
                    <div className="title">
                        <h2>Fénix CMS</h2>
                        <h3>ERROR 404</h3>
                    </div>
                </div>
                <Result
                    status="404"
                    title="404"
                    subTitle="Lo sentimos, la página que estás buscando no se encuentra disponible o no existe."
                    extra={<Button type="danger" onClick={this.onRedirect}>Regresar {/*time*/}</Button>}
                />
            </>
        )
    }
}

export default withRouter(ErrorNotFound);