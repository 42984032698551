export interface EntityUser {
    user_info:      UserInfo;
    user_dashboard: {
        dashboard: Dashboard;
        menu: Dashboard;
    };
    settings: EntityUserSettings;
    modules: Object;
}

interface EntityUserSettings {
    url_cms: string;
    url_portal: string;
    file_size_mb: string;
    items_per_page: string;
    delete_limit_days: string;
}

interface Dashboard {
    normal: Normal[];
    superA: Normal[];
}

interface Normal {
    name:      string;
    label:     string;
    url:       string;
    moreUsing: boolean;
}

interface UserInfo {
    id:        number;
    email:     string;
    firstName: string;
    lastName:  string;
    isSuper:   boolean;
}

export const APP_SETTINGS = () => {
    let settings: EntityUserSettings = {
        delete_limit_days: "",
        file_size_mb: "",
        items_per_page: "",
        url_cms: "",
        url_portal: ""
    }

    const response: string = window.localStorage["user"];
    const user: EntityUser | undefined = JSON.parse(response);

    if (!!user) {
        Object.assign(settings, {
            delete_limit_days: parseInt(user.settings?.delete_limit_days ?? "0"),
            file_size_mb: parseInt(user.settings?.file_size_mb ?? "10"),
            items_per_page: parseInt(user.settings?.items_per_page ?? "10"),
            url_cms: user.settings?.url_cms || "",
            url_portal: user.settings.url_portal || "",
        });
    }

    return settings;
}