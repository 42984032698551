import React from 'react';
import Loadable from 'react-loadable';

const Loading = () => {
    return <p>Loading</p>
}

export const MiDatos =  Loadable({
  loader: () => import('./options/MiDatos'),
  loading: Loading
})

export const ChangePassword =  Loadable({
  loader: () => import('./options/ChangePassword'),
  loading: Loading
})

export const GalleryCreateEdit =  Loadable({
  loader: () => import('./gallery/Form/index'),
  loading: Loading
})

export const ListGallery = Loadable({
  loader: () => import('./gallery/List/index'),
  loading: Loading,
});

export const ListInternalPage = Loadable({
  loader: () => import('./internalPage/List/index'),
  loading: Loading
});

export const InternalPageCreateEdit = Loadable({
  loader : () => import('./internalPage/Form/index'),
  loading: Loading
});

export const ListPageZone = Loadable({
  loader: () => import('./pageZone/List'),
  loading: Loading
});

export const PageZoneCreateEdit = Loadable({
  loader : () => import('./pageZone/Form/index'),
  loading: Loading
});

export const ListBanner = Loadable({
  loader: () => import('./banner/List/index'),
  loading: Loading
});

export const BannerCreateEdit = Loadable({
  loader: () => import('./banner/Form/index'),
  loading: Loading
});

export const ListNews = Loadable({
  loader: () => import('./news/List/index'),
  loading: Loading
});

export const NewsCreateEdit = Loadable({
  loader: () => import('./news/Form/index'),
  loading:Loading
})

export const ListEvent = Loadable({
  loader: () => import('./event/List/index'),
  loading:Loading
})

export const EventCreateEdit = Loadable({
  loader: () => import('./event/Form/index'),
  loading: Loading
})

export const ListHtmlTemplate = Loadable({
  loader: () => import('./htmlTemplate/List/index'),
  loading: Loading
})

export const HtmlTemplateCreateEdit = Loadable({
  loader: () => import('./htmlTemplate/Form/index'),
  loading: Loading
})

export const ListEmailTemplate = Loadable({
  loader: () => import('./emailTemplate/List/index'),
  loading: Loading
})

export const EmailTemplateCreateEdit = Loadable({
  loader: () => import('./emailTemplate/Form/index'),
  loading: Loading
})

export const Form = Loadable({
  loader: () => import('./Form/index'),
  loading: Loading
})

export const ListForm = Loadable({
  loader: () => import('./Form/formForm/List/index'),
  loading: Loading
})

export const FormCreateEdit = Loadable({
  loader: () => import('./Form/formForm/Form/index'),
  loading: Loading
})

export const ListRegisterForm = Loadable({
  loader: () => import('./Form/formRegister/List'),
  loading: Loading
})

export const FormSimple = Loadable({
  loader: () => import('./Form/formSimple/form'),
  loading: Loading
})

export const ListSimple = Loadable({
  loader: () => import('./Form/formSimple/list'),
  loading:Loading
})

export const FormList = Loadable({
  loader: () => import('./Form/formList/List'),
  loading: Loading
})

export const FormListForm = Loadable({
  loader: () => import('./Form/formList/form'),
  loading: Loading
})


export const MasterPage = Loadable({
  loader: () => import('./masterpage'),
  loading: Loading
})

export const MasterPageConfiguration = Loadable({
  loader: () => import('./masterpage/listConfiguration'),
  loading: Loading
})



export const adminDashboard = Loadable({
  loader: () => import('./superAdmin/dashboard'),
  loading:Loading
})

export const adminRecycle = Loadable({
  loader: () => import('./superAdmin/recyclein'),
  loading:Loading
})

export const adminList = Loadable({
  loader: () => import('./superAdmin/admin/List/index'),
  loading:Loading
})

export const adminForm = Loadable({
  loader: () => import('./superAdmin/admin/form/form'),
  loading:Loading
})

export const settings = Loadable({
  loader: () => import('./superAdmin/settings'),
  loading:Loading
})

export const Reports = Loadable({
  loader: () => import('./reports/index'),
  loading:Loading
})



export const ELearningDashboard = Loadable({
  loader: () => import('./E-Learning/dashboard'),
  loading:Loading
})

export const ELearningCourseList = Loadable({
  loader: () => import('./E-Learning/course/list'),
  loading:Loading
})

export const ELearningCourseForm = Loadable({
  loader: () => import('./E-Learning/course/form'),
  loading:Loading
})

export const ELearningCanvasCourseList = Loadable({
  loader: () => import('./E-Learning/canvas/list'),
  loading:Loading
})

export const ELearningCanvasCourseForm = Loadable({
  loader: () => import('./E-Learning/canvas/form'),
  loading:Loading
})

export const ELearningGroupList = Loadable({
  loader: () => import('./E-Learning/speciality/list'),
  loading:Loading
})

export const ELearningGroupForm = Loadable({
  loader: () => import('./E-Learning/speciality/form'),
  loading:Loading
})

// export const Inventory_CategoriaForm = Loadable({
//   loader: () => import('../module/inventory/category/form'),
//   loading: Loading,
// });