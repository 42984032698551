import ActionType from "../constants";

const initialState = {
    auditMetadata: [],
    Ametadata: [],
    auditModules: []
}

const REmailTemplate = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.REPORTS_ACCESS_LISTS_INIT_ADD: 
            return {
                ...state,
                Alist: action.payload.list,
                Atotal: action.payload.total,
                Ametadata: action.payload.metadata || state.Ametadata
            }
        case ActionType.REPORTS_AUDIT_LISTS_INIT_ADD: 
            return {
                ...state,
                auditList: action.payload.list,
                auditTotal: action.payload.total,
                auditMetadata: action.payload.metadata || state.auditMetadata,
                auditModules: action.payload.modules || state.auditModules
            }
        default: 
            return state
    }
}

export default REmailTemplate;