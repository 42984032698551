import { combineReducers } from 'redux';
import course from './course';
import group from './group';
import canvas from './canvas';

const RELearning = combineReducers({
    course,
    group,
    canvas
})

export default RELearning;