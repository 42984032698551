import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import {AGallery_Add_Get, AGallery_Content_Add_Get, AGallery_Content_Add_List, AGallery_Content_Add_MetadaList, AGallery_Content_Add_Type, AGallery_Categories_Add } from '../actions';
import * as Actions from './../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { EyeOutlined } from '@ant-design/icons';
import { APP_SETTINGS } from '../../constants/app_settings';

const style ={
    cursor:'pointer',
    color:'#1890ff'
}
function* EGallery_Category_List(action:any): any{
try{ 
    const data = (yield call(API_SERVICE.Gallery_Category_List)).data.result;
    const metadataList:Array<any> = (yield call(API_SERVICE.Gallery_Category_MetadataList)).data.result;

    metadataList?.map((item:any) => {
        if(item.link){
            item.render = (text: any, record: any) => <span style={style} onClick={() => action.extraFunction(record.id)}>{text}</span>
        }
    })
    
    const _listCategory:Array<any> = Functions.ListUpperLowerCase(data);
    yield put(Actions.AGallery_Category_Add_List(_listCategory));
    yield put(Actions.AGallery_Category_Add_MetadataList(metadataList));
    action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EGallery_Category_Update(action:any): any{
    try{
        const _result = (yield call(API_SERVICE.Gallery_Category_Update, action.payload)).data;
        const data = (yield call(API_SERVICE.Gallery_Category_List)).data.result;
        const _listCategory:Array<any> = Functions.ListUpperLowerCase(data);
        yield put(Actions.AGallery_Category_Add_List(_listCategory));
        action.resolve(_result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Category_Create(action:any): any{
    try{
        const _result = (yield call(API_SERVICE.Gallery_Category_Create, action.payload)).data;
        const data = (yield call(API_SERVICE.Gallery_Category_List)).data.result;
        const _listCategory:Array<any> = Functions.ListUpperLowerCase(data);
        yield put(Actions.AGallery_Category_Add_List(_listCategory));
        action.resolve(_result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Category_Delete(action:any): any{
    try{
        const message = (yield call(API_SERVICE.Gallery_Category_Batch_Delete, action.payload)).data.message;
        const data = (yield call(API_SERVICE.Gallery_Category_List)).data.result;
        const _listCategory:Array<any> = Functions.ListUpperLowerCase(data);
        yield put(Actions.AGallery_Category_Add_List(_listCategory));
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}


function* EGallery_Categories_Call(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Gallery_Categories)).data.result;
        yield put(AGallery_Categories_Add(data))
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}


function* EGallery_Get(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Gallery_Get, action.payload)).data.result;
        data.data = Functions.ObjectLowerCase(data.data);
        if(data.contentList.items) data.contentList.items = Functions.ListUpperLowerCase(data.contentList.items);
        if(data.categories) data.categories = Functions.ListUpperLowerCase(data.categories);
        yield put(AGallery_Add_Get(data))
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Create_Init(action:any):any {
    try {
        const data = (yield call(API_SERVICE.Gallery_Create_Init, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        if(data.categories) data.categories = Functions.ListUpperLowerCase(data.categories);
        yield put(Actions.AGallery_Add_Create_Init(data));
        action.resolve();
    } catch(error) {
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Update(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Gallery_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Create(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Gallery_Create, action.payload)).data;
        //yield put(AGallery_Add_Create(id))
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Delete(action:any): any{
    try{
        const filter = {perfil: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
        const message = (yield call(API_SERVICE.Gallery_Delete, action.payload.ids)).data.message;
        const data = (yield call(API_SERVICE.Gallery_List, filter)).data.result;
        yield put (Actions.AGallery_Add_List(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Metadata_List(action:any):any{
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.Gallery_MetadataList)).data.result;
            const listCategory = (yield call(API_SERVICE.Gallery_Category_List)).data.result;
            //convertir la lista a mayuscula y minuscula
            const _listCategory:Array<any> = Functions.ListUpperLowerCase(listCategory);

            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={ `/${process.env.REACT_APP_ROUTE_PATH_MAIN_GALERIA}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.id}` }>{text}</Link>;
                }

                if(element.filterItem && element.key === 'Category'){
                    element.filters =  _listCategory?.map((category) => ({text: category.description, value: category.id + ""}));
                }

                if(element.link && element.key === "portal") {
                    element.render = (text:any, record: any) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <a href={`${element.linkPortal}${record.PermanentLink}`} target="_blank" rel="noreferrer">
                                <EyeOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
                            </a>
                        </div>
                    )
                }

                if(element.key === "preview") {
                    element.render = (text:any, record: any) => (
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <EyeOutlined style={{ fontSize: "20px", color: "#1890ff" }} onClick={() => action.activePreview(record)}/>
                        </div>
                    )
                }
            });

            yield put(Actions.AGallery_Category_Add_List(_listCategory));
            yield put (Actions.AGallery_Add_MetadaList(metadata));
        }

        const data = (yield call(API_SERVICE.Gallery_List, action.payload)).data.result;
        yield put (Actions.AGallery_Add_List(data));
        
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Settings_Call(action:any):any {
    try{
        let data = (yield call(API_SERVICE.Gallery_Settings)).data.result;
        data = Functions.ObjectLowerCase(data);

        let fields:Array<any> = [];
        let initialValue:any = {};

        data.lista?.forEach((item:any) => { 
            let temp:any = {};
            if(item.identifier === "gallery_header_type"){
                temp.conditionals = [
                    {
                        operator: "01", valueOperator: "I",
                        conditioneds: [{
                            behaviour: "01",
                            id: "gallery_header_image",
                            type: "F"
                        }]
                    },
                    {
                        operator: "01", valueOperator: "B",
                        conditioneds: [{
                            behaviour: "01",
                            id: "gallery_header_banner",
                            type: "F"
                        }]
                    }
                ];
                temp.type = "radioWidget";
                temp.options = [
                    {text:"Imagen Principal", value:"I"},
                    {text:"Banner", value:"B"},
                ]
                temp.useConditional = true;
                temp.validations = [
                    {
                      type: "required",
                      params: [
                        "Selecciona Banner / Imagen Principal"
                      ]
                    }
                ]
            } 
            else if(item.identifier === "gallery_header_image"){
                temp.type = "image";
                temp.behaviour = "03";
            }
            else if(item.identifier === "gallery_header_banner"){
                temp.type = "selectDetail"; 
                temp.options = data.banners?.map((item:any) => ({id: item.Id, value: item.Name}));
                temp.validations = [];
                temp.behaviour = "03";
            } 
            else if(item.identifier === "gallery_header_description") {
                temp.typeInput = "string";
                temp.type = "editor";
            }
            else {
                temp.typeInput = "string";
                temp.type = "text";
            }

            //temp.idOriginal = item.id;
            temp.id = item.identifier;
            temp.label = item.description;
            temp.value = item.value;
            initialValue[item.identifier] = item.value;
            fields.push(temp);
        });

        data.fields = fields;
        data.initialValue = initialValue;
        delete data.banners;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Update_Settings_Call(action:any):any {
    try{
        let msg = (yield call(API_SERVICE.Gallery_Update_Settings, action.payload)).data.message;
        action.resolve(msg);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}







function* EGallery_Content_Get(action:any): any{
    try{
        const data = (yield call(API_SERVICE.Gallery_Content_Get, action.payload)).data.result;
        const _data =Functions.ObjectLowerCase(data);
        yield put(AGallery_Content_Add_Get(_data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Content_Update(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Gallery_Content_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Content_Create(action:any): any{
    try{
        const result = (yield call(API_SERVICE.Gallery_Content_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Content_Delete(action:any): any{
    const { ids, idForm } = action.payload;
    const filter = { "filter": [{ "key": "idGallery", "value": `${idForm}` }] }
    try{
        const message = (yield call(API_SERVICE.Gallery_Content_Delete, {idGallery: idForm, ids})).data.message;
        const list = (yield call(API_SERVICE.Gallery_Content_List, filter)).data.result;
        if(list.items) list.items = Functions.ListUpperLowerCase(list.items);
        yield put(AGallery_Content_Add_List(list));
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Content_Metadat_List(action:any): any{
    try{ 
    const data = (yield call(API_SERVICE.Gallery_Content_List, action.payload)).data.result;
    const metadata = (yield call(API_SERVICE.Gallery_Content_MetadataList)).data.result;
    //reNameContent(data.items);
    yield put(AGallery_Content_Add_List(data));
    yield put(AGallery_Content_Add_MetadaList(metadata));
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EGallery_Content_Call_Type():any{
    try{
        const data = (yield call(API_SERVICE.Gallery_List_Content_Type)).data.result;
        yield put(AGallery_Content_Add_Type(data));
    }
    catch(error){
        
    }
}

function* EGallery_Content_Export_Excel(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Gallery_Content_Export_Excel, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGallery_Export_Excel(action:any):any{
    try{
        const data = (yield call(API_SERVICE.Gallery_Export_Excel, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WGallery_Category_Update():any { return yield takeLatest(ActionType.GALLERY_CATEGORY_CALL_UPDATE, EGallery_Category_Update); }
export function* WGallery_Category_Create():any { return yield takeLatest(ActionType.GALLERY_CATEGORY_CALL_CREATE, EGallery_Category_Create); }
export function* WGallery_Category_Delete():any { return yield takeLatest(ActionType.GALLERY_CATEGORY_CALL_DELETE, EGallery_Category_Delete); }
export function* WGallery_Category_List():any { return yield takeLatest(ActionType.GALLERY_CATEGORY_CALL_LIST, EGallery_Category_List); }

export function* WGallery_Categories():any { return yield takeLatest(ActionType.GALLERY_CATEGORIES_CALL, EGallery_Categories_Call) }
export function* WGallery_Create_Init():any { return yield takeLatest(ActionType.GALLERY_CALL_CREATE_INIT, EGallery_Create_Init) }
export function* WGallery_Get():any { return yield takeLatest(ActionType.GALLERY_CALL_GET, EGallery_Get); }
export function* WGallery_Update():any { return yield takeLatest(ActionType.GALLERY_CALL_UPDATE, EGallery_Update); }
export function* WGallery_Create():any { return yield takeLatest(ActionType.GALLERY_CALL_CREATE, EGallery_Create); }
export function* WGallery_Delete():any { return yield takeLatest(ActionType.GALLERY_CALL_DELETE, EGallery_Delete); }
export function* WGallery_Metadata_List():any { return yield takeLatest(ActionType.GALLERY_CALL_METADATA_LIST, EGallery_Metadata_List); }
export function* WGallery_Content_Get():any { return yield takeLatest(ActionType.GALLERY_CONTENT_CALL_GET, EGallery_Content_Get); }
export function* WGallery_Content_Update():any { return yield takeLatest(ActionType.GALLERY_CONTENT_CALL_UPDATE, EGallery_Content_Update); }
export function* WGallery_Content_Delete():any { return yield takeLatest(ActionType.GALLERY_CONTENT_CALL_DELETE, EGallery_Content_Delete); }
export function* WGallery_Content_Create():any { return yield takeLatest(ActionType.GALLERY_CONTENT_CALL_CREATE, EGallery_Content_Create); }
export function* WGallery_Content_Metadata_List():any { return yield takeLatest(ActionType.GALLERY_CONTENT_CALL_METADATA_LIST, EGallery_Content_Metadat_List); }
export function* WGallery_Content_Call_Type():any { return yield takeLatest(ActionType.GALLERY_CONTENT_CALL_TYPE, EGallery_Content_Call_Type); }
export function* WGallery_Content_Export_Excel():any { return yield takeLatest(ActionType.GALLERY_CONTENT_EXPORT_EXCEL, EGallery_Content_Export_Excel); }
export function* WGallery_Export_Excel():any { return yield takeLatest(ActionType.GALLERY_EXPORT_EXCEL, EGallery_Export_Excel); }

export function* WGallery_Settings_Call():any { return yield takeLatest(ActionType.GALLERY_SETTINGS_CALL, EGallery_Settings_Call); }
export function* WGallery_Update_Settings_Call():any { return yield takeLatest(ActionType.GALLERY_UPDATE_SETTINGS_CALL, EGallery_Update_Settings_Call); }