import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as Actions from '../actions';
import Functions from '../../functions';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EReports_Access_Init(action:any):any {
    try{
        let temp:any = {};
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.Reports_Access_MetadataList)).data.result || [];
            temp.metadata = metadata;
        }

        const resultList = (yield call(API_SERVICE.Reports_Access_Lists, action.payload)).data.result;
        temp.list = Functions.ListUpperLowerCase(resultList.items) || [];
        temp.total = resultList.total || resultList?.items.length || 0;
        yield put(Actions.AREPORTS_ACCESS_LISTS_INIT_ADD(temp));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EReports_Access_Exports(action:any):any {
    try{
        let data:any;
        if(action.payload.type) {
            //type 1 excel
            data = (yield call(API_SERVICE.Reports_Access_Export_EXCEL, action.payload.data)).data.result;
        } else {
            //type 2 pdf
            data = (yield call(API_SERVICE.Reports_Access_Export_PDF, action.payload)).data.result;
        }
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EReports_Audit_Init(action:any):any {
    try{
        let temp:any = {};
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.Reports_Audit_MetadataList)).data.result || [];
            const modules = (yield call(API_SERVICE.Reports_Modules)).data.result || [];
            /*metadata.forEach((element: any) => {
                if(element.link) {
                  //element.render = (text: any, record: any) => <a onClick={() => action.getGroup(record.Id)}>{text}</a>;
                }
            });*/
            
            temp.modules = modules;
            temp.metadata = metadata;
        }

        const resultList = (yield call(API_SERVICE.Reports_Audit_Lists, action.payload)).data.result;
        temp.list = Functions.ListUpperLowerCase(resultList.items) || [];
        temp.total = resultList.total || resultList?.items.length || 0;
        yield put(Actions.AREPORTS_AUDIT_LISTS_INIT_ADD(temp));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EReports_Audit_Exports(action:any):any {
    try{
        let data:any;
        if(action.payload.type) {
            //type 1 excel
            data = (yield call(API_SERVICE.Reports_Audit_Export_EXCEL, action.payload.data)).data.result;
        } else {
            //type 2 pdf
            data = (yield call(API_SERVICE.Reports_Audit_Export_PDF, action.payload)).data.result;
        }
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WReports_Access_Init():any { return yield takeLatest( ActionType.REPORTS_ACCESS_LISTS_INIT_CALL, EReports_Access_Init); }
export function* WReports_Access_Export():any { return yield takeLatest(ActionType.REPORTS_ACCESS_EXPORTS, EReports_Access_Exports); }

export function* WReports_Audit_Init():any { return yield takeLatest( ActionType.REPORTS_AUDIT_LISTS_INIT_CALL, EReports_Audit_Init); }
export function* WReports_Audit_Export():any { return yield takeLatest(ActionType.REPORTS_AUDIT_EXPORTS, EReports_Audit_Exports); }