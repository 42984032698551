import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as Actions from '../actions';
import Functions from '../../functions';
import { APP_SETTINGS } from '../../constants/app_settings';

function* ERol_MetadataList(action:any):any {
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.Rol_Metadata)).data.result || [];
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <span className='id_links' onClick={() => action.getGroup(record.Id)}>{text}</span>;
                }
            });

            yield put(Actions.ROL_METADATA_ADD(metadata));
        }
        const data = (yield call(API_SERVICE.Rol_List, action.payload)).data.result;
        yield put(Actions.ROL_LIST_ADD(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERol_Create(action:any):any {
    const searchList = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.Rol_Create, action.payload)).data;
       const data = (yield call(API_SERVICE.Rol_List, searchList)).data.result;
       yield put(Actions.ROL_LIST_ADD(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERol_Update(action:any):any {
    const searchList = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page};
    try{
        const result = (yield call(API_SERVICE.Rol_Update, action.payload)).data;
        const data = (yield call(API_SERVICE.Rol_List, searchList)).data.result;
        yield put(Actions.ROL_LIST_ADD(data));

        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERol_Create_Init(action:any):any {
    try{
        let data = (yield call(API_SERVICE.Rol_Create_Init, action.payload)).data.result;
        if(data) data = Functions.ObjectLowerCase(data);
        yield put(Actions.ROL_GET_ADD(data));
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERol_Get(action:any):any {
    try{
        let data = (yield call(API_SERVICE.Rol_Get, action.payload)).data.result;
        if(data) data = Functions.ObjectLowerCase(data);
        yield put(Actions.ROL_GET_ADD(data));
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERol_Delete(action:any):any {
    const searchList = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.Rol_Delete, action.payload)).data.message;
        const data = (yield call(API_SERVICE.Rol_List, searchList)).data.result;
        yield put(Actions.ROL_LIST_ADD(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ERol_Export_Excel(action:any):any {
    try {
        const data = (yield call(API_SERVICE.Rol_Export, action.payload)).data.result;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WRol_MetadataList():any { return yield takeLatest( ActionType.ROL_METADATA_CALL, ERol_MetadataList); }
export function* WRol_Create():any { return yield takeLatest(ActionType.ROL_CREATE_CALL, ERol_Create); }
export function* WRol_Update():any { return yield takeLatest(ActionType.ROL_UPDATE_CALL, ERol_Update); }
export function* WRol_Get():any { return yield takeLatest(ActionType.ROL_GET_CALL, ERol_Get); }
export function* WRol_Create_Init():any { return yield takeLatest(ActionType.ROL_CREATE_INIT, ERol_Create_Init); }
export function* WRol_Delete():any { return yield takeLatest(ActionType.ROL_BATCH_DELETE_CALL, ERol_Delete); }
export function* WRol_Export_Excel():any { return yield takeLatest(ActionType.ROL_EXPORT_EXCEL_CALL, ERol_Export_Excel); }