import React from 'react'; 
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from './../../constants';
import API_SERVICE from './../../../API/api';
import * as Actions from './../../actions';
import { Link } from 'react-router-dom';
import Functions from './../../../functions';
import { APP_SETTINGS } from '../../../constants/app_settings';

function* ECanvas_Course_MetadataList(action:any):any {
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.ELEARNING_CANVAS_COURSE_METADATA)).data.result || [];
            metadata?.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={ "/e-learning/courses-canvas/edit/" + record.Id }>{text}</Link>;
                }
            });

            yield put(Actions.AELEARNING_CANVAS_COURSE_ADD_METADATA(metadata));
        }

        const data = (yield call(API_SERVICE.ELEARNING_CANVAS_COURSE_LIST, action.payload)).data.result;
        Functions.ListUpperLowerCase(data.items);
        yield put(Actions.AELEARNING_CANVAS_COURSE_ADD_LIST(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECanvas_Course_Get(action:any):any {
    try{
        let readonlyFields:any = {};
        const data = (yield call(API_SERVICE.ELEARNING_CANVAS_COURSE_GET, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        if(data.readonlyFields) 
            data.readonlyFields.forEach((item:string) => {
                let newItem:string = item.toLowerCase();
                readonlyFields[newItem] = newItem;
            })

        data.readonlyFields = readonlyFields;
        yield put(Actions.AELEARNING_CANVAS_COURSE_ADD_GET(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECanvas_Course_Update(action:any):any {
    try{
        const message = (yield call(API_SERVICE.ELEARNING_CANVAS_COURSE_UPDATE, action.payload)).data.message;
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECanvas_Course_Synchronize(action:any):any {
    try{
        const data = (yield call(API_SERVICE.ELEARNING_CANVAS_COURSE_SYNCHRONIZE)).data;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WCanvas_Course_MetadataList():any { return yield takeLatest( ActionType.ELEARNING_CANVAS_COURSE_CALL_METADATA, ECanvas_Course_MetadataList); }
export function* WCanvas_Course_Get():any { return yield takeLatest( ActionType.ELEARNING_CANVAS_COURSE_CALL_GET, ECanvas_Course_Get); }
export function* WCanvas_Course_Update():any { return yield takeLatest( ActionType.ELEARNING_CANVAS_COURSE_CALL_UPDATE, ECanvas_Course_Update); }
export function* WCanvas_Course_Synchronize():any { return yield takeLatest( ActionType.ELEARNING_CANVAS_COURSE_SYNCHRONIZE, ECanvas_Course_Synchronize); }