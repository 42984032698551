import ActionType from "../constants";

const initialState = {
    list: [],
    total: 0,
    metadata: [],
    onlyOne: {},
    category: []
};

const Rnews = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.NEWS_ADD_METADATA_LIST :
            return {
                ...state,
                metadata: action.payload
            };
        case ActionType.NEWS_ADD_LIST :
            return {
                ...state,
                list: action.payload.items,
                total: action.payload.total
            };
        case ActionType.NEWS_ADD_GET :
            return {
                ...state,
                onlyOne: action.payload
            };
        case ActionType.NEWS_ADD_CATEGORY:
            return {
                ...state,
                category: action.payload
            };
        case ActionType.NEWS_EXPORT_EXCEL_ADD:
            return {
                ...state,
                excel: action.payload
            };
        default:
        return state;
    }
}

export default Rnews;