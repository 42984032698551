import ActionType from "./../../constants";

const initialState = {
    dataType: [],
    controlType: [],
    behaviorList: [],
    listRegistry: {
        metadataList: [],
        items: [],
        total: 0
    },
    countRegistry: 0
}

const FormTypes = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.FORM_ADD_DATA_TYPE:
            return {
                ...state,
                dataType: action.payload
            }
        case ActionType.FORM_ADD_CONTROL_TYPE:
            return {
                ...state,
                controlType: action.payload
            }
        case ActionType.FORM_ADD_LIST_BEHAVIOR:
            return {
                ...state,
                behaviorList: action.payload
            }
        case ActionType.FORM_ADD_LIST_REGISTRY:
            return {
                ...state,
                listRegistry: action.payload
            }
        default: 
            return state
    }
}

export default FormTypes;