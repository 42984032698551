interface IActionType {
    USER_CALL_UPDATE_PASSWORD: string,

    INTERNAL_PAGE_CALL_METADATA_LIST: string,
    INTERNAL_PAGE_ADD_METADATA_LIST: string,
    INTERNAL_PAGE_ADD_LIST: string,
    INTERNAL_PAGE_CALL_CREATE: string,
    INTERNAL_PAGE_CALL_GET: string,
    INTERNAL_PAGE_GET: string,
    INTERNAL_PAGE_CALL_CREATE_INIT: string,
    INTERNAL_PAGE_CALL_UPDATE: string,
    INTERNAL_PAGE_CALL_HIERARCHICALLIST: string,
    INTERNAL_PAGE_ADD_HIERARCHICALLIST: string,
    INTERNAL_PAGE_CALL_DELETE: string,
    INTERNAL_PAGE_CALL_TYPE: string,
    INTERNAL_PAGE_ADD_TYPE: string,
    INTERNAL_PAGE_EXPORT_EXCEL: string,
    /**
    * 
    */

    PAGE_ZONE_CALL_UPDATE: string,
    PAGE_ZONE_CALL_DELETE: string
    PAGE_ZONE_CALL_CREATE: string,
    PAGE_ZONE_CALL_CREATE_INIT: string,
    PAGE_ZONE_CALL_GET: string,
    PAGE_ZONE_ADD_GET: string,
    PAGE_ZONE_ADD_METADATALIST: string,
    PAGE_ZONE_CALL_METADATALIST: string,
    PAGE_ZONE_ADD_LIST: string,
    PAGE_ZONE_EXPORT_EXCEL_CALL: string,

    PAGE_ZONE_GROUP_CALL_CREATE_INIT: string,
    PAGE_ZONE_GROUP_CALL_CREATE: string,
    PAGE_ZONE_GROUP_ADD_CREATE: string,
    PAGE_ZONE_GROUP_CALL_UPDATE: string,
    PAGE_ZONE_GROUP_CALL_GET: string,
    PAGE_ZONE_GROUP_CALL_METADATA_LIST: string,
    PAGE_ZONE_GROUP_ADD_LIST: string,
    PAGE_ZONE_GROUP_ADD_METADATA_LIST: string,
    PAGE_ZONE_GROUP_GET: string,
    PAGE_ZONE_GROUP_CALL_DELETE: string,
    PAGE_ZONE_GROUP_EXPORT_EXCEL_CALL: string,

    /**
    * 
    */
    BANNER_CALL_CREATE: string,
    BANNER_CALL_CREATE_INIT: string,
    BANNER_CALL_UPDATE: string,
    BANNER_CALL_GET: string,
    BANNER_ADD_GET: string,
    BANNER_CALL_DELETE: string,
    BANNER_CALL_LIST: string,
    BANNER_ADD_LIST: string,
    BANNER_CALL_METADATA_LIST: string,
    BANNER_ADD_METADATA_LIST: string,
    BANNER_EXPORT_EXCEL_CONTENT: string,



    BANNER_EXPORT_EXCEL: string,
    BANNER_GROUP_CALL_CREATE_INIT: string,
    BANNER_GROUP_CALL_CREATE: string,
    BANNER_GROUP_ADD_CREATE: string,
    BANNER_GROUP_GET: string,
    BANNER_GROUP_CALL_GET: string,
    BANNER_GROUP_CALL_UPDATE: string,
    BANNER_GROUP_CALL_METADATA_LIST: string,
    BANNER_GROUP_ADD_METADATA_LIST: string,
    BANNER_GROUP_ADD_LIST: string,
    BANNER_GROUP_CALL_DELETE: string,
    /**
    * 
    */
    NEWS_CALL_DUPLICATE: string,
    NEWS_CALL_CREATE: string,
    NEWS_CALL_UPDATE: string,
    NEWS_CALL_GET: string,
    NEWS_CALL_CREATE_INIT: string,
    NEWS_ADD_GET: string,
    NEWS_CALL_METADATA_LIST: string,
    NEWS_ADD_METADATA_LIST: string,
    NEWS_ADD_LIST: string,
    NEWS_CALL_DELETE: string,
    NEWS_CALL_CATEGORY: string,
    NEWS_ADD_CATEGORY: string,
    NEWS_EXPORT_EXCEL_CALL: string,
    NEWS_EXPORT_EXCEL_ADD: string,
    NEWS_UPDATE_SETTINGS_CALL: string,
    NEWS_SETTINGS_CALL: string,
    /**
    * 
    */
    GALLERY_CATEGORY_CALL_LIST: string,
    GALLERY_CATEGORY_ADD_LIST: string,
    GALLERY_CATEGORY_ADD_METADATALIST: string,
    GALLERY_CATEGORY_CALL_UPDATE: string,
    GALLERY_CATEGORY_CALL_CREATE: string,
    GALLERY_CATEGORY_CALL_DELETE: string,

    GALLERY_CATEGORIES_ADD: string,
    GALLERY_CATEGORIES_CALL: string,
    GALLERY_CALL_GET: string,
    GALLERY_ADD_GET: string,
    GALLERY_CALL_CREATE: string,
    GALLERY_ADD_CREATE: string,
    GALLERY_CALL_CREATE_INIT: string,
    GALLERY_ADD_CREATE_INIT: string,
    GALLERY_CALL_UPDATE: string,
    GALLERY_CALL_DELETE: string,
    GALLERY_ADD_LIST: string,
    GALLERY_CALL_METADATA_LIST: string,
    GALLERY_ADD_METADATA_LIST: string,
    GALLERY_CONTENT_CALL_GET: string,
    GALLERY_CONTENT_ADD_GET: string,
    GALLERY_CONTENT_CALL_CREATE: string,
    GALLERY_CONTENT_CALL_UPDATE: string
    GALLERY_CONTENT_CALL_DELETE: string,
    GALLERY_CONTENT_ADD_LIST: string,
    GALLERY_CONTENT_CALL_METADATA_LIST: string,
    GALLERY_CONTENT_ADD_METADATA_LIST: string,
    GALLERY_CONTENT_CALL_TYPE: string,
    GALLERY_CONTENT_ADD_TYPE: string,
    GALLERY_CONTENT_EXPORT_EXCEL: string,
    GALLERY_EXPORT_EXCEL: string,
    GALLERY_UPDATE_SETTINGS_CALL: string,
    GALLERY_SETTINGS_CALL: string,
    /**
    *  
    **/
    EVENT_CALL_CREATE_INIT: string,
    EVENT_ADD_CREATE_INIT: string,
    EVENT_CALL_GET: string,
    EVENT_ADD_GET: string,
    EVENT_CALL_CREATE: string,
    EVENT_CALL_UPDATE: string,
    EVENT_CALL_DELETE: string,
    EVENT_ADD_LIST: string,
    EVENT_CALL_METADATA_LIST: string,
    EVENT_ADD_METADATA_LIST: string,
    EVENT_CALL_CATEGORY_LIST: string,
    EVENT_ADD_CATEGORY_LIST: string,
    EVENT_EXPORT_EXCEL_CALL: string,
    EVENT_UPDATE_SETTINGS_CALL: string,
    EVENT_SETTINGS_CALL: string,
    /**
     *  
     **/
    HTMLTEMPLATE_CALL_CREATE_INIT: string,
    HTMLTEMPLATE_CALL_GET: string,
    HTMLTEMPLATE_ADD_GET: string,
    HTMLTEMPLATE_CALL_CREATE: string,
    HTMLTEMPLATE_CALL_UPDATE: string,
    HTMLTEMPLATE_CALL_DELETE: string,
    HTMLTEMPLATE_ADD_LIST: string,
    HTMLTEMPLATE_CALL_METADATA_LIST: string,
    HTMLTEMPLATE_ADD_METADATA_LIST: string,
    HTMLTEMPLATE_CALL_LIST_MODULES: string,
    HTMLTEMPLATE_ADD_LIST_MODULES: string,
    HTMLTEMPLATE_CALL_LIST_BY_MODULE: string,
    HTMLTEMPLATE_CALL_EXPORT_EXCEL: string,
    /**
    *  
    **/
    EMAILTEMPLATE_CALL_CREATE_INIT: string,
    EMAILTEMPLATE_CALL_GET: string,
    EMAILTEMPLATE_ADD_GET: string,
    EMAILTEMPLATE_CALL_CREATE: string,
    EMAILTEMPLATE_CALL_UPDATE: string,
    EMAILTEMPLATE_CALL_DELETE: string,
    EMAILTEMPLATE_ADD_LIST: string,
    EMAILTEMPLATE_CALL_METADATA_LIST: string,
    EMAILTEMPLATE_ADD_METADATA_LIST: string,
    EMAILTEMPLATE_CALL_EXPORT_EXCEL: string,
    /**
    *   
    **/
    REPORTS_ACCESS_LISTS_INIT_CALL: string,
    REPORTS_ACCESS_LISTS_INIT_ADD: string,
    REPORTS_ACCESS_EXPORTS: string,

    REPORTS_AUDIT_LISTS_INIT_CALL: string,
    REPORTS_AUDIT_LISTS_INIT_ADD: string,
    REPORTS_AUDIT_EXPORTS: string,
    /**
    *   
    **/
    FORM_CALL_GET_REGISTRY_FILE: string,
    FORM_CALL_GET: string,
    FORM_ADD_GET: string,
    FORM_CALL_CREATE: string,
    FORM_CALL_CREATE_INIT: string,
    FORM_ADD_CREATE_INIT: string,
    FORM_CALL_UPDATE: string,
    FORM_CALL_DELETE: string,
    FORM_ADD_LIST: string,
    FORM_CALL_METADATA_LIST: string,
    FORM_ADD_METADATA_LIST: string,

    FORM_LISTS_CALL_LIST: string,
    FORM_LISTS_ADD_LIST: string,
    FORM_LISTS_CALL_GET: string,
    FORM_LISTS_ADD_GET: string,
    FORM_LISTS_CALL_CREATE: string,
    FORM_LISTS_CALL_UPDATE: string,
    FORM_LISTS_CALL_DELETE: string,
    FORM_LISTS_CALL_EXPORT: string,

    FORM_CALL_CONTROL_TYPE: string,
    FORM_ADD_LIST_BEHAVIOR: string,
    FORM_ADD_CONTROL_TYPE: string,
    FORM_ADD_DATA_TYPE: string,
    FORM_ADD_LIST_REGISTRY: string,
    FORM_CALL_LIST_REGISTRY: string,

    FORM_CALL_GET_PREVIEW: string,
    FORM_ADD_GET_PREVIEW: string,
    FORM_CALL_GENERATE_FORM: string,
    FORM_ADD_GENERATE_FORM: string,

    FORM_CALL_REGISTRY_EXPORT_EXCEL: string,
    FORM_CALL_EXPORT_EXCEL: string,

    /**
    *   
    **/
    ADMIN_ASSIGN_ROLES: string,
    ADMIN_LIST_ADD: string,
    ADMIN_METADATA_CALL: string,
    ADMIN_METADATA_ADD: string,

    ADMIN_REGISTER_CALL: string,
    ADMIN_UPDATE_CALL: string,
    ADMIN_GET_CALL: string,
    ADMIN_GET_ADD: string,
    ADMIN_DELETE_CALL: string,
    ADMIN_BATCH_DELETE_CALL: string,
    ADMIN_BATCH_PRE_ACTIVE: string,
    ADMIN_ACTIVE_ACCOUNT: string,
    /**
    *   
    **/
    ROL_LIST_ADD: string,
    ROL_METADATA_CALL: string,
    ROL_METADATA_ADD: string,

    ROL_CREATE_INIT: string,
    ROL_CREATE_CALL: string,
    ROL_UPDATE_CALL: string,
    ROL_GET_CALL: string,
    ROL_GET_ADD: string,
    ROL_BATCH_DELETE_CALL: string,
    ROL_EXPORT_EXCEL_CALL: string,
    /**
    *   
    **/
    ELEARNING_COURSES_CALL_GET: string,
    ELEARNING_COURSES_ADD_GET: string,
    ELEARNING_COURSES_CALL_UPDATE: string,
    ELEARNING_COURSES_ADD_LIST: string,
    ELEARNING_COURSES_CALL_METADATA: string,
    ELEARNING_COURSES_ADD_METADATA: string,
    ELEARNING_COURSES_EXPORT_EXCEL: string,
    ELEARNING_COURSES_SYNCHRONIZE: string,
    ELEARNING_COURSES_ASSIGN: string,
    /**
    *   
    **/
    ELEARNING_CANVAS_COURSE_CALL_GET: string,
    ELEARNING_CANVAS_COURSE_ADD_GET: string,
    ELEARNING_CANVAS_COURSE_CALL_UPDATE: string,
    ELEARNING_CANVAS_COURSE_ADD_LIST: string,
    ELEARNING_CANVAS_COURSE_CALL_METADATA: string,
    ELEARNING_CANVAS_COURSE_ADD_METADATA: string,
    ELEARNING_CANVAS_COURSE_SYNCHRONIZE: string,
    /**
    *   
    **/
    ELEARNING_GROUP_CALL_CREATE_INIT: string,
    ELEARNING_GROUP_CALL_GET: string,
    ELEARNING_GROUP_ADD_GET: string,
    ELEARNING_GROUP_CALL_CREATE: string,
    ELEARNING_GROUP_CALL_UPDATE: string,
    ELEARNING_GROUP_CALL_DELETE: string,
    ELEARNING_GROUP_ADD_LIST: string,
    ELEARNING_GROUP_CALL_METADATA: string,
    ELEARNING_GROUP_ADD_METADATA: string,
    ELEARNING_GROUP_EXPORT_EXCEL: string,
    ELEARNING_GROUP_CALL_ASSIGN: string,
    /**
    *   
    **/ 
    MASTER_PAGE_CALL_METADATA: string,
    MASTER_PAGE_ADD_METADATA: string,
    MASTER_PAGE_ADD_LIST: string,
    MASTER_PAGE_CALL_GET: string,
    MASTER_PAGE_ADD_GET: string,
    MASTER_PAGE_CALL_UPDATE: string,
    MASTER_PAGE_ASSIGNED_ELEMENT: string,
    MASTER_PAGE_CALL_EXPORT_EXCEL: string,
    /**
    *   
    **/ 
    RECYCLEBIN_ADD_LIST: string,
    RECYCLEBIN_CALL_BATCHRETRIEVE: string,
    RECYCLEBIN_CALL_RETRIEVEITEM: string,
    RECYCLEBIN_CALL_METADATALIST: string,
    RECYCLEBIN_ADD_METADATALIST: string,
    RECYCLEBIN_EXPORT_EXCEL: string,
    RECYCLEBIN_ADD_MODULES: string,
    /**
    *   
    **/
    SETTINGS_LIST_RRSS: string,
    SETTINGS_LIST_RRSS_ADD: string,
    SETTINGS_UPDATE: string,
    SETTINGS_EXPORTA_EXCEL_RRSS: string,
    /**
    *   
    **/
    UPLOAD_CALL: string,
    /**
    *   
    **/
    ACHANGE_VALUE_BUTTONS_GENERAL_CALL: string,
    ACHANGE_VALUE_BUTTONS_GENERAL_ADD: string,
    CONFIRM_CHANGE_MODULE_CALL: string,
    CONFIRM_CHANGE_MODULE_ADD: string,
    IS_SUPER_MODULE_CALL: string,
    IS_SUPER_MODULE_ADD: string,
    /**
    *   
    **/
    COMMON_CANCEL_CREATION: string
}

const ActionType: IActionType = {} as IActionType;

/**
*  @keyword USER
* 
**/

ActionType.USER_CALL_UPDATE_PASSWORD = 'USER_CALL_UPDATE_PASSWORD';

/**
*  @keyword INTERNAL_PAGE
* 
**/

ActionType.INTERNAL_PAGE_CALL_METADATA_LIST = 'INTERNAL_PAGE_CALL_METADATA_LIST';
ActionType.INTERNAL_PAGE_ADD_METADATA_LIST = 'INTERNAL_PAGE_ADD_METADATA_LIST';
ActionType.INTERNAL_PAGE_ADD_LIST = 'INTERNAL_PAGE_ADD_LIST';
ActionType.INTERNAL_PAGE_CALL_CREATE = 'INTERNAL_PAGE_CALL_CREATE';
ActionType.INTERNAL_PAGE_CALL_GET = 'INTERNAL_PAGE_CALL_GET';
ActionType.INTERNAL_PAGE_GET = 'INTERNAL_PAGE_GET';
ActionType.INTERNAL_PAGE_CALL_CREATE_INIT = 'INTERNAL_PAGE_CALL_CREATE_INIT';
ActionType.INTERNAL_PAGE_CALL_UPDATE = 'INTERNAL_PAGE_CALL_UPDATE';
ActionType.INTERNAL_PAGE_CALL_HIERARCHICALLIST = 'INTERNAL_PAGE_CALL_HIERARCHICALLIST';
ActionType.INTERNAL_PAGE_ADD_HIERARCHICALLIST = 'INTERNAL_PAGE_ADD_HIERARCHICALLIST';
ActionType.INTERNAL_PAGE_CALL_DELETE = 'INTERNAL_PAGE_CALL_DELETE';
ActionType.INTERNAL_PAGE_CALL_TYPE = 'INTERNAL_PAGE_CALL_TYPE';
ActionType.INTERNAL_PAGE_ADD_TYPE = 'INTERNAL_PAGE_ADD_TYPE';
ActionType.INTERNAL_PAGE_EXPORT_EXCEL = 'INTERNAL_PAGE_EXPORT_EXCEL';

/**
*  @keyword PAGEZONE
* 
**/

ActionType.PAGE_ZONE_CALL_CREATE = 'PAGE_ZONE_CALL_CREATE';
ActionType.PAGE_ZONE_CALL_CREATE_INIT = 'PAGE_ZONE_CALL_CREATE_INIT';
ActionType.PAGE_ZONE_ADD_LIST = 'PAGE_ZONE_ADD_LIST';
ActionType.PAGE_ZONE_CALL_GET = 'PAGE_ZONE_CALL_GET';
ActionType.PAGE_ZONE_ADD_GET = 'PAGE_ZONE_ADD_GET';
ActionType.PAGE_ZONE_CALL_UPDATE = 'PAGE_ZONE_CALL_UPDATE';
ActionType.PAGE_ZONE_CALL_DELETE = 'PAGE_ZONE_CALL_DELETE';
ActionType.PAGE_ZONE_ADD_METADATALIST = 'PAGE_ZONE_ADD_METADATALIST';
ActionType.PAGE_ZONE_CALL_METADATALIST = 'PAGE_ZONE_CALL_METADATALIST';
ActionType.PAGE_ZONE_EXPORT_EXCEL_CALL = 'PAGE_ZONE_EXPORT_EXCEL_CALL';

ActionType.PAGE_ZONE_GROUP_CALL_CREATE_INIT = 'PAGE_ZONE_GROUP_CALL_CREATE_INIT';
ActionType.PAGE_ZONE_GROUP_CALL_CREATE = 'PAGE_ZONE_GROUP_CALL_CREATE';
ActionType.PAGE_ZONE_GROUP_ADD_CREATE = 'PAGE_ZONE_GROUP_ADD_CREATE';
ActionType.PAGE_ZONE_GROUP_CALL_UPDATE = 'PAGE_ZONE_GROUP_CALL_UPDATE';
ActionType.PAGE_ZONE_GROUP_CALL_GET = 'PAGE_ZONE_GROUP_CALL_GET';
ActionType.PAGE_ZONE_GROUP_GET = 'PAGE_ZONE_GROUP_GET';
ActionType.PAGE_ZONE_GROUP_CALL_METADATA_LIST = 'PAGE_ZONE_GROUP_CALL_METADATA_LIST';
ActionType.PAGE_ZONE_GROUP_ADD_METADATA_LIST = 'PAGE_ZONE_GROUP_ADD_METADATA_LIST';
ActionType.PAGE_ZONE_GROUP_ADD_LIST = 'PAGE_ZONE_GROUP_ADD_LIST';
ActionType.PAGE_ZONE_GROUP_CALL_DELETE = 'PAGE_ZONE_GROUP_CALL_DELETE';
ActionType.PAGE_ZONE_GROUP_EXPORT_EXCEL_CALL = "PAGE_ZONE_GROUP_EXPORT_EXCEL_CALL";

/**
*  @keyword BANNER
*    
**/

ActionType.BANNER_CALL_CREATE_INIT = 'BANNER_CALL_CREATE_INIT';
ActionType.BANNER_CALL_CREATE = 'BANNER_CALL_CREATE';
ActionType.BANNER_CALL_UPDATE = 'BANNER_CALL_UPDATE';
ActionType.BANNER_CALL_GET = 'BANNER_CALL_GET';
ActionType.BANNER_ADD_GET = 'BANNER_ADD_GET';
ActionType.BANNER_CALL_DELETE = 'BANNER_CALL_DELETE';
ActionType.BANNER_CALL_LIST = 'BANNER_CALL_LIST';
ActionType.BANNER_ADD_LIST = 'BANNER_ADD_LIST';
ActionType.BANNER_CALL_METADATA_LIST = 'BANNER_CALL_METADATA_LIST';
ActionType.BANNER_ADD_METADATA_LIST = 'BANNER_ADD_METADATA_LIST';
ActionType.BANNER_EXPORT_EXCEL_CONTENT = 'BANNER_EXPORT_EXCEL_CONTENT';


ActionType.BANNER_EXPORT_EXCEL = 'BANNER_EXPORT_EXCEL';
ActionType.BANNER_GROUP_CALL_CREATE_INIT = 'BANNER_GROUP_CALL_CREATE_INIT';
ActionType.BANNER_GROUP_ADD_CREATE = 'BANNER_GROUP_ADD_CREATE';
ActionType.BANNER_GROUP_CALL_CREATE = 'BANNER_GROUP_CALL_CREATE';
ActionType.BANNER_GROUP_CALL_GET = 'BANNER_GROUP_CALL_GET';
ActionType.BANNER_GROUP_GET = 'BANNER_GROUP_GET';
ActionType.BANNER_GROUP_CALL_UPDATE = 'BANNER_GROUP_CALL_UPDATE';
ActionType.BANNER_GROUP_CALL_METADATA_LIST = 'BANNER_GROUP_CALL_METADATA_LIST';
ActionType.BANNER_GROUP_ADD_METADATA_LIST = 'BANNER_GROUP_ADD_METADATA_LIST';
ActionType.BANNER_GROUP_ADD_LIST = 'BANNER_GROUP_ADD_LIST';
ActionType.BANNER_GROUP_CALL_DELETE = 'BANNER_GROUP_CALL_DELETE';
/**
*  @keyword NEWS
* 
**/
ActionType.NEWS_CALL_DUPLICATE = 'NEWS_CALL_DUPLICATE';
ActionType.NEWS_CALL_METADATA_LIST = 'NEWS_CALL_METADATA_LIST';
ActionType.NEWS_ADD_METADATA_LIST = 'NEWS_ADD_METADATA_LIST';
ActionType.NEWS_ADD_LIST = 'NEWS_ADD_LIST';
ActionType.NEWS_CALL_CREATE = 'NEWS_CALL_CREATE';
ActionType.NEWS_CALL_UPDATE = 'NEWS_CALL_UPDATE';
ActionType.NEWS_CALL_GET = 'NEWS_CALL_GET';
ActionType.NEWS_CALL_CREATE_INIT = 'NEWS_CALL_CREATE_INIT';
ActionType.NEWS_ADD_GET = 'NEWS_ADD_GET';
ActionType.NEWS_CALL_DELETE = 'NEWS_CALL_DELETE';
ActionType.NEWS_CALL_CATEGORY = 'NEWS_CALL_CATEGORY';
ActionType.NEWS_ADD_CATEGORY = 'NEWS_ADD_CATEGORY';
ActionType.NEWS_EXPORT_EXCEL_CALL = "NEWS_EXPORT_EXCEL_CALL";
ActionType.NEWS_EXPORT_EXCEL_ADD = "NEWS_EXPORT_EXCEL_ADD";
ActionType.NEWS_UPDATE_SETTINGS_CALL = "NEWS_UPDATE_SETTINGS_CALL";
ActionType.NEWS_SETTINGS_CALL = "NEWS_SETTINGS_CALL";
/**
*  @keyword GALLERY
* 
**/
ActionType.GALLERY_CATEGORY_CALL_LIST = 'GALLERY_CATEGORY_CALL_LIST';
ActionType.GALLERY_CATEGORY_ADD_LIST = 'GALLERY_CATEGORY_ADD_LIST';
ActionType.GALLERY_CATEGORY_ADD_METADATALIST = 'GALLERY_CATEGORY_ADD_METADATALIST';
ActionType.GALLERY_CATEGORY_CALL_UPDATE = 'GALLERY_CATEGORY_CALL_UPDATE';
ActionType.GALLERY_CATEGORY_CALL_CREATE = 'GALLERY_CATEGORY_CALL_CREATE';
ActionType.GALLERY_CATEGORY_CALL_DELETE = 'GALLERY_CATEGORY_CALL_DELETE';

ActionType.GALLERY_CATEGORIES_ADD = "GALLERY_CATEGORIES_ADD";
ActionType.GALLERY_CATEGORIES_CALL = "GALLERY_CATEGORIES_CALL";
ActionType.GALLERY_CALL_GET = 'GALLERY_CALL_GET';
ActionType.GALLERY_ADD_GET = 'GALLERY_ADD_GET';
ActionType.GALLERY_CALL_CREATE_INIT = 'GALLERY_CALL_CREATE_INIT';
ActionType.GALLERY_ADD_CREATE_INIT = 'GALLERY_ADD_CREATE_INIT';
ActionType.GALLERY_CALL_CREATE = 'GALLERY_CALL_CREATE';
ActionType.GALLERY_ADD_CREATE = 'GALLERY_ADD_CREATE';
ActionType.GALLERY_CALL_UPDATE = 'GALLERY_CALL_UPDATE';
ActionType.GALLERY_CALL_DELETE = 'GALLERY_CALL_DELETE';
ActionType.GALLERY_ADD_LIST = 'GALLERY_ADD_LIST';
ActionType.GALLERY_CALL_METADATA_LIST = 'GALLERY_CALL_METADATA_LIST';
ActionType.GALLERY_ADD_METADATA_LIST = 'GALLERY_ADD_METADATA_LIST';

ActionType.GALLERY_CONTENT_CALL_GET = 'GALLERY_CONTENT_CALL_GET';
ActionType.GALLERY_CONTENT_ADD_GET = 'GALLERY_CONTENT_ADD_GET';
ActionType.GALLERY_CONTENT_CALL_CREATE = 'GALLERY_CONTENT_CALL_CREATE';
ActionType.GALLERY_CONTENT_CALL_UPDATE = 'GALLERY_CONTENT_CALL_UPDATE';
ActionType.GALLERY_CONTENT_CALL_DELETE = 'GALLERY_CONTENT_CALL_DELETE';
ActionType.GALLERY_CONTENT_ADD_LIST = 'GALLERY_CONTENT_ADD_LIST';
ActionType.GALLERY_CONTENT_CALL_METADATA_LIST = 'GALLERY_CONTENT_CALL_METADATA_LIST';
ActionType.GALLERY_CONTENT_ADD_METADATA_LIST = 'GALLERY_CONTENT_ADD_METADATA_LIST';
ActionType.GALLERY_CONTENT_CALL_TYPE = 'GALLERY_CONTENT_CALL_TYPE';
ActionType.GALLERY_CONTENT_ADD_TYPE = 'GALLERY_CONTENT_ADD_TYPE';
ActionType.GALLERY_CONTENT_EXPORT_EXCEL = 'GALLERY_CONTENT_EXPORT_EXCEL';
ActionType.GALLERY_EXPORT_EXCEL = 'GALLERY_EXPORT_EXCEL';

ActionType.GALLERY_UPDATE_SETTINGS_CALL = "GALLERY_UPDATE_SETTINGS_CALL";
ActionType.GALLERY_SETTINGS_CALL = "GALLERY_SETTINGS_CALL";


ActionType.EVENT_CALL_CREATE_INIT = "EVENT_CALL_CREATE_INIT";
ActionType.EVENT_ADD_CREATE_INIT = "EVENT_ADD_CREATE_INIT";
ActionType.EVENT_CALL_GET = 'EVENT_CALL_GET';
ActionType.EVENT_ADD_GET = 'EVENT_ADD_GET';
ActionType.EVENT_CALL_CREATE = 'EVENT_CALL_CREATE';
ActionType.EVENT_CALL_UPDATE = 'EVENT_CALL_UPDATE';
ActionType.EVENT_CALL_DELETE = 'EVENT_CALL_DELETE';
ActionType.EVENT_ADD_LIST = 'EVENT_ADD_LIST';
ActionType.EVENT_CALL_METADATA_LIST = 'EVENT_CALL_METADATA_LIST';
ActionType.EVENT_ADD_METADATA_LIST = 'EVENT_ADD_METADATA_LIST';
ActionType.EVENT_CALL_CATEGORY_LIST = 'EVENT_CALL_CATEGORY_LIST';
ActionType.EVENT_ADD_CATEGORY_LIST = 'EVENT_ADD_CATEGORY_LIST';
ActionType.EVENT_EXPORT_EXCEL_CALL = "EVENT_EXPORT_EXCEL_CALL";
ActionType.EVENT_UPDATE_SETTINGS_CALL = "EVENT_UPDATE_SETTINGS_CALL";
ActionType.EVENT_SETTINGS_CALL = "EVENT_SETTINGS_CALL";

/**
*  @keyword HTMLTEMPLATE
* 
**/
ActionType.HTMLTEMPLATE_CALL_CREATE_INIT = 'HTMLTEMPLATE_CALL_CREATE_INIT';
ActionType.HTMLTEMPLATE_CALL_GET = 'HTMLTEMPLATE_CALL_GET';
ActionType.HTMLTEMPLATE_ADD_GET = 'HTMLTEMPLATE_ADD_GET';
ActionType.HTMLTEMPLATE_CALL_CREATE = 'HTMLTEMPLATE_CALL_CREATE';
ActionType.HTMLTEMPLATE_CALL_UPDATE = 'HTMLTEMPLATE_CALL_UPDATE';
ActionType.HTMLTEMPLATE_CALL_DELETE = 'HTMLTEMPLATE_CALL_DELETE';
ActionType.HTMLTEMPLATE_ADD_LIST = 'HTMLTEMPLATE_ADD_LIST';
ActionType.HTMLTEMPLATE_CALL_METADATA_LIST = 'HTMLTEMPLATE_CALL_METADATA_LIST';
ActionType.HTMLTEMPLATE_ADD_METADATA_LIST = 'HTMLTEMPLATE_ADD_METADATA_LIST';
ActionType.HTMLTEMPLATE_CALL_LIST_MODULES = 'HTMLTEMPLATE_CALL_LIST_MODULES';
ActionType.HTMLTEMPLATE_ADD_LIST_MODULES = 'HTMLTEMPLATE_ADD_LIST_MODULES';
ActionType.HTMLTEMPLATE_CALL_LIST_BY_MODULE = 'HTMLTEMPLATE_CALL_LIST_BY_MODULE';
ActionType.HTMLTEMPLATE_CALL_EXPORT_EXCEL = 'HTMLTEMPLATE_CALL_EXPORT_EXCEL';

/**
*  @keyword EMAIL TEMPLATE
* 
**/

ActionType.EMAILTEMPLATE_CALL_CREATE_INIT = 'EMAILTEMPLATE_CALL_CREATE_INIT';
ActionType.EMAILTEMPLATE_CALL_GET = 'EMAILTEMPLATE_CALL_GET';
ActionType.EMAILTEMPLATE_ADD_GET = 'EMAILTEMPLATE_ADD_GET';
ActionType.EMAILTEMPLATE_CALL_CREATE = 'EMAILTEMPLATE_CALL_CREATE';
ActionType.EMAILTEMPLATE_CALL_UPDATE = 'EMAILTEMPLATE_CALL_UPDATE';
ActionType.EMAILTEMPLATE_CALL_DELETE = 'EMAILTEMPLATE_CALL_DELETE';
ActionType.EMAILTEMPLATE_ADD_LIST = 'EMAILTEMPLATE_ADD_LIST';
ActionType.EMAILTEMPLATE_CALL_METADATA_LIST = 'EMAILTEMPLATE_CALL_METADATA_LIST';
ActionType.EMAILTEMPLATE_ADD_METADATA_LIST = 'EMAILTEMPLATE_ADD_METADATA_LIST';
ActionType.EMAILTEMPLATE_CALL_EXPORT_EXCEL = 'EMAILTEMPLATE_CALL_EXPORT_EXCEL';

/**
*  @keyword FORM
* 
**/

ActionType.REPORTS_ACCESS_LISTS_INIT_CALL = "REPORTS_ACCESS_LISTS_INIT_CALL";
ActionType.REPORTS_ACCESS_LISTS_INIT_ADD = "REPORTS_ACCESS_LISTS_INIT_ADD";
ActionType.REPORTS_ACCESS_EXPORTS = "REPORTS_ACCESS_EXPORTS";

ActionType.REPORTS_AUDIT_LISTS_INIT_CALL = "REPORTS_AUDIT_LISTS_INIT_CALL";
ActionType.REPORTS_AUDIT_LISTS_INIT_ADD = "REPORTS_AUDIT_LISTS_INIT_ADD";
ActionType.REPORTS_AUDIT_EXPORTS = "REPORTS_AUDIT_EXPORTS";
/**
*  @keyword FORM
* 
**/
ActionType.FORM_CALL_GET_REGISTRY_FILE = 'FORM_CALL_GET_REGISTRY_FILE';
ActionType.FORM_CALL_GET = 'FORM_CALL_GET';
ActionType.FORM_ADD_GET = 'FORM_ADD_GET';
ActionType.FORM_CALL_CREATE_INIT = "FORM_CALL_CREATE_INIT";
ActionType.FORM_ADD_CREATE_INIT = "FORM_ADD_CREATE_INIT";
ActionType.FORM_CALL_CREATE = 'FORM_CALL_CREATE';
ActionType.FORM_CALL_UPDATE = 'FORM_CALL_UPDATE';
ActionType.FORM_CALL_DELETE = 'FORM_CALL_DELETE';
ActionType.FORM_ADD_LIST = 'FORM_ADD_LIST';
ActionType.FORM_CALL_METADATA_LIST = 'FORM_CALL_METADATA_LIST';
ActionType.FORM_ADD_METADATA_LIST = 'FORM_ADD_METADATA_LIST';
/***/
ActionType.FORM_LISTS_CALL_LIST = 'FORM_LISTS_CALL_LIST';
ActionType.FORM_LISTS_ADD_LIST = 'FORM_LISTS_ADD_LIST';
ActionType.FORM_LISTS_CALL_GET = 'FORM_LISTS_CALL_GET';
ActionType.FORM_LISTS_ADD_GET = 'FORM_LISTS_ADD_GET';
ActionType.FORM_LISTS_CALL_CREATE = 'FORM_LISTS_CALL_CREATE';
ActionType.FORM_LISTS_CALL_UPDATE = 'FORM_LISTS_CALL_UPDATE';
ActionType.FORM_LISTS_CALL_DELETE = 'FORM_LISTS_CALL_DELETE';
ActionType.FORM_LISTS_CALL_EXPORT = 'FORM_LISTS_CALL_EXPORT';
/***/
ActionType.FORM_CALL_CONTROL_TYPE = 'FORM_CALL_CONTROL_TYPE';
ActionType.FORM_ADD_LIST_BEHAVIOR = 'FORM_ADD_LIST_BEHAVIOR';
ActionType.FORM_ADD_CONTROL_TYPE = 'FORM_ADD_CONTROL_TYPE';
ActionType.FORM_ADD_DATA_TYPE = 'FORM_ADD_DATA_TYPE';
ActionType.FORM_ADD_LIST_REGISTRY = 'FORM_ADD_LIST_REGISTRY';
ActionType.FORM_CALL_LIST_REGISTRY = 'FORM_CALL_LIST_REGISTRY';

ActionType.FORM_CALL_GET_PREVIEW = 'FORM_CALL_GET_PREVIEW';
ActionType.FORM_ADD_GET_PREVIEW = 'FORM_ADD_GET_PREVIEW';
ActionType.FORM_CALL_GENERATE_FORM = 'FORM_CALL_GENERATE_FORM';
ActionType.FORM_ADD_GENERATE_FORM = 'FORM_ADD_GENERATE_FORM';

ActionType.FORM_CALL_REGISTRY_EXPORT_EXCEL = 'FORM_CALL_REGISTRY_EXPORT_EXCEL';
ActionType.FORM_CALL_EXPORT_EXCEL = 'FORM_CALL_EXPORT_EXCEL';

/**
*  @keyword ADMIN
**/

ActionType.ADMIN_ASSIGN_ROLES = "ADMIN_ASSIGN_ROLES";
ActionType.ADMIN_LIST_ADD = "ADMIN_LIST_ADD";
ActionType.ADMIN_METADATA_CALL = "ADMIN_METADATA_CALL";
ActionType.ADMIN_METADATA_ADD = "ADMIN_METADATA_ADD";

ActionType.ADMIN_REGISTER_CALL = "ADMIN_REGISTER_CALL";
ActionType.ADMIN_UPDATE_CALL = "ADMIN_UPDATE_CALL";
ActionType.ADMIN_GET_CALL = "ADMIN_GET_CALL";
ActionType.ADMIN_GET_ADD = "ADMIN_GET_ADD";
ActionType.ADMIN_DELETE_CALL = "ADMIN_DELETE_CALL";
ActionType.ADMIN_BATCH_DELETE_CALL = "ADMIN_BATCH_DELETE_CALL";
ActionType.ADMIN_BATCH_PRE_ACTIVE = "ADMIN_BATCH_PRE_ACTIVE";
ActionType.ADMIN_ACTIVE_ACCOUNT = "ADMIN_ACTIVE_ACCOUNT";

/**
*  @keyword Roles
* 
**/

ActionType.ROL_LIST_ADD = "ROL_LIST_ADD";
ActionType.ROL_METADATA_CALL = "ROL_METADATA_CALL";
ActionType.ROL_METADATA_ADD = "ROL_METADATA_ADD";

ActionType.ROL_CREATE_INIT = "ROL_CREATE_INIT";
ActionType.ROL_CREATE_CALL = "ROL_CREATE_CALL";
ActionType.ROL_UPDATE_CALL = "ROL_UPDATE_CALL";
ActionType.ROL_GET_CALL = "ROL_GET_CALL";
ActionType.ROL_GET_ADD = "ROL_GET_ADD";
ActionType.ROL_BATCH_DELETE_CALL = "ROL_BATCH_DELETE_CALL";
ActionType.ROL_EXPORT_EXCEL_CALL = "ROL_EXPORT_EXCEL_CALL";

/**
*  @keyword Courses
* 
**/

ActionType.ELEARNING_COURSES_CALL_GET = 'ELEARNING_COURSES_CALL_GET';
ActionType.ELEARNING_COURSES_ADD_GET = 'ELEARNING_COURSES_ADD_GET';
ActionType.ELEARNING_COURSES_CALL_UPDATE = 'ELEARNING_COURSES_CALL_UPDATE';
ActionType.ELEARNING_COURSES_ADD_LIST = 'ELEARNING_COURSES_ADD_LIST';
ActionType.ELEARNING_COURSES_CALL_METADATA = 'ELEARNING_COURSES_CALL_METADATA';
ActionType.ELEARNING_COURSES_ADD_METADATA = 'ELEARNING_COURSES_ADD_METADATA';
ActionType.ELEARNING_COURSES_EXPORT_EXCEL = "ELEARNING_COURSES_EXPORT_EXCEL";
ActionType.ELEARNING_COURSES_SYNCHRONIZE = "ELEARNING_COURSES_SYNCHRONIZE";
ActionType.ELEARNING_COURSES_ASSIGN = "ELEARNING_COURSES_ASSIGN";

/**
*  @keyword Canvas
* 
**/

ActionType.ELEARNING_CANVAS_COURSE_CALL_GET = "ELEARNING_CANVAS_COURSE_CALL_GET";
ActionType.ELEARNING_CANVAS_COURSE_ADD_GET = "ELEARNING_CANVAS_COURSE_ADD_GET";
ActionType.ELEARNING_CANVAS_COURSE_CALL_UPDATE = "ELEARNING_CANVAS_COURSE_CALL_UPDATE";
ActionType.ELEARNING_CANVAS_COURSE_ADD_LIST = "ELEARNING_CANVAS_COURSE_ADD_LIST";
ActionType.ELEARNING_CANVAS_COURSE_CALL_METADATA = "ELEARNING_CANVAS_COURSE_CALL_METADATA";
ActionType.ELEARNING_CANVAS_COURSE_ADD_METADATA = "ELEARNING_CANVAS_COURSE_ADD_METADATA";
ActionType.ELEARNING_CANVAS_COURSE_SYNCHRONIZE = "ELEARNING_CANVAS_COURSE_SYNCHRONIZE";

/**
*  @keyword Group
* 
**/

ActionType.ELEARNING_GROUP_CALL_CREATE_INIT = 'ELEARNING_GROUP_CALL_CREATE_INIT';
ActionType.ELEARNING_GROUP_CALL_GET = 'ELEARNING_GROUP_CALL_GET';
ActionType.ELEARNING_GROUP_ADD_GET = 'ELEARNING_GROUP_ADD_GET';
ActionType.ELEARNING_GROUP_CALL_CREATE = 'ELEARNING_GROUP_CALL_CREATE';
ActionType.ELEARNING_GROUP_CALL_UPDATE = 'ELEARNING_GROUP_CALL_UPDATE';
ActionType.ELEARNING_GROUP_CALL_DELETE = 'ELEARNING_GROUP_CALL_DELETE';
ActionType.ELEARNING_GROUP_ADD_LIST = 'ELEARNING_GROUP_ADD_LIST';
ActionType.ELEARNING_GROUP_CALL_METADATA = 'ELEARNING_GROUP_CALL_METADATA';
ActionType.ELEARNING_GROUP_ADD_METADATA = 'ELEARNING_GROUP_ADD_METADATA';
ActionType.ELEARNING_GROUP_EXPORT_EXCEL = 'ELEARNING_GROUP_EXPORT_EXCEL';
ActionType.ELEARNING_GROUP_CALL_ASSIGN = 'ELEARNING_GROUP_CALL_ASSIGN';

/**
*  @keyword MASTERPAGE
* 
**/

ActionType.MASTER_PAGE_CALL_METADATA = 'MASTER_PAGE_CALL_METADATA';
ActionType.MASTER_PAGE_ADD_METADATA = 'MASTER_PAGE_ADD_METADATA';
ActionType.MASTER_PAGE_ADD_LIST = 'MASTER_PAGE_ADD_LIST';
ActionType.MASTER_PAGE_CALL_GET = 'MASTER_PAGE_CALL_GET';
ActionType.MASTER_PAGE_ADD_GET = 'MASTER_PAGE_ADD_GET';
ActionType.MASTER_PAGE_CALL_UPDATE = 'MASTER_PAGE_CALL_UPDATE';
ActionType.MASTER_PAGE_ASSIGNED_ELEMENT = 'MASTER_PAGE_ASSIGNED_ELEMENT';
ActionType.MASTER_PAGE_CALL_EXPORT_EXCEL = 'MASTER_PAGE_CALL_EXPORT_EXCEL';

/**
*  @keyword RECYCLE
* 
**/

ActionType.RECYCLEBIN_ADD_LIST = 'RECYCLEBIN_ADD_LIST';
ActionType.RECYCLEBIN_CALL_METADATALIST = 'RECYCLEBIN_CALL_METADATALIST';
ActionType.RECYCLEBIN_ADD_METADATALIST = 'RECYCLEBIN_ADD_METADATALIST';
ActionType.RECYCLEBIN_CALL_BATCHRETRIEVE = 'RECYCLEBIN_CALL_BATCHRETRIEVE';
ActionType.RECYCLEBIN_CALL_RETRIEVEITEM = 'RECYCLEBIN_CALL_RETRIEVEITEM';
ActionType.RECYCLEBIN_EXPORT_EXCEL = 'RECYCLEBIN_EXPORT_EXCEL';
ActionType.RECYCLEBIN_ADD_MODULES = 'RECYCLEBIN_ADD_MODULES';

/**
*  @keyword settings rrss
* 
**/

ActionType.SETTINGS_LIST_RRSS = "SETTINGS_LIST_RRSS";
ActionType.SETTINGS_LIST_RRSS_ADD = "SETTINGS_LIST_RRSS_ADD";
ActionType.SETTINGS_UPDATE = "SETTINGS_UPDATE";
ActionType.SETTINGS_EXPORTA_EXCEL_RRSS = "SETTINGS_EXPORTA_EXCEL_RRSS";

/**
*  @keyword UPLOAD
* 
**/

ActionType.UPLOAD_CALL = 'UPLOAD_CALL';

/**
*  @keyword extra - change module
* 
**/

ActionType.CONFIRM_CHANGE_MODULE_ADD = 'CONFIRM_CHANGE_MODULE_ADD';
ActionType.CONFIRM_CHANGE_MODULE_CALL = 'CONFIRM_CHANGE_MODULE_CALL';
ActionType.IS_SUPER_MODULE_CALL = "IS_SUPER_MODULE_CALL";
ActionType.IS_SUPER_MODULE_ADD = "IS_SUPER_MODULE_ADD";
ActionType.ACHANGE_VALUE_BUTTONS_GENERAL_CALL = "ACHANGE_VALUE_BUTTONS_GENERAL_CALL";
ActionType.ACHANGE_VALUE_BUTTONS_GENERAL_ADD = "ACHANGE_VALUE_BUTTONS_GENERAL_ADD";

/**
*  @keyword Common module
* 
**/

ActionType.COMMON_CANCEL_CREATION = 'COMMON_CANCEL_CREATION';

export default ActionType;