import ActionType from "../constants";

const initialState = {
    categories: []
}

const RGallery = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.GALLERY_CATEGORY_ADD_LIST: return {...state, categoryList: action.payload }
        case ActionType.GALLERY_CATEGORY_ADD_METADATALIST: return {...state, categoryMetadata: action.payload }
        case ActionType.GALLERY_ADD_GET: return {...state, onlyOne: action.payload }
        case ActionType.GALLERY_ADD_LIST: return {...state, list: action.payload.items, total: action.payload.total }
        case ActionType.GALLERY_ADD_CREATE_INIT: return {...state, onlyOne: action.payload }
        case ActionType.GALLERY_ADD_METADATA_LIST: return {...state, metadata: action.payload, }
        case ActionType.GALLERY_ADD_CREATE: return { ...state, id: action.payload }
        case ActionType.GALLERY_CONTENT_ADD_TYPE: return { ...state, type: action.payload }
        case ActionType.GALLERY_CONTENT_ADD_METADATA_LIST: return { ...state, contentMetadata: action.payload }
        case ActionType.GALLERY_CONTENT_ADD_LIST: return { ...state, contentList: action.payload}
        case ActionType.GALLERY_CONTENT_ADD_GET: return { ...state, contentGet: action.payload }
        case ActionType.GALLERY_CATEGORIES_ADD: return { ...state, categories: action.payload }
        default : return state
    }
}

export default RGallery;