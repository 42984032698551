import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import Functions from '../../functions';
import * as Actions from '../actions';

function* ESettings_List_RRSS (action:any):any {
    try{
        const data = (yield call(API_SERVICE.Settings_List_RRSS)).data.result;
        
        yield put(Actions.ASettings_List_RRSS_ADD(data));
        action.resolve();
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ESettings_Export_Excel_RRSS(action:any):any {
    try{
        const excel = (yield call(API_SERVICE.Settings_Export_Excel_RRSS)).data.result;
        action.resolve(excel);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ESettings_Update_RRSS(action:any):any {
    try{
        const message = (yield call(API_SERVICE.Settings_Update, action.payload)).data.message;

        //const data = (yield call(API_SERVICE.Settings_List_RRSS)).data.result;
        //data?.map((item:any, index:any) => data[index].children = item.items || null);
        //yield put(Actions.ASettings_List_RRSS_ADD(data));
        
        action.resolve(message);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WSettings_List_RRSS():any { return yield takeLatest(ActionType.SETTINGS_LIST_RRSS, ESettings_List_RRSS) };
export function* WSettings_Export_Excel_RRSS():any { return yield takeLatest(ActionType.SETTINGS_EXPORTA_EXCEL_RRSS, ESettings_Export_Excel_RRSS) };
export function* WSettings_Update_RRSS():any { return yield takeLatest(ActionType.SETTINGS_UPDATE, ESettings_Update_RRSS) };
