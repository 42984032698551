import moment from 'moment';
import { message } from 'antd';

export const valueDate = (date: any) => {
    var d = moment(date);
    return date && d.isValid() ? d : null;
}

export const betweenDate = (value:any, search:any):Boolean => {
    let resultado:Boolean = false;
    if (value.dtp1 && value.dtp1 > search.dtp2 && search.dtp2) {
        message.error('La fecha debe ser menor a la fecha de fin de búsqueda');
        resultado = true;
    }
    if (value.dtp2 && value.dtp2 < search.dtp1) { 
        message.error('La fecha debe ser mayor a la fecha de inicio de búsqueda');
        resultado = true;
    }
    return resultado;
}

export const FilterTemplate = (filters:any = {}, metadata:Array<any> = [], sort:any = {}, preFilters:any = {}) => {
    const PrincipalFilters:Array<String> = metadata.filter(item => item.filterItem);
    let validationIsExists: Boolean = false;
    
    let AllFilters:Array<any> = Object.entries(filters).map(([key, value]:any) => {
        if(value !== null) validationIsExists = true;
        return {key, value}
    });
    
    let validation01 = sort && Object.keys(sort).length !== 0 && (!validationIsExists); 
    let validation02 = !sort && !validationIsExists;
    
    if(validation01 || validation02){
        AllFilters = Object.entries(preFilters).map(([key, value]:any) => ({key, value}))
    }
    
    //plantillas para retornar - FiltersWithValue
    const templateFiltersWithValue:Array<any> = [];
    const FiltersWithValue = AllFilters.filter(({key, value}) => value);
    FiltersWithValue.forEach((itemFilter) => {
            const PrincipalFilter:any = PrincipalFilters.find((itemPF:any) => itemPF.dataIndex === itemFilter.key) || {}; 
            //acá en la metadata verifica si existe originKey sino usará la de dataIndex.
            const keyFilter = PrincipalFilter?.originKey || itemFilter.key;
            itemFilter.value?.map((valueFilters:any) => templateFiltersWithValue.push({key: keyFilter, value: valueFilters}))
        }
    )

    //plantillas para retornar - AllFilters
    const templateAllFilters:any = {}
    AllFilters.map(({key, value}) => (templateAllFilters[key] = value));

    //retornará los filtros.
    return ({ AllFilters: templateAllFilters, FiltersWithValue: templateFiltersWithValue });
}

export const FilterExport = (filterValue:any = {}, filters:any = {}, metadata:Array<any>) => {
    const PrincipalFilters:Array<String> = metadata.filter(item => item.filterItem);
    let AllFilters:Array<any> = Object.entries(filters).map(([key, value]:any) => ({key, value}));

    //plantillas para retornar - FiltersWithValue
    const templateFiltersWithValue:Array<any> = [];
    const FiltersWithValue = AllFilters.filter(({key, value}) => value);
    FiltersWithValue.forEach((itemFilter) => {
            const PrincipalFilter:any = PrincipalFilters.find((itemPF:any) => itemPF.dataIndex === itemFilter.key) || {}; 
            //acá en la metadata verifica si existe originKey sino usará la de dataIndex.
            const keyFilter = PrincipalFilter?.originKey || itemFilter.key;
            itemFilter.value?.map((valueFilters:any) => templateFiltersWithValue.push({key: keyFilter, value: valueFilters}))
        }
    )
    if(filterValue.value && filterValue.value !== "")  templateFiltersWithValue.push({key: filterValue.key, value: filterValue.value});

    return templateFiltersWithValue;
}