import ActionType from "../constants";

const initialState = [{ }];

const RRecyclebin = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.RECYCLEBIN_ADD_METADATALIST :
            return {
                ...state,
                metadata: action.payload
            };
        case ActionType.RECYCLEBIN_ADD_LIST :
            return {
                ...state,
                list: action.payload.items || action.payload ,
                total: action.payload.total
            }
        case ActionType.RECYCLEBIN_ADD_MODULES :
            return {
                ...state,
                modules: action.payload
            };
        default:
        return state;
    }
}

export default RRecyclebin;  