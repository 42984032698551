import ActionType from "../constants";

const initialState = {
    list: {
        list: [],
        total: 0
    }
};

const RInternal_Page = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.INTERNAL_PAGE_ADD_METADATA_LIST :
            return {
                ...state,
                metadata: action.payload
            };
        case ActionType.INTERNAL_PAGE_ADD_LIST :
            return {
                ...state,
                list: action.payload.items,
                total: action.payload.total
            };
        case ActionType.INTERNAL_PAGE_GET :
            return {
                ...state,
                onlyOne: action.payload
            }
        case ActionType.INTERNAL_PAGE_ADD_HIERARCHICALLIST:
            return {
                ...state,
                list: action.payload
            }
        case ActionType.INTERNAL_PAGE_ADD_TYPE:
            return {
                ...state,
                type: action.payload
            }
        default:
        return state;
    }
}

// export default combineReducers({depositToken, deposits});
export default RInternal_Page;  