import React from 'react';
import './../assets/style/dashboard.scss';
import { Route, Switch } from 'react-router-dom';

import * as ELearning from '../modules/loadable';

class Admin extends React.Component<any, any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Switch>
                    {/*--------------------------------------------------------------- */}
                    <Route path='/e-learning/courses/edit/:id' component={ELearning.ELearningCourseForm}/>
                    <Route path='/e-learning/courses' component={ELearning.ELearningCourseList}/>
                    <Route path='/e-learning/courses-canvas/edit/:id' component={ELearning.ELearningCanvasCourseForm}/>
                    <Route path='/e-learning/courses-canvas' component={ELearning.ELearningCanvasCourseList}/>
                    <Route path='/e-learning/speciality/edit/:id' component={ELearning.ELearningGroupForm}/>
                    <Route path='/e-learning/speciality/create' component={ELearning.ELearningGroupForm}/>
                    <Route path='/e-learning/speciality' component={ELearning.ELearningGroupList}/>
                    <Route path='/e-learning' component={ELearning.ELearningDashboard}/>
                </Switch>
            </>
        );
    }
}

export default Admin;