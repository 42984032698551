import { takeLatest, call, put, select } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as Actions from '../actions';
import { APageZoneGroup_Add_MetadataList, APageZoneGroup_Add_List, APageZoneGroup_Get, APageZone_Add_List, APageZone_Add_MetadataList, APageZone_Add_Get } from '../actions';
import Functions from '../../functions';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EPageZone_Update(action:any):any {

  try{
    const result = (yield call(API_SERVICE.PageZone_Update, action.payload)).data;
    action.resolve(result)
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZone_Create(action:any):any {
  try{
    const result = (yield call(API_SERVICE.PageZone_Create, action.payload)).data;
    action.resolve(result)
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZone_Create_Init(action:any):any {
  try{
    const data = (yield call(API_SERVICE.PageZone_Create_Init, action.payload)).data.result;
    //if(data.data) data.data = Functions.ObjectLowerCase(data.data);
    action.resolve(data.data);
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZone_MetadataList(action:any):any {
  try{
    let data:any;
    if(action.payload.reload) {
      data = [];
    } else {
      data = (yield call(API_SERVICE.PageZone_List, action.payload)).data.result;
    }
    const metadata = (yield call(API_SERVICE.PageZone_MetadaList)).data.result;

    yield put(APageZone_Add_MetadataList(metadata));
    yield put(APageZone_Add_List(data))
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZone_Delete(action:any):any {
  try{
    const message = (yield call(API_SERVICE.PageZone_Delete, action.payload.ids)).data.message;
    const list = (yield call(API_SERVICE.PageZone_List, action.payload.idPageZone)).data.result;
    
    yield put(APageZone_Add_List(list))
    action.resolve(message);
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZone_Get(action:any):any {
  try{
      const data = (yield call(API_SERVICE.PageZone_Get, action.payload)).data.result;
      yield put(APageZone_Add_Get(data))
      action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZone_ExportExcel(action:any):any {
  try {

    const data = (yield call(API_SERVICE.PageZone_Export_Excel, action.payload)).data.result;
    action.resolve(data);

  } catch(error) {
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}



function* EPageZoneGroup_Create(action:any):any {
  try{
    const message = (yield call(API_SERVICE.PageZoneGroup_Create, action.payload)).data.message;
    //yield put(APageZoneGroup_Add_Create(id))
    action.resolve(message);
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZoneGroup_Update(action:any):any {
  let useMasterPage = action.payload?.useFormFromOtherModule; 
  try{
    delete action.payload?.useFormFromOtherModule;
    const response = (yield call(API_SERVICE.PageZoneGroup_Update, action.payload)).data;

    if(useMasterPage){
      const onlyOne = (yield select((state) => state.masterPage.onlyOne));
      yield put(Actions.AMASTERPAGE_CALL_GET(onlyOne.id, () => null, () => null));
    }

    action.resolve(response);
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZoneGroup_Get(action:any):any {
  try{
    const data = (yield call(API_SERVICE.PageZoneGroup_Get, action.payload))?.data.result;
    if(data.data) data.data = Functions.ObjectLowerCase(data.data);
    if(data.list.items) data.list.items = Functions.ListUpperLowerCase(data.list.items);
    yield put(APageZoneGroup_Get(data));
    action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZoneGroup_Create_Init(action:any):any {
  try{
    const data = (yield call(API_SERVICE.PageZoneGroup_Create_Init, action.payload))?.data.result;
    if(data.data) data.data = Functions.ObjectLowerCase(data.data);
    yield put(APageZoneGroup_Get(data));
    action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EPageZoneGroup_Delete(action:any):any {
  const searchList = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page};
  try{
    const message = (yield call(API_SERVICE.PageZoneGroup_Delete, action.payload)).data.message;
    const data = (yield call(API_SERVICE.PageZoneGroup_List, searchList)).data.result;

    yield put(APageZoneGroup_Add_List(data));
    action.resolve(message);

  } catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}
  
function* EPageZoneGroup_MetadataList(action:any):any {
  try {
    //METADATA
    if(action.payload === undefined) {
      //este caso es solo para que se liste por primera vez.
      action.payload = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
      const metadata = (yield call(API_SERVICE.PageZoneGroup_MetadataList)).data.result;
      action.renderMetadata(metadata);
      yield put(APageZoneGroup_Add_MetadataList(metadata));
    } 

    //LIST 
    const data = (yield call(API_SERVICE.PageZoneGroup_List, action.payload)).data.result;
    yield put(APageZoneGroup_Add_List(data));

    action.resolve();   

  } catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);

  }
}

function* EPageZoneGroup_ExportExcel(action:any):any {
  try {

    const data = (yield call(API_SERVICE.PageZoneGroup_Excel, action.payload)).data.result;
    action.resolve(data);

  } catch(error) {
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}




export function* WPageZoneGroup_Create():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_CALL_CREATE, EPageZoneGroup_Create); }
export function* WPageZoneGroup_Update():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_CALL_UPDATE, EPageZoneGroup_Update); }
export function* WPageZoneGroup_Get():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_CALL_GET, EPageZoneGroup_Get); }
export function* WPageZoneGroup_Create_Init():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_CALL_CREATE_INIT, EPageZoneGroup_Create_Init); }
export function* WPageZoneGroup_MetadataList():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_CALL_METADATA_LIST, EPageZoneGroup_MetadataList); }
export function* WPageZoneGroup_Delete():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_CALL_DELETE, EPageZoneGroup_Delete) }  
export function* WPageZoneGroup_Excel():any { return yield takeLatest(ActionType.PAGE_ZONE_GROUP_EXPORT_EXCEL_CALL, EPageZoneGroup_ExportExcel) }

export function* WPageZone_Create():any { return yield takeLatest(ActionType.PAGE_ZONE_CALL_CREATE, EPageZone_Create); }
export function* WPageZone_Create_Init():any { return yield takeLatest(ActionType.PAGE_ZONE_CALL_CREATE_INIT, EPageZone_Create_Init); }
export function* WPageZone_MetadataList():any { return yield takeLatest(ActionType.PAGE_ZONE_CALL_METADATALIST, EPageZone_MetadataList) } 
export function* WPageZone_Delete():any { return yield takeLatest(ActionType.PAGE_ZONE_CALL_DELETE, EPageZone_Delete) }
export function* WPageZone_Update():any { return yield takeLatest(ActionType.PAGE_ZONE_CALL_UPDATE, EPageZone_Update) }
export function* WPageZone_Get():any { return yield takeLatest(ActionType.PAGE_ZONE_CALL_GET, EPageZone_Get) }
export function* WPageZone_Excel():any { return yield takeLatest(ActionType.PAGE_ZONE_EXPORT_EXCEL_CALL, EPageZone_ExportExcel) }