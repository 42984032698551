import ActionType from "../constants";

const initialState = {
    list: [],
    total: 0,
    metadata: [],
    onlyOne: {},
};

const Rrole = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.ROL_METADATA_ADD :
            return {
                ...state,
                metadata: action.payload
            };
        case ActionType.ROL_LIST_ADD :
            return {
                ...state,
                list: action.payload.items,
                total: action.payload.total
            };
        case ActionType.ROL_GET_ADD :
            return {
                ...state,
                onlyOne: action.payload
            };
        default: return state;
    }
}

export default Rrole;  