import React from 'react'; 
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import { AEmailTemplate_Add_Get } from '../actions';
import * as Actions from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EEmailTemplate_Get(action:any): any{
    try{
        const data = (yield call(API_SERVICE.EmailTemplate_Get, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        yield put(AEmailTemplate_Add_Get(data));
        action.resolve();
    } catch(error) {
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEmailTemplate_Create_Init(action:any): any{
    try{
        const data = (yield call(API_SERVICE.EmailTemplate_Create_Init, action.payload)).data.result;
        const temp = Functions.ObjectLowerCase(data);
        yield put(AEmailTemplate_Add_Get(temp));
        action.resolve();
    } catch(error) {
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEmailTemplate_Update(action:any): any{
    try{
        const result = (yield call(API_SERVICE.EmailTemplate_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEmailTemplate_Create(action:any): any{
    try{
        const result = (yield call(API_SERVICE.EmailTemplate_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEmailTemplate_Delete(action:any): any{
    try{
        const message = (yield call(API_SERVICE.EmailTemplate_Delete, action.payload.ids)).data.message;

        const filter =  {perfil: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
        const data = (yield call(API_SERVICE.EmailTemplate_List, filter)).data.result;
        yield put(Actions.AEmailTemplate_Add_List(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEmailTemplate_MetadataList(action:any): any{
    try{

        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.EmailTemplate_MetadataList)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_EMAIL_TEMPLATE}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.id}` }>{text}</Link>;
                }
            });

            yield put(Actions.AEmailTemplate_Add_MetadataList(metadata));
        }

        const data = (yield call(API_SERVICE.EmailTemplate_List, action.payload)).data.result;
        yield put(Actions.AEmailTemplate_Add_List(data));
    
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EEmailTemplate_Export_Excel(action:any): any{
    try{
        const excel = (yield call(API_SERVICE.EmailTemplate_Export_Excel, action.payload)).data.result;
        action.resolve(excel);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WEmailTemplate_Create_Init(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_CREATE_INIT, EEmailTemplate_Create_Init); }
export function* WEmailTemplate_Get(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_GET, EEmailTemplate_Get); }
export function* WEmailTemplate_Update(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_UPDATE, EEmailTemplate_Update); }
export function* WEmailTemplate_Create(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_CREATE, EEmailTemplate_Create); }
export function* WEmailTemplate_Delete(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_DELETE, EEmailTemplate_Delete); }
export function* WEmailTemplate_MetadataList(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_METADATA_LIST, EEmailTemplate_MetadataList); }
export function* WEmailTemplate_Export_Excel(): any{ return yield takeLatest(ActionType.EMAILTEMPLATE_CALL_EXPORT_EXCEL, EEmailTemplate_Export_Excel); }