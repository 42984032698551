import * as Exception from './template/getException';
import * as LisTemplate from './template/ListUpperLowerCase';
import * as FuncLists from './template/lists';
import * as FuncForms from './template/form';

interface IRedirectAfterSubmitInFormModules {
    actionTypeNumber: number;
    response: {
        message: string;
        result: number | string;
    };
    urls:{
        URL_CREATE: string;
        URL_EDIT: string;
        URL_LIST: string;
    },
    history: any
}

interface IFunction {
    getExeceptions: (err:any) => any;
    showException: (err:any) => void;
    ListUpperLowerCase: (data:any, funCompoundKey?:Function) => Array<any>;
    ObjectLowerCase: (data:any) => any;
    ArrayLowerCase: (data: any[] | null | undefined) => any[];
    templateDate: (date:any) => any;
    validationBetweenDates: (value:any, search:any) => Boolean;
    replaceData: any;
    filterTemplate: any;
    filterExport: any;
    dateToDayWithoutFormat: any;
    convertTextTitleToSlug: (textTitle:string) => string;
    redirectAfterSubmitInFormModules: (props:IRedirectAfterSubmitInFormModules) => void;
    getBase64: (file: any) => Promise<any>;
}

const Functions:IFunction = {
    getExeceptions: Exception.getExeception,
    showException: Exception.showException,
    ListUpperLowerCase: LisTemplate.ListUpperLowerCase,
    ObjectLowerCase: LisTemplate.ObjectLowerCase,
    ArrayLowerCase: LisTemplate.ArrayLowerCase,
    templateDate: FuncLists.valueDate,
    validationBetweenDates: FuncLists.betweenDate,
    replaceData: FuncForms.replaceData,
    filterTemplate: FuncLists.FilterTemplate,
    filterExport: FuncLists.FilterExport,
    dateToDayWithoutFormat: FuncForms.dateToDayWithoutFormat,
    convertTextTitleToSlug: FuncForms.convertTextTitleToSlug,
    redirectAfterSubmitInFormModules: FuncForms.redirectAfterSubmitInFormModules,
    getBase64: FuncForms.getBase64
}

export default Functions;