import React from 'react';
import { Form, Input, Button, Spin } from 'antd';
import './../assets/style/login.scss';
// import './../style/login.scss';
// import { Switch, Route } from 'react-router'
import jwtDecode from 'jwt-decode';
import { Link } from 'react-router-dom';
import LoginWrapper from '../components/loginWrapper';
import { GoogleReCaptchaProvider, withGoogleReCaptcha, IWithGoogleReCaptchaProps } from 'react-google-recaptcha-v3';
import API_SERVICE from '../API/api';
import Functions from '../functions';

declare var window: any;
var RECAPTCHA_KEY = window.config.key_Recaptcha;

class Login extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            messageError: ''
        };
    }

    componentDidMount(){
        const token = localStorage.getItem('token');
        if (token) {
            const payload = JSON.stringify(jwtDecode(token));
            const exp = JSON.parse(payload).exp;
            if (exp < Date.now() / 1000) {
              localStorage.clear();
            }
            return window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN}`;
        }
    }

    enterLoading = () => {
        this.setState({ loading: true });
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const enter = (document.all) ? e.keyCode : e.which;
        if (enter !== 13) {
            this.props.form.validateFields(async (err: any, values: any) => {
                if (!err) {
                    /*
                    let recaptcha = "";
			        await this.props.googleReCaptchaProps.executeRecaptcha(Login).then((token:any) => {
                        recaptcha = token;
                    }).catch((err:any) => {
                        console.log(err);
                    });*/

                    const { executeRecaptcha } = (this.props as IWithGoogleReCaptchaProps).googleReCaptchaProps;
                    
                    if (!executeRecaptcha) {
                        console.log('Recaptcha has not been loaded');
                        return;
                    }
                
                    const token = await executeRecaptcha('login');

                    API_SERVICE.User_Login({
                        email: values.username,
                        password: values.password,
                        recaptcha: token
                    })
                        .then((res: any) => {
                            this.setState({
                                loading: false
                            });
                            const { status, data } = res;

                            if (status === 200) {
                                const { email, firstName, id, dashboard, dashboardSuper, isSuper, lastName, menu, menuSuper, userRol, settings, modules } = data.result;
                                const validateMenu = menuSuper ? menuSuper.menuLateral : [];
                                const user = {
                                    user_info: {
                                        id,
                                        email,
                                        firstName,
                                        lastName,
                                        isSuper,
                                        userRol
                                    },
                                    user_dashboard: {
                                        dashboard: {
                                            normal: dashboard.items,
                                            superA: dashboardSuper?.items || []
                                        },
                                        menu: {
                                            normal: menu.menuLateral,
                                            superA: validateMenu
                                        }
                                    },
                                    settings: {
                                        ...settings
                                    },
                                    modules
                                }

                                localStorage.setItem('token', data.result.token);
                                localStorage.setItem('user', JSON.stringify(user));
                                const params = new URLSearchParams(this.props.location.search);
                                const redirect = params.get('continue');
                                window.location.href = redirect ? decodeURIComponent(redirect) : `/${process.env.REACT_APP_ROUTE_PATH_MAIN}`
                                this.props.form.setFieldsValue({ password: null })
                            }
                        })
                        .catch((err: any) => {
                            const errMessage = err.response.data.responseException.exceptionMessage;
                            const newErr = Functions.getExeceptions(errMessage);
                            
                            this.setState({
                                loading: false,
                               messageError: Array.isArray(newErr) ? newErr.toString() : newErr
                            });
                        })
                }
                else {
                    this.setState({
                        loading: false
                    })
                }
            }
            );
        }
    }

    handleVerifyRecaptcha = async () => {
        const { executeRecaptcha } = (this.props as IWithGoogleReCaptchaProps)
          .googleReCaptchaProps;
    
        if (!executeRecaptcha) {
          console.log('Recaptcha has not been loaded');
    
          return;
        }
    
        await executeRecaptcha('homepage');
      };

    renderTitle = () => (
        <div>
            <h2>Bienvenid@ a Fénix CMS</h2>
            <p>Le damos un nuevo impulso a nuestro CMS para brindarte una experiencia renovada y 100% personalizable. ¡Bienvenid@!</p>
        </div>
    )

    render() {
        const { messageError } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <LoginWrapper Title={this.renderTitle}>
                <div className="message">
                    {messageError && <Spin spinning={this.state.loading}><div className="msgError"> <span>x</span><label dangerouslySetInnerHTML={{__html: messageError || ""}}></label> </div> </Spin>}
                </div>
                <div className="form">
                    <Form onSubmit={this.handleSubmit} className="login-form">
                        <Form.Item label="Correo Electrónico">
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: 'El correo electrónico no puede estar vacio' }],
                            })(
                                <Input disabled={this.state.loading} />
                            )}
                        </Form.Item>
                        <Form.Item label="Contraseña">
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: 'El password no puede estar vacio' }],
                            })(
                                <Input.Password disabled={this.state.loading}/>
                            )}
                        </Form.Item>
                        <Form.Item className="login__button">
                            <Button type="primary" htmlType="submit" className="login-form-button" loading={this.state.loading} onClick={this.enterLoading}>
                                INGRESAR
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
                <div className="footer">
                    <Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PERDIO_CUENTA}`} >¿Has olvidado tu contraseña?</Link>
                </div>
            </LoginWrapper>
        );
    }
}

const WithGoogleRecaptchaLogin = withGoogleReCaptcha(Login);

const LoginCaptcha = (props:any) => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={ RECAPTCHA_KEY }>
			<WithGoogleRecaptchaLogin {...props}/>
		</GoogleReCaptchaProvider>
	)
}

export default Form.create({ name: 'login' })(LoginCaptcha);