import ActionType from "./../../constants";

const initialState = {

};

const RCourse = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.ELEARNING_COURSES_ADD_GET:
            return {
                ...state,
                onlyOne: action.payload
            }
        case ActionType.ELEARNING_COURSES_ADD_LIST:
            return {
                ...state,
                list: action.payload
            }
        case ActionType.ELEARNING_COURSES_ADD_METADATA:
            return {
                ...state,
                metadata: action.payload
            }
        default: 
            return state
    }
}

export default RCourse;