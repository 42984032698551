import { takeLatest, call } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import Functions from '../../functions';

function* EUserUpdatePassword (action:any):any {
    try{
        const message = (yield call(API_SERVICE.User_Update_Password, action.payload)).data.message;
        action.resolve(message);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WUserUpdatePassword():any { return yield takeLatest(ActionType.USER_CALL_UPDATE_PASSWORD, EUserUpdatePassword) };