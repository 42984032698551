import axios from 'axios';
import * as constant from './url_apis';

export const Form_Get_Registry_File = (payload:any, header:any) => {
    return new Promise((resolve, reject) => 
        axios.post(`${constant.API_URL}${constant.FORM_GET_REGISTRY_FILE}`, payload, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    )
}

export const Form_Get = (Id:number,API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + constants.FORM_GET + Id, header)
        .then((res)=> {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_Export_Excel = (payload:any, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.FORM_EXPORT_EXCEL, payload, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Form_Registry_Export_Excel = (Id:number, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.FORM_REGISTRY_EXPORT_EXCEL + Id, {id:Id}, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Form_Create = (body:object, API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject)=>{
        axios.post(API_URL + constants.FORM_CREATE, body, header)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const Form_Create_Init = (Id:number, header:object) => {
    const params = Id ? `/${Id}` : '';
    return new Promise((resolve, reject)=>{
        axios.get(constant.API_URL + constant.FORM_CREATE_INIT + params, header)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const Form_Update = (body:object, API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject)=>{
        axios.post(API_URL + constants.FORM_UPDATE, body, header)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const Form_Delete = (Ids:number[],API_URL:string, constants:any, header:any) => {
    header.data = Ids;
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + constants.FORM_DELETE, header)
        .then((res)=> {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_List = (body:object, API_URL:string, constants:any, header:object) => { 
    return new Promise((resolve, reject)=>{
       axios.post(API_URL + constants.FORM_LIST, body, header)
        .then((res)=>{
            resolve(res)
        })
        .catch((err)=>{
            reject(err);
        })
    })
}

export const Form_MetadataList = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + constants.FORM_METADATA_LIST, header)
        .then((res)=> {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

/**------------------------------------------LISTS-------------------------------------------------*/

export const Form_Lists_Metadata = (header:object) => {
    return new Promise((resolve:any,reject:any) => {
        axios.get(constant.API_URL + constant.FORM_LISTS_METADATA, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_Lists_List = (payload:object, API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any,reject:any) => {
        axios.post(API_URL + constants.FORM_LISTS_LIST, payload, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_Lists_Get = (Id:number, API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any,reject:any) => {
        axios.get(API_URL + constants.FORM_LISTS_GET + Id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_Lists_Create = (payload:object, API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any,reject:any) => {
        axios.post(API_URL + constants.FORM_LISTS_CREATE, payload, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_Lists_Update = (payload:object, API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any,reject:any) => {
        axios.post(API_URL + constants.FORM_LISTS_UPDATE, payload, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Form_Lists_Delete = (Ids:Array<number>, API_URL:string, constants:any, header:any) => {
    header.data = Ids;
    return new Promise((resolve:any,reject:any) => {
        axios.delete(API_URL + constants.FORM_LISTS_DELETE, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}


/*------------------------------------------------Type-------------------------------------------------*/

export const Form_Control_Type = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(API_URL + constants.FORM_CONTROL_TYPE, header)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Form_Data_Type = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(API_URL + constants.FORM_DATA_TYPE, header)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Form_List_Behavior = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(API_URL + constants.FORM_LIST_BEHAVIOR , header)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Form_List_Registry = (API_URL:any, constants:any, Id:number, header:object) => { 
    return new Promise((resolve:any, reject:any) => {
        axios.get(API_URL + constants.FORM_LIST_REGISTRY + Id, header)
        .then((res) => {
            resolve(res)
        })
        .catch((err) => {
            reject(err)
        })
    })
}

export const Form_Get_Preview = (API_URL:string, constants:any, id:any, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post( API_URL + constants.FORM_GET_PREVIEW + id, {}, header )
        .then((res:any) => {
            resolve(res);
        })
        .catch((err:any) => {
            reject(err);
        })
    })
}

export const Form_Generate_Form = (API_URL:string, constants:any, payload:any, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.post( API_URL + constants.FORM_GENERATE_FORM, payload, header )
        .then((res:any) => {
            resolve(res);
        })
        .catch((err:any) => {
            reject(err);
        })
    })
}
