import ActionType from "../constants";

const initialState = {
    list_: []
}

const rBanner = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.BANNER_GROUP_ADD_METADATA_LIST:
            return {...state, 
            metadata: action.payload,
            }
        case ActionType.BANNER_GROUP_ADD_LIST:
            return {...state, 
            list: action.payload.items,
            total: action.payload.total
            }
        case ActionType.BANNER_GROUP_GET:
            return {...state,
            data: action.payload
            }
        case ActionType.BANNER_ADD_METADATA_LIST:
            return {...state,
            metadata_: action.payload,
            }
        case ActionType.BANNER_GROUP_ADD_CREATE:
            return {...state,
            id: action.payload,
            }
        case ActionType.BANNER_ADD_LIST:
            return {...state,
            list_: action.payload.items,
            total_: action.payload.total
            }
        case ActionType.BANNER_ADD_GET:
            return {...state,
                data_:action.payload,
            }
        default:
        return state;
    }
}

export default rBanner;

