import ActionType from '../constants';
const initialState = {
    onlyOne: {},
    list: [],
    total: 0,
    metadata: [],
    categoryList: []
}

const REvent = (state = initialState, action:any) => {
    switch(action.type){
        case ActionType.EVENT_ADD_CREATE_INIT: 
            return {...state, 
                onlyOne: action.payload}
        case ActionType.EVENT_ADD_GET: 
            return {...state, 
                onlyOne: action.payload}
        case ActionType.EVENT_ADD_LIST:
            return {...state,
                list: action.payload.items,
                total: action.payload.total}
        case ActionType.EVENT_ADD_METADATA_LIST:
            return {...state,
                metadata: action.payload}
        case ActionType.EVENT_ADD_CATEGORY_LIST:
            return {...state,
                categoryList: action.payload}
        default :
            return state;
    }
}

export default REvent;