import { combineReducers } from 'redux';
import internalPage from './internalPage';
import zonePage from './zonepage';
import banner from './banner';
import news from './news';
import gallery from './gallery';
import event from './event';
import htmlTemplate from './htmlTemplate';
import emailTemplate from './emailTemplate';
import form from './form/index';
import recyclebin from './recyclebin';
import admin from './admin';
import statusModule from './statusModule';
import settingsRRSS from './settings-rrss';
import eLearning from './e-learning';
import masterPage from './masterPage';
import roles from './roles';
import reports from './reports';

const appReducer = combineReducers({
    internalPage,
    zonePage,
    banner,
    news,
    gallery,
    event,
    htmlTemplate,
    emailTemplate,
    form,
    recyclebin,
    admin,
    statusModule,
    settingsRRSS,
    eLearning,
    masterPage,
    roles,
    reports
});

export default appReducer;