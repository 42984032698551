import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import * as Actions from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EAdmin_MetadataList(action:any):any {
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.Admin_Metadata)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN_USERS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.Id}`}>{text}</Link>;
                }
            });

            yield put(Actions.ADMIN_METADATA_ADD(metadata));
        }

        const data = (yield call(API_SERVICE.Admin_List, action.payload)).data.result;
        yield put(Actions.ADMIN_LIST_ADD(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EAdmin_Assign_Roles(action:any):any {
    try{
        const data = (yield call(API_SERVICE.Admin_Assign_Roles, action.payload)).data.message;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EAdmin_Create(action:any):any {
    try{
        const result = (yield call(API_SERVICE.Admin_Register, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EAdmin_Update(action:any):any {
    try{
        const result = (yield call(API_SERVICE.Admin_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EAdmin_Get(action:any):any {
    try{
        const data = (yield call(API_SERVICE.Admin_Get, action.payload)).data.result;
        if(data.user) data.user = Functions.ObjectLowerCase(data.user);
        yield put(Actions.ADMIN_GET_ADD(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EAdmin_Delete(action:any):any {
    const searchList = {profile: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.Admin_Batch_Delete, action.payload.ids)).data.message;
        const data = (yield call(API_SERVICE.Admin_List, searchList)).data.result;
        yield put(Actions.ADMIN_LIST_ADD(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EAdmin_Batch_Pre_Active(action:any):any {
    const searchList = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.Admin_Batch_Pre_Active, action.payload)).data.message;
        const data = (yield call(API_SERVICE.Admin_List, searchList)).data.result;
        yield put(Actions.ADMIN_LIST_ADD(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr); 
    }
}


export function* WAdmin_Assign_Roles():any { return yield takeLatest( ActionType.ADMIN_ASSIGN_ROLES, EAdmin_Assign_Roles); }
export function* WAdmin_MetadataList():any { return yield takeLatest( ActionType.ADMIN_METADATA_CALL, EAdmin_MetadataList); }
export function* WAdmin_Create():any { return yield takeLatest(ActionType.ADMIN_REGISTER_CALL, EAdmin_Create); }
export function* WAdmin_Update():any { return yield takeLatest(ActionType.ADMIN_UPDATE_CALL, EAdmin_Update); }
export function* WAdmin_Get():any { return yield takeLatest(ActionType.ADMIN_GET_CALL, EAdmin_Get); }
export function* WAdmin_Delete():any { return yield takeLatest(ActionType.ADMIN_DELETE_CALL, EAdmin_Delete); }
export function* WAdmin_Batch_Pre_Active():any { return yield takeLatest(ActionType.ADMIN_BATCH_PRE_ACTIVE, EAdmin_Batch_Pre_Active); }