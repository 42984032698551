import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import { AInternalPage_Add_MetadataList, AInternalPage_Get, AInternalPage_Add_HierarchicalList, AInternalPage_Add_Type } from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { EyeOutlined } from '@ant-design/icons';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EInternalPage_MetadataList(action:any):any  {
  try {
    const metadata = (yield call(API_SERVICE.InternalPage_MetadataList)).data;
    const _metadata = metadata.result;
    const _data = (yield call(API_SERVICE.InternalPage_HierarchicalList, { perfil: 1 })).data.result;
    _metadata.forEach((element: any) => {
      if(element.link && element.key === "portal") {
        element.render = (text:any, record: any) => (
            <div style={{ width: "100%", textAlign: "center" }}>
                <a href={`${element.linkPortal}${record.permanentLink}`} target="_blank" rel="noreferrer">
                    <EyeOutlined style={{ fontSize: "20px", color: "#1890ff" }} />
                </a>
            </div>
        )
      } else if(element.link) {
        element.width = 225;
        element.render = (text: any, record: any) => <Link
          replace
          to={ `/${process.env.REACT_APP_ROUTE_PATH_MAIN_PAGINAS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.id}`}
        >
          {text}
        </Link>;
      } else {

      }
    });

    //const list =
    nestedChildren(_data.items.children);
    let data = { list:_data.items.children, total: _data.total};

    yield put(AInternalPage_Add_MetadataList(_metadata));
    yield put(AInternalPage_Add_HierarchicalList(data));

    action.resolve();
  } catch (error) {
      const err:any = error;
      const errMessage = err.response.data.responseException.exceptionMessage;
      const newErr = Functions.getExeceptions(errMessage);
      action.reject(newErr);
  }
}

function* EInternalPage_Create(action:any):any  {
  try {
    const result = (yield call(API_SERVICE.InternalPage_Create, action.payload)).data;
    action.resolve(result);

  } catch (error) {
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_Get(action:any):any  {
  try{
    const data = (yield call(API_SERVICE.InternalPage_Get, action.payload)).data.result;
    data.data = Functions.ObjectLowerCase(data.data);
    yield put(AInternalPage_Get(data));
    action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_Create_Init(action:any):any  {
  try{
    const data = (yield call(API_SERVICE.InternalPage_Create_Init, action.payload)).data.result;
    if(data.data) data.data = Functions.ObjectLowerCase(data.data);
    yield put(AInternalPage_Get(data));
    action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_Update(action:any):any {
  try{
    const result = (yield call(API_SERVICE.InternalPage_Update, action.payload)).data;
    action.resolve(result);
  }
  catch(error) {
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_HierarchicalList(action:any):any {
  try{
    const _data = (yield call(API_SERVICE.InternalPage_HierarchicalList, action.payload)).data.result;
    //const list =
    nestedChildren(_data.items.children);
    
    let data = { list: _data.items.children, total: _data.total};
    yield put(AInternalPage_Add_HierarchicalList(data));

    action.resolve();
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_Delete(action:any):any  {
  try {
    const message = (yield call(API_SERVICE.InternalPage_Delete, action.payload.ids)).data.message;
    const _data = (yield call(API_SERVICE.InternalPage_HierarchicalList, { perfil: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page })).data.result;
    nestedChildren(_data.items.children);
    let data = { list:_data.items.children, total: _data.total};
    yield put(AInternalPage_Add_HierarchicalList(data));

    action.resolve(message);
  }
  catch(error) {
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_Type(action:any):any {
  try{
    const types = (yield call(API_SERVICE.InternalPage_Type)).data.result;
    yield put(AInternalPage_Add_Type(types))
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

function* EInternalPage_Export_Excel(action:any):any {
  try{
    const data = (yield call(API_SERVICE.InternalPage_Export_Excel, action.payload)).data.result;
    action.resolve(data);
  }
  catch(error){
    const err:any = error;
    const errMessage = err.response.data.responseException.exceptionMessage;
    const newErr = Functions.getExeceptions(errMessage);
    action.reject(newErr);
  }
}

export function* WInternalPage_MetadataList() { yield takeLatest(ActionType.INTERNAL_PAGE_CALL_METADATA_LIST, EInternalPage_MetadataList); }
export function* WInternalPage_Create() { yield takeLatest(ActionType.INTERNAL_PAGE_CALL_CREATE, EInternalPage_Create); }
export function* WInternalPage_Get(){ yield takeLatest(ActionType.INTERNAL_PAGE_CALL_GET, EInternalPage_Get); }
export function* WInternalPage_Create_init(){ yield takeLatest(ActionType.INTERNAL_PAGE_CALL_CREATE_INIT, EInternalPage_Create_Init); }
export function* WInternalPage_Update(){ yield takeLatest(ActionType.INTERNAL_PAGE_CALL_UPDATE, EInternalPage_Update); }
export function* WInternalPage_HierarchicalList(){ yield takeLatest(ActionType.INTERNAL_PAGE_CALL_HIERARCHICALLIST, EInternalPage_HierarchicalList); }
export function* WInternalPage_Delete(){ yield takeLatest(ActionType.INTERNAL_PAGE_CALL_DELETE, EInternalPage_Delete); } 
export function* WInternalPage_Type(){ yield takeLatest(ActionType.INTERNAL_PAGE_CALL_TYPE, EInternalPage_Type); }
export function* WInternalPage_Export_Excel(){ yield takeLatest(ActionType.INTERNAL_PAGE_EXPORT_EXCEL, EInternalPage_Export_Excel); }

/*Internal Functions*/

const nestedChildren = (data:Array<any>) => {
  /*const list = data?.map((item:any) => {
    //const data = Functions.ObjectLowerCase(item);
    if(item.children.length > 0) {
       item.children = nestedChildren(item.children);
    } else {
      delete item.children;
    }

    return item;
  })*/
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if(item.children.length > 0) {
      nestedChildren(item.children);
    } else {
      delete item.children;
    }
  }

  /*for (let i = 0; i < data.length; i++) {
    const item = Functions.ObjectLowerCase(data[i]);
    if(item.children.length > 0) {
      nestedChildren(item.children);
    } else {
      delete item.children;
      //item.children = null;
    }
  }*/

  //return Functions.ListUpperLowerCase(list);
}