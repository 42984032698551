import ActionType from "../constants";

const initialState = {
    status: false,
    isSuper: false,
    activeMessageToConfirm: false,
    loadingButtonFormGeneral: false
}

const RStatusModule = (state = initialState, action: any) => {
    switch(action.type) {
        case ActionType.CONFIRM_CHANGE_MODULE_ADD :
            return {
                ...state,
                status: action.payload.value,
                activeMessageToConfirm: action.payload.verifyRedirect
            };
        case ActionType.IS_SUPER_MODULE_ADD :
            return {    
                ...state,
                isSuper: action.payload
            };
        case ActionType.ACHANGE_VALUE_BUTTONS_GENERAL_ADD:
            return {
                ...state,
                loadingButtonFormGeneral: action.payload
            }
        default:
        return state;
    }
}

export default RStatusModule;  