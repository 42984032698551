import React from 'react';
import locale from 'antd/lib/locale/es_ES';
import Master from './containers/master';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <ConfigProvider locale={locale}>
      <Master/>
      </ConfigProvider>
    </div>
  );
}

export default App;
