//this page is for custom message Errors.
export const CustomMessageErrors = { 
    ExportEmpty: 'No se puede generar un archivo sin data resultante'
}

export const CustomMessageSuccess = {
    FormularioCreado:'Realizado',
    FormularioEditado:'Realizado',
    ModalCreado:'Realizado',
    ModalEditado:'Realizado'
}

export const CustomMessageAlert = {
    changeAlert: 'Tienes cambios sin guardar y serán descartados. ¿Deseas continuar?',
    titleAlert: 'Alerta',
    alertConfirmToImportExcel: 'Los elementos de esta lista serán sobrescritos por los incluidos dentro del archivo cargado'
}

export const CustomMessageButton = {
    btnConfirmAlert: 'Aceptar',
    btnCancelAlert: 'Cancelar'
}

export const CustomMessageAlertDelete = {
    pageZone: '¿Desea Eliminar los siguientes Widgets?',
    onlyOne_pageZone: '¿Desea Eliminar el siguiente Widget?',
    //content
    content_PageZone: '¿Desea Eliminar el siguiente contenido de Widget?',

    internalPage: '¿Desea Eliminar las siguientes Páginas Internas?',
    onlyOne_internalPage: '¿Desea Eliminar la siguiente Pagina Interna?',

    news: '¿Desea Eliminar las siguientes Noticias?',
    onlyOne_news: '¿Desea Eliminar la siguiente Noticia?',

    event: '¿Desea Eliminar los siguientes Eventos?',
    onlyOne_event: '¿Desea Eliminar el siguiente Evento?',

    banner: '¿Desea Eliminar los siguientes Banners?',
    onlyOne_banner: '¿Desea Eliminar el siguiente Banner?',
    //content
    content__baner: '¿Desea Eliminar el siguiente contenido de Banner?',

    gallery: '¿Desea Eliminar las siguientes Galerias?',
    onlyOne_gallery: '¿Desea Eliminar la siguiente Galeria?',
    //content
    content__gallery: '¿Desea Eliminar el siguiente contenido de la Galería?',
    content__gallery_multiple: '¿Desea Eliminar los siguientes contenidos de la Galería?',
    //category
    gallery__category_multiple: '¿Desea Eliminar las siguientes Categorías de Galería?',
    gallery__Category_onlyOne: '¿Desea Eliminar la siguiente Categorías de Galería?',

    form: '¿Desea Eliminar los siguientes Formularios?',
    onlyOne_form: '¿Desea Eliminar el siguiente Formulario?',

    masterPage__Menu: '¿Desea Eliminar los siguientes Menús?',
    onlyOne_masterPage__Menu: '¿Desea Eliminar el siguiente Menú?',

    formListsContent: '¿Desea Eliminar las siguientes listas?',
    onlyOneContent_formLists: '¿Desea Eliminar la siguiente lista?',

    admin: '¿Desea Eliminar los siguientes Administradores?',
    onlyOne_admin: '¿Desea Eliminar el siguiente Administrador?',

    roles: '¿Desea eliminar los siguientes grupos?',
    onlyOne_roles: '¿Desea eliminar el siguiente grupo?',

    htmlTemplate: '¿Desea Eliminar las siguientes plantillas HTML?',
    onlyOne_htmlTemplate: '¿Desea Eliminar la siguiente plantilla HTML?',

    emailTemplate: '¿Desea Eliminar las siguientes Plantillas de Correo?',
    onlyOne_emailTemplate: '¿Desea Eliminar la siguiente Plantilla de Correo?', 
    
    formLists: '¿Desea Eliminar las siguientes Listas de Formulario?',
    onlyOne_formLists: '¿Desea Eliminar la siguiente Lista de Formulario?',

    //e-Learning
    speciality: '¿Desea Eliminar las siguientes Especialidades?',
    onlyOne_speciality: '¿Desea Eliminar la siguiente Especialidad?',

    okText: 'Aceptar',
    cancelText: 'Cancelar'
}

export const CustomMessageAlertRetrieveItem = {
    text: '¿Desea restaurar los siguientes elementos?',
    onlyOne_text: '¿Desea restaurar el siguiente elemento?',
    okText: 'Aceptar',
    cancelText: 'Cancelar'
}