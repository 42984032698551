import React from 'react'; 
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from './../../constants';
import API_SERVICE from './../../../API/api';
import * as Actions from './../../actions';
import { Link } from 'react-router-dom';
import Functions from './../../../functions';
import { APP_SETTINGS } from '../../../constants/app_settings';

function* ECourses_MetadataList(action:any):any {
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.ELEARNING_COURSES_METADATA)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={ "/e-learning/courses/edit/" + record.Id }>{text}</Link>;
                }
            });

            yield put(Actions.AELEARNING_COURSES_ADD_METADATA(metadata));
        }

        const data = (yield call(API_SERVICE.ELEARNING_COURSES_LIST, action.payload)).data.result;
        Functions.ListUpperLowerCase(data.items);
        yield put(Actions.AELEARNING_COURSES_ADD_LIST(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECourses_Get(action:any):any {
    try{
        let readonlyFields:any = {};
        const data = (yield call(API_SERVICE.ELEARNING_COURSES_GET, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        if(data.readonlyFields) 
            data.readonlyFields.forEach((item:string) => {
                let newItem:string = item.toLowerCase();
                readonlyFields[newItem] = newItem;
            })

        data.readonlyFields = readonlyFields;
        yield put(Actions.AELEARNING_COURSES_ADD_GET(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECourses_Update(action:any):any {
    try{
        const message = (yield call(API_SERVICE.ELEARNING_COURSES_UPDATE, action.payload)).data.message;
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECourses_Excel(action:any): any{
    try{
        const excel = (yield call(API_SERVICE.ELEARNING_COURSES_EXPORT_EXCEL, action.payload)).data.result;
        action.resolve(excel);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECourses_Synchronize(action:any):any {
    try{
        const data = (yield call(API_SERVICE.ELEARNING_COURSES_SYNCHRONIZE)).data;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* ECourses_Assign(action:any):any {
    try{
        yield call(API_SERVICE.ELEARNING_COURSES_ASSIGN, action.payload.id, action.payload.data);
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WCourses_MetadataList():any { return yield takeLatest( ActionType.ELEARNING_COURSES_CALL_METADATA, ECourses_MetadataList); }
export function* WCourses_Get():any { return yield takeLatest( ActionType.ELEARNING_COURSES_CALL_GET, ECourses_Get); }
export function* WCourses_Update():any { return yield takeLatest( ActionType.ELEARNING_COURSES_CALL_UPDATE, ECourses_Update); }
export function* WCourses_Excel():any { return yield takeLatest( ActionType.ELEARNING_COURSES_EXPORT_EXCEL, ECourses_Excel); }
export function* WCourses_Synchronize():any { return yield takeLatest( ActionType.ELEARNING_COURSES_SYNCHRONIZE, ECourses_Synchronize); }
export function* WCourses_Assign():any { return yield takeLatest( ActionType.ELEARNING_COURSES_ASSIGN, ECourses_Assign); }