import React, { Suspense } from 'react';
import './../../assets/style/dashboard.scss';
import { Input, Select, Icon, Modal } from 'antd';
import * as Actions from '../../saga/actions';
import { CustomMessageAlert as MessageAlert, CustomMessageButton as MessageButtons } from '../../constants/customMessage';
import { bindActionCreators } from 'redux';
import { Link, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const logoFooter = 'logoMagia';
const InputGroup = Input.Group;
const { Option } = Select;

class Dashboard extends React.Component<any, any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            collapsed: false,
            user: {}
        }
    }

    componentDidMount(){
        const user = (JSON.parse( (localStorage.getItem('user') || {}).toString() ))?.user_info || null;
        //const { normal } = modules.menu;
        user && this.setState({ user });
    }

    componentDidUpdate(){
        const validationIsModuloIsSuper = /(super)/.test(this.props.location.pathname);
        if(!validationIsModuloIsSuper){
            this.props.AIsSuperModule_CALL(false);
        }
    }

    toggleCollapsed = () => {
        this.setState({
          collapsed: !this.state.collapsed,
        });
    };

    onClose = () => {
        // this.setState({
        //   visible: false,
        // });
    };

    beforeOnLogout = (value:any) => {
        const { activeMessageToConfirm } = this.props;
        if(activeMessageToConfirm){
            Modal.confirm({
                title: MessageAlert.titleAlert,
                content: MessageAlert.changeAlert,
                onOk: () => this.handleOptions(value),
                okText: MessageButtons.btnConfirmAlert,
                cancelText: MessageButtons.btnCancelAlert
            });

        } else {
            this.handleOptions(value);
        }
    }

    handleOptions = async (value:any) => {
        await this.props.AConfirmChangeModule_CALL(false, false);
        
        switch(value){
            case "datos":
                console.log(this.props.activeMessageToConfirm);
                this.props.history.push(`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`);
            ;break;
            case "changepass":
                this.props.history.push(`/${process.env.REACT_APP_ROUTE_PATH_MAIN_CAMBIAR_CONTRASENA}`)
            ;break;
            case "logout":    
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                window.location.href = `/${process.env.REACT_APP_ROUTE_PATH_MAIN_LOGIN}`;
            ;break;
        }
    }

    render() {
        const { user } = this.state;
        const nombreCompleto = `${user.firstName} ${user.lastName}`;
        const { moduleSuper } = this.props;
        return (
            <div className="dashboard__container">
                <div className="topbar" style={moduleSuper ? {background:'#1a466e'} : {}}>
                    <div className="menu">
                        {/* <InputGroup compact >
                            <Select defaultValue="Perfil" suffixIcon={ <Icon type="caret-down"/>} value="Perfil">
                            </Select>
                        </InputGroup> */}
                        <InputGroup compact>
                            <Select defaultValue="Admin" suffixIcon={ <Icon type="caret-down" />} value={nombreCompleto} onSelect={(value:string) => this.beforeOnLogout(value)}>
                                <Option value="datos" className='optionLinks'><Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_PROFILE}`} onClick={(e) => e.preventDefault()}> Mis datos </Link></Option>
                                <Option value="changepass" className='optionLinks'><Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_CAMBIAR_CONTRASENA}`} onClick={(e) => e.preventDefault()}> Cambiar contraseña </Link></Option>
                                <Option value="logout" className='optionLinks' > Cerrar sesión </Option>
                            </Select>
                        </InputGroup>
                    </div>
                </div>
                <Switch>
                    {this.props.children}
                </Switch>
                <div className="footer">
                    <div>Desarrollado por</div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Icon component={React.lazy(() => import(`./../../assets/img/${logoFooter}.svg`))} />
                    </Suspense>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => ({
    activeMessageToConfirm: state.statusModule.activeMessageToConfirm,
    moduleSuper: state.statusModule.isSuper
})

const mapDispatchToProps = (dispatch: any) => {
    const { AIsSuperModule_CALL, AConfirmChangeModule_CALL } = Actions;
    return bindActionCreators({
        AIsSuperModule_CALL,
        AConfirmChangeModule_CALL
    }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));