import axios from 'axios';
import * as constant from './url_apis';

export const Gallery_Order_Content = (body:any, header:any) => {
    header.params = body;
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.GALLERY_ORDER_CONTENT, {}, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
           reject(err);
        })
    })
}

export const Gallery_Category_MetadataList = (header:object) => {
    return new Promise((resolve, reject) => {
    axios.get(constant.API_URL + constant.GALLERY_CATEGORY_METADATALIST, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Category_List = (header:object) => {
    return new Promise((resolve, reject) => {
    axios.get(constant.API_URL + constant.GALLERY_CATEGORY_LIST, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Category_Create = (body:object, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.GALLERY_CATEGORY_CREATE, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Category_Update = (body:object, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.GALLERY_CATEGORY_UPDATE, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Category_Delete = (ids: number[], header:any) => {
    header.data = ids;
    return new Promise((resolve, reject) => {
        axios.delete(constant.API_URL + constant.GALLERY_CATEGORY_BATCH_DELETE, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}


export const Gallery_Categories = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve:any, reject:any) => {
        axios.get(API_URL + constants.GALLERY_CATEGORIES, header)
        .then((res:any) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Get = (API_URL: string, constants:any, id: number, header:object) => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + constants.GALLERY_GET + id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Create_Init = (Id:number, header:object) => {
    let params = Id ? `/${Id}` : '';
    return new Promise((resolve:Function, reject:Function) => {
        axios.get(constant.API_URL + constant.GALLERY_CREATE_INIT + params, header)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
}

export const Gallery_Create = (API_URL:string, constants:any, body:object, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + constants.GALLERY_CREATE, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Update = (API_URL:string, constants:any, body:object, header:object) => {
    return new Promise((resolve,reject) => {
        axios.post(API_URL + constants.GALLERY_UPDATE, body, header) 
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Delete = (API_URL: string, constants:any, ids:number[], header:any) => {
    header.data = ids;
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + constants.GALLERY_DELETE, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_List = (API_URL:string, constants:any,body:object ,header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + constants.GALLERY_LIST, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_MetadataList = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject) => {
     axios.get(API_URL + constants.GALLERY_METADATA_LIST, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Export_Excel = (payload:any, header:any) => {
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.GALLERY_EXPORT_EXCEL, payload, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        });
    });
}


export const Gallery_Content_Get = (API_URL: string, constants:any, id: number, header:object) => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + constants.GALLERY_CONTENT_GET + id, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Content_Create = (API_URL:string, constants:any, body:object, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + constants.GALLERY_CONTENT_CREATE, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Content_Update = (API_URL:string, constants:any, body:object, header:object) => {
    return new Promise((resolve, reject) => {
        axios.post(API_URL + constants.GALLERY_CONTENT_UPDATE, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Content_Delete = (API_URL: string, constants:any, data:any, header:any) => {
    const { idGallery, ids } = data;
    header.data = ids;
    return new Promise((resolve, reject) => {
        axios.delete(API_URL + constants.GALLERY_CONTENT_DELETE + idGallery, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Content_List = (API_URL:string, constants:any, body:object, header:object) => {
    return new Promise((resolve, reject) => {
    axios.post(API_URL + constants.GALLERY_CONTENT_LIST, body, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Content_MetadataList = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject) => {
    axios.get(API_URL + constants.GALLERY_CONTENT_METADATA_LIST, header)
         .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}

export const Gallery_Content_Export_Excel = (payload:any, header:any) => {
    return new Promise((resolve, reject) => {
        axios.post(constant.API_URL + constant.GALLERY_CONTENT_EXPORT_EXCEL + payload, {}, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        });
    });
}

export const Gallery_List_Content_Types = (API_URL:string, constants:any, header:object) => {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + constants.GALLERY_CONTENT_LIST_CONTENT_TYPES, header)
        .then((res) => {
            resolve(res);
        })
        .catch((err) => {
            reject(err);
        })
    })
}