export default {
    proyect: 'Fénix CMS',
    /*---------------------------Modules------------------------ */
    dashboard: 'Dashboard',
    news: 'Noticias',
    gallery: 'Galerías Multimedia',
    events: 'Eventos',
    internal: 'Páginas',
    zone_page: 'Widgets',
    banner: 'Banners',
    template_html: 'Plantillas HTML',
    template_email: 'Plantillas de Correo',
    form: 'Formularios',
    masterPage: 'Master Page',
    reports: 'Reportes',
    formLists: 'Listas',
    /*----------------------GENERIC------------------------------ */
    signo: '/',
    create: 'Crear',
    edit: 'Editar', 
    /*----------------Super Administrator------------------------ */
    sa_dashboard: 'Dashboard Super Administrador',
    sa_administradores: 'Administradores',
    sa_recycle: 'Papelera',
    sa_configTags: 'Configuración de Etiquetas',
    sa_roles: 'Roles',
    /*--------------------- e-Learning ----------------------- */
    elm_dashboard: 'Dashboard e-Learning',
    elm_courses: 'Cursos',
    elm_courses_canvas: 'Cursos canvas',
    elm_speciality: 'Especialidades'
}