import { all } from 'redux-saga/effects';
import * as internalPageEffects from './effects/internalPage';
import * as pagezoneEffects from './effects/pagezone';
import * as bannerEffects from './effects/banner';
import * as newsEffects from './effects/news';
import * as galleryEffects from './effects/gallery';
import * as eventEffects from './effects/event';
import * as htmlTemplateEffects from './effects/htmlTemplate';
import * as emailEffects from './effects/emailTemplate';
import * as formEffects from './effects/form';
import * as recycleEffects from './effects/recyclebin';
import * as uploadEffects from './effects/upload';
import * as adminEffects from './effects/admin';
import * as userEffects from './effects/user';
import * as statusModuleEffects from './effects/statusModule';
import * as settingsRRSSEffects from './effects/settings-rrss';
import * as coursesEffects from './effects/e-learning/courses';
import * as groupEffects from './effects/e-learning/group';
import * as canvasEffects from './effects/e-learning/canvas';
import * as commonEffects from './effects/common';
import * as masterPageEffects from './effects/masterPage';
import * as roleEffects from './effects/rol';
import * as reportsEffects from './effects/reports';

export function* rootSaga() {
  yield all([
    userEffects.WUserUpdatePassword(),


    internalPageEffects.WInternalPage_MetadataList(),
    internalPageEffects.WInternalPage_Create(),
    internalPageEffects.WInternalPage_Get(),
    internalPageEffects.WInternalPage_Create_init(),
    internalPageEffects.WInternalPage_Update(),
    internalPageEffects.WInternalPage_HierarchicalList(),
    internalPageEffects.WInternalPage_Delete(),
    internalPageEffects.WInternalPage_Type(),
    internalPageEffects.WInternalPage_Export_Excel(),

    
    pagezoneEffects.WPageZoneGroup_MetadataList(),
    pagezoneEffects.WPageZoneGroup_Get(),
    pagezoneEffects.WPageZoneGroup_Update(),
    pagezoneEffects.WPageZoneGroup_Create_Init(),
    pagezoneEffects.WPageZoneGroup_Create(),
    pagezoneEffects.WPageZoneGroup_Delete(),
    pagezoneEffects.WPageZoneGroup_Excel(),
    pagezoneEffects.WPageZone_Get(),
    pagezoneEffects.WPageZone_Delete(),
    pagezoneEffects.WPageZone_Create(),
    pagezoneEffects.WPageZone_Create_Init(),
    pagezoneEffects.WPageZone_Update(),
    pagezoneEffects.WPageZone_MetadataList(),
    pagezoneEffects.WPageZone_Excel(),


    bannerEffects.WBannerGroup_Create_Init(),
    bannerEffects.WBannerGroup_Create(),
    bannerEffects.WBannerGroup_Get(),
    bannerEffects.WBannerGroup_MetadataList(),
    bannerEffects.WBannerGroup_Update(),
    bannerEffects.WBannerGroup_Delete(),
    bannerEffects.WBanner_Export_Excel(),
    bannerEffects.WBanner_Create(),
    bannerEffects.WBanner_Create_Init(),
    bannerEffects.WBanner_Delete(),
    bannerEffects.WBanner_Get(),
    bannerEffects.WBanner_List(),
    bannerEffects.WBanner_MetadataList(),
    bannerEffects.WBanner_Update(),
    bannerEffects.WBanner_Export_Excel_Content(),

    newsEffects.WNews_Duplicate(),
    newsEffects.WNews_Create(),
    newsEffects.WNews_Get(),
    newsEffects.WNews_Create_Init(),
    newsEffects.WNews_MetadataList(),
    newsEffects.WNews_Update(),
    newsEffects.WNews_Delete(),
    newsEffects.WNews_Category(),
    newsEffects.WNews_Export_Excel(),
    newsEffects.WNews_Update_Settings_Call(),
    newsEffects.WNews_Settings_Call(),

    galleryEffects.WGallery_Category_List(),
    galleryEffects.WGallery_Category_Create(),
    galleryEffects.WGallery_Category_Update(),
    galleryEffects.WGallery_Category_Delete(),
    galleryEffects.WGallery_Content_Get(),
    galleryEffects.WGallery_Content_Create(),
    galleryEffects.WGallery_Content_Update(),
    galleryEffects.WGallery_Content_Metadata_List(),
    galleryEffects.WGallery_Content_Delete(),
    galleryEffects.WGallery_Get(),
    galleryEffects.WGallery_Create_Init(),
    galleryEffects.WGallery_Create(),
    galleryEffects.WGallery_Update(),
    galleryEffects.WGallery_Metadata_List(), 
    galleryEffects.WGallery_Content_Call_Type(),
    galleryEffects.WGallery_Delete(),
    galleryEffects.WGallery_Categories(),
    galleryEffects.WGallery_Export_Excel(),
    galleryEffects.WGallery_Content_Export_Excel(),
    galleryEffects.WGallery_Settings_Call(),
    galleryEffects.WGallery_Update_Settings_Call(),


    eventEffects.WEvent_Create_Init(),
    eventEffects.WEvent_Create(),
    eventEffects.WEvent_Delete(),
    eventEffects.WEvent_Get(),
    eventEffects.WEvent_MetadataList(),
    eventEffects.WEvent_Update(),
    eventEffects.WEvent_CategoryList(),
    eventEffects.WEvent_Export_Excel(),
    eventEffects.WEvent_Update_Settings_Call(),
    eventEffects.WEvent_Settings_Call(),
  

    htmlTemplateEffects.WHtmlTemplate_Create_Init(),
    htmlTemplateEffects.WHtmlTemplate_Create(),
    htmlTemplateEffects.WHtmlTemplate_Delete(),
    htmlTemplateEffects.WHtmlTemplate_Get(),
    htmlTemplateEffects.WHtmlTemplate_ListModule(),
    htmlTemplateEffects.WHtmlTemplate_MetadataList(),
    htmlTemplateEffects.WHtmlTemplate_Update(),
    htmlTemplateEffects.WHtmlTemplate_ListByModule(),
    htmlTemplateEffects.WHtmlTemplate_Export_Excel(),


    emailEffects.WEmailTemplate_Create_Init(),
    emailEffects.WEmailTemplate_Create(),
    emailEffects.WEmailTemplate_Delete(),
    emailEffects.WEmailTemplate_Get(),
    emailEffects.WEmailTemplate_MetadataList(),
    emailEffects.WEmailTemplate_Update(),
    emailEffects.WEmailTemplate_Export_Excel(),


    reportsEffects.WReports_Access_Init(),
    reportsEffects.WReports_Access_Export(),
    reportsEffects.WReports_Audit_Init(),
    reportsEffects.WReports_Audit_Export(),


    formEffects.WForm_Call_Get_Registry(),
    formEffects.WForm_Create_Init(),
    formEffects.WForm_Create(),
    formEffects.WForm_Delete(),
    formEffects.WForm_Get(),
    formEffects.WForm_MetadataList(),
    formEffects.WForm_Update(),
    formEffects.WForm_ListRegistry(),

    formEffects.WForm_Lists_List(),
    formEffects.WForm_Lists_Create(),
    formEffects.WForm_Lists_Delete(),
    formEffects.WForm_Lists_Get(),
    formEffects.WForm_Lists_Update(),
    formEffects.WForm_Lists_Export(),

    formEffects.WForm_Registry_Export_Excel(),
    formEffects.WForm_Export_Excel(),
    formEffects.WForm_Type(),
    formEffects.WForm_Get_Preview(),

    recycleEffects.WRecyclebin_MetadataList(),
    recycleEffects.WRecyclebin_RetrieveItem(),
    recycleEffects.WRecyclebin_Export_Excel(),
    recycleEffects.WRecyclebin_BatchRetrieve(),

    adminEffects.WAdmin_Assign_Roles(),
    adminEffects.WAdmin_MetadataList(),
    adminEffects.WAdmin_Create(),
    adminEffects.WAdmin_Update(),
    adminEffects.WAdmin_Delete(),
    adminEffects.WAdmin_Get(),
    adminEffects.WAdmin_Batch_Pre_Active(),

    roleEffects.WRol_MetadataList(),
    roleEffects.WRol_Get(),
    roleEffects.WRol_Create(),
    roleEffects.WRol_Update(),
    roleEffects.WRol_Delete(),
    roleEffects.WRol_Create_Init(),
    roleEffects.WRol_Export_Excel(),

    coursesEffects.WCourses_Assign(),
    coursesEffects.WCourses_Excel(),
    coursesEffects.WCourses_Get(),
    coursesEffects.WCourses_MetadataList(),
    coursesEffects.WCourses_Synchronize(),
    coursesEffects.WCourses_Update(),

    canvasEffects.WCanvas_Course_MetadataList(),
    canvasEffects.WCanvas_Course_Get(),
    canvasEffects.WCanvas_Course_Update(),
    canvasEffects.WCanvas_Course_Synchronize(),

    groupEffects.WGroup_MetadataList(),
    groupEffects.WGroup_Create(),
    groupEffects.WGroup_Create_Init(),
    groupEffects.WGroup_Delete(),
    groupEffects.WGroup_Excel(),
    groupEffects.WGroup_Get(),
    groupEffects.WGroup_Update(),
    groupEffects.WGroup_Assign(),

    masterPageEffects.WMasterPage_MetadataList(),
    masterPageEffects.WMasterPage_Get(),
    masterPageEffects.WMasterPage_Update(),
    masterPageEffects.WMasterPage_AssignedElement(),
    masterPageEffects.WMasterPage_ExportExcel(),

    settingsRRSSEffects.WSettings_List_RRSS(),
    settingsRRSSEffects.WSettings_Export_Excel_RRSS(),
    settingsRRSSEffects.WSettings_Update_RRSS(),

    uploadEffects.WUpload(),

    statusModuleEffects.WConfirmChangeModule(),
    statusModuleEffects.WIsSuperModule(),
    statusModuleEffects.WChange_Value_Buttons_General(),

    commonEffects.WCommon_Cancel_Creation()
  ]);
}