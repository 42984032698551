export const ListUpperLowerCase = (list:any, funCompoundKey?:Function) => {
    if(list === undefined || list.length === 0) return [];
    let newItem:any = {}; 
    let newItemInitCap:any = {};

    const objectKeys = Object.keys(list[0]);

    for(let i=0; i < objectKeys.length; i++){
        const key:string = objectKeys[i];   
        const firstLetter = key.charAt(0);
        const afterFirstLetter = key.substring(1, key.length);

        const newKey = `${firstLetter.toLowerCase()}${afterFirstLetter}`;
        const newKey2 = `${firstLetter.toUpperCase()}${afterFirstLetter}`;

        newItem = {
            ...newItem, 
            [newKey]:''
        }

        newItemInitCap = {
            ...newItemInitCap,
            [newKey2]: ''
        }
    }

    const newList = [];

    const ObjectNewItem = Object.keys(newItem);
    const ObjectInitCap = Object.keys(newItemInitCap);

    for(let i=0; i < list.length; i++){
        let temp:any = {};

        for(let c=0; c < objectKeys.length; c++){

            let keyNewItem = ObjectNewItem[c];
            let kewInitCap = ObjectInitCap[c];

            let value = list[i][objectKeys[c]]
            temp[keyNewItem] = value;
            temp[kewInitCap] = value;
        }

        if(funCompoundKey) temp['_key'] = funCompoundKey(temp);
        newList.push(temp);
    }

    return newList;
} 

export const ObjectLowerCase = (data:any) => {
    let newItem:any = {}; 
    let temp:any = {};
    
    const objectKeys = Object.keys(data);

    for(var i=0; i < objectKeys.length; i++){
        const key:string = objectKeys[i];
        const newKey = `${key.toLowerCase()}`;

        newItem = {
            ...newItem, 
            [newKey]:''
        }
    }

    const ObjectNewItem = Object.keys(newItem);

    for(var c=0; c < objectKeys.length; c++){

        let keyNewItem = ObjectNewItem[c];
        let value = data[objectKeys[c]]
        temp[keyNewItem] = value;
    }

    return temp;
}

export const ArrayLowerCase = (data: any[] | null | undefined) => {
    if (!Array.isArray(data)) return [];
    return data.map(row => ObjectLowerCase(row));
}