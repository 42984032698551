import { combineReducers } from 'redux';
import formLists from './formLists';
import formTypes from './formTypes';
import form from './form'

const RForm = combineReducers({
    form,
    formTypes,
    formLists
})

export default RForm;