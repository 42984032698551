import Type from "./constants"
import ActionType from './constants';

/**
*  @keyword INTERNAL PAGE
* 
**/

export const AUser_Call_Update_Password = (body: any, resolve: Function, reject: Function) => ({ type: Type.USER_CALL_UPDATE_PASSWORD, payload: body, resolve, reject });

/**
*  @keyword INTERNAL PAGE
* 
**/
export const AInternalPage_Call_MetadataList = (filters:any, resolve: Function, reject: Function) => { return { type: Type.INTERNAL_PAGE_CALL_METADATA_LIST, payload: filters, resolve, reject } }
export const AInternalPage_Add_MetadataList = (body: object) => { return { type: Type.INTERNAL_PAGE_ADD_METADATA_LIST, payload: body } }

export const AInternalPage_Add_List = (body: object) => {
    return { type: Type.INTERNAL_PAGE_ADD_LIST, payload: body }
}

export const AInternalPage_Call_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.INTERNAL_PAGE_CALL_CREATE, payload: body, resolve, reject }
}

export const AInternalPage_Call_Get = (id: Number, resolve: Function, reject: Function) => ({ type: Type.INTERNAL_PAGE_CALL_GET, payload: id, resolve, reject })
export const AInternalPage_Get = (body: object) => ({ type: Type.INTERNAL_PAGE_GET, payload: body })
export const AInternalPage_Create_Init = (Id: number, resolve: any, reject: any) => ({ type: Type.INTERNAL_PAGE_CALL_CREATE_INIT, resolve, reject, payload: Id })


export const AInternalPage_Call_Update = (data: object, resolve: Function, reject: Function) => {
    return { type: Type.INTERNAL_PAGE_CALL_UPDATE, payload: data, resolve, reject }
}

export const AInternalPage_Call_HierarchicalList = (body: object, resolve: Function, reject: Function) => { return { type: Type.INTERNAL_PAGE_CALL_HIERARCHICALLIST, payload: body, resolve, reject } }
export const AInternalPage_Add_HierarchicalList = (body: any) => { return { type: Type.INTERNAL_PAGE_ADD_HIERARCHICALLIST, payload: body } }

export const AInternalPage_Call_Delete = (id: any, resolve: Function, reject: Function) => ({ type: Type.INTERNAL_PAGE_CALL_DELETE, payload: id, resolve, reject })

export const AInternalPage_Call_Type = () => {
    return { type: Type.INTERNAL_PAGE_CALL_TYPE, payload: null }
}

export const AInternalPage_Add_Type = (body: any) => {
    return { type: Type.INTERNAL_PAGE_ADD_TYPE, payload: body }
}

export const AInternalPage_Export_Excel = (body: any, resolve: Function, reject: Function) => ({ type: Type.INTERNAL_PAGE_EXPORT_EXCEL, payload: body, resolve, reject })

/**
*  @keyword PAGE ZONE
* 
**/

export const APageZone_Call_Update = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_CALL_UPDATE, payload: body, resolve, reject }
}

export const APageZone_Call_Delete = (id: number, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_CALL_DELETE, payload: id, resolve, reject }
}

export const APageZone_Call_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_CALL_CREATE, payload: body, resolve, reject }
}

export const APageZone_Call_Create_Init = (id: any, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_CALL_CREATE_INIT, payload: id, resolve, reject }
}

export const APageZone_Call_Get = (id: Number, resolve: Function, reject: Function) => {
    return { type: ActionType.PAGE_ZONE_CALL_GET, payload: id, resolve, reject }
}

export const APageZone_Add_Get = (body: object) => {
    return { type: ActionType.PAGE_ZONE_ADD_GET, payload: body }
}

export const APageZone_Call_MetadataList = (body: object) => {
    return { type: Type.PAGE_ZONE_CALL_METADATALIST, payload: body }
}

export const APageZone_Add_MetadataList = (body: object) => {
    return { type: Type.PAGE_ZONE_ADD_METADATALIST, payload: body }
}

export const APageZone_Add_List = (body: any) => {
    return { type: Type.PAGE_ZONE_ADD_LIST, payload: body }
}
export const APageZone_ExportExcel_Call = (body: object, resolve: Function, reject: Function) => ({ type: Type.PAGE_ZONE_EXPORT_EXCEL_CALL, payload: body, resolve, reject })


export const APageZoneGroup_Create_Init = (Id: number, resolve: Function, reject: Function) => ({ type: Type.PAGE_ZONE_GROUP_CALL_CREATE_INIT, payload: Id, resolve, reject })

export const APageZoneGroup_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_GROUP_CALL_CREATE, payload: body, resolve, reject }
}

export const APageZoneGroup_Update = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_GROUP_CALL_UPDATE, payload: body, resolve, reject }
}

export const APageZoneGroup_Call_Get = (id: Number, resolve: Function, reject: Function) => {
    return { type: Type.PAGE_ZONE_GROUP_CALL_GET, payload: id, resolve, reject }
}

export const APageZoneGroup_Add_Create = (id: number) => {
    return { type: Type.PAGE_ZONE_GROUP_ADD_CREATE, payload: id }
}

export const APageZoneGroup_Get = (body: any) => {
    return { type: Type.PAGE_ZONE_GROUP_GET, payload: body }
}

export const APageZoneGroup_Call_MetadataList = (body: object, resolve: Function, reject: Function, renderMetadata:Function) => ({ type: Type.PAGE_ZONE_GROUP_CALL_METADATA_LIST, payload: body, resolve, reject, renderMetadata })

export const APageZoneGroup_Add_MetadataList = (body: object) => {
    return { type: Type.PAGE_ZONE_GROUP_ADD_METADATA_LIST, payload: body }
}

export const APageZoneGroup_Add_List = (body: object) => {
    return { type: Type.PAGE_ZONE_GROUP_ADD_LIST, payload: body }
}

export const APageZoneGroup_Call_Delete = (id: number, resolve: Function, reject: Function) => ({ type: Type.PAGE_ZONE_GROUP_CALL_DELETE, payload: id, resolve, reject })
export const APageZoneGroup_ExportExcel_Call = (body: object, resolve: Function, reject: Function) => ({ type: Type.PAGE_ZONE_GROUP_EXPORT_EXCEL_CALL, payload: body, resolve, reject })

/**
*  @keyword BANNER
* 
**/

export const ABanner_Call_Create_Init = (id:number, resolve: Function, reject: Function) => ({ type: Type.BANNER_CALL_CREATE_INIT, payload: id, resolve, reject })

export const ABanner_Call_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.BANNER_CALL_CREATE, payload: body, resolve, reject }
}

export const ABanner_Call_Get = (Id: number, resolve: Function, reject: Function) => {
    return { type: Type.BANNER_CALL_GET, payload: Id, resolve, reject }
}

export const ABanner_Add_Get = (data: object) => {
    return { type: ActionType.BANNER_ADD_GET, payload: data }
}

export const ABanner_Call_Update = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.BANNER_CALL_UPDATE, payload: body, resolve, reject }
}

export const ABanner_Call_Delete = (Id: number, resolve: Function, reject: Function) => {
    return { type: ActionType.BANNER_CALL_DELETE, payload: Id, resolve, reject }
}

export const ABanner_Add_List = (body: object) => {
    return { type: ActionType.BANNER_ADD_LIST, payload: body }
}

export const ABanner_Call_Metadata_List = (body: object) => {
    return { type: ActionType.BANNER_CALL_METADATA_LIST, payload: body }
}

export const ABanner_Add_Metadata_List = (body: Array<any>) => {
    return { type: ActionType.BANNER_ADD_METADATA_LIST, payload: body }
}
export const ABanner_Export_Excel_Content = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.BANNER_EXPORT_EXCEL_CONTENT, payload: body, resolve, reject })


export const ABanner_Export_Excel = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.BANNER_EXPORT_EXCEL, payload: body, resolve, reject })

export const ABannerGroup_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => ({ type: Type.BANNER_GROUP_CALL_CREATE_INIT, payload: Id, resolve, reject })

export const ABannerGroup_Call_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.BANNER_GROUP_CALL_CREATE, payload: body, resolve, reject }
}

export const ABanner_Group_Add_Create = (body: number) => {
    return { type: Type.BANNER_GROUP_ADD_CREATE, payload: body }
}

export const ABannerGroup_Call_Update = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.BANNER_GROUP_CALL_UPDATE, payload: body, resolve, reject }
}

export const ABannerGroup_Add_Get = (body: any) => {
    return { type: Type.BANNER_GROUP_GET, payload: body }
}

export const ABannerGroup_Call_Get = (Id: number, resolve: Function, reject: Function) => ({ type: Type.BANNER_GROUP_CALL_GET, payload: Id, resolve, reject })

export const ABannerGroup_Call_MetadataList = (body: object, resolve: Function, reject: Function, openPreview: Function) => ({ type: Type.BANNER_GROUP_CALL_METADATA_LIST, payload: body, resolve, reject, openPreview })
export const ABannerGroup_Add_MetadataList = (body: object) => ({ type: Type.BANNER_GROUP_ADD_METADATA_LIST, payload: body })

export const ABanner_call_List = () => {
    return { type: Type.BANNER_CALL_LIST }
}

export const ABannerGroup_add_List = (body: object) => {
    return { type: Type.BANNER_GROUP_ADD_LIST, payload: body }
}

export const ABannerGroup_Call_Delete = (id: number, resolve: Function, reject: Function) => {
    return { type: Type.BANNER_GROUP_CALL_DELETE, payload: id, resolve, reject }
}

/**
*  @keyword NEWS
* 
**/

export const ANews_Duplicate = (data: any, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_DUPLICATE, payload: data, resolve, reject });

export const ANews_Call_MetadataList = (body: Object, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_METADATA_LIST, payload: body, resolve, reject })
export const ANews_Add_MetadataList = (body: object) => ({ type: Type.NEWS_ADD_METADATA_LIST, payload: body })

export const ANews_Create = (body: object, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_CREATE, payload: body, resolve, reject });
export const ANews_Update = (body: object, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_UPDATE, payload: body, resolve, reject });

export const ANews_Call_Get = (Id: number, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_GET, payload: Id, resolve, reject })
export const ANews_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_CREATE_INIT, resolve, reject, payload: Id })

export const ANews_Add_Get = (body: object) => {
    return { type: Type.NEWS_ADD_GET, payload: body }
}

export const ANews_add_List = (body: object) => {
    return { type: Type.NEWS_ADD_LIST, payload: body }
}

export const ANews_call_Delete = (id: number, resolve: Function, reject: Function) => ({ type: Type.NEWS_CALL_DELETE, payload: id, resolve, reject })

export const ANews_Call_Category = () => {
    return { type: Type.NEWS_CALL_CATEGORY, payload: null }
}

export const ANews_Add_Category = (body: object) => {
    return { type: Type.NEWS_ADD_CATEGORY, payload: body }
}

export const ANews_Export_Excel_Call = (data: object, resolve: Function, reject: Function) => ({ type: Type.NEWS_EXPORT_EXCEL_CALL, payload: data, resolve, reject })
export const ANews_Export_Excel_Add = (data: object) => ({ type: Type.NEWS_EXPORT_EXCEL_ADD, payload: data });
export const ANEWS_SETTINGS_CALL = (resolve:Function, reject:Function) => ({ type: Type.NEWS_SETTINGS_CALL, resolve, reject });
export const ANEWS_SETTINGS_UPDATE_CALL = (data: object, resolve:Function, reject:Function) => ({ type: Type.NEWS_UPDATE_SETTINGS_CALL, payload: data, resolve, reject });

/**
*  @keyword GALLERY
* 
**/


export const AGallery_Category_Call_List = (resolve: Function, reject: Function, extraFunction: Function) => ({ type: Type.GALLERY_CATEGORY_CALL_LIST, payload: {}, resolve, reject, extraFunction });
export const AGallery_Category_Add_List = (payload: any) => ({ type: Type.GALLERY_CATEGORY_ADD_LIST, payload });
export const AGallery_Category_Add_MetadataList = (payload: any) => ({ type: Type.GALLERY_CATEGORY_ADD_METADATALIST, payload });

export const AGallery_Category_Call_Update = (body: any, resolve: Function, reject: Function) => ({ type: Type.GALLERY_CATEGORY_CALL_UPDATE, payload: body, resolve, reject });
export const AGallery_Category_Call_Delete = (id: number[], resolve: Function, reject: Function) => ({ type: Type.GALLERY_CATEGORY_CALL_DELETE, payload: id, resolve, reject });
export const AGallery_Category_Call_Create = (body: any, resolve: Function, reject: Function) => ({ type: Type.GALLERY_CATEGORY_CALL_CREATE, payload: body, resolve, reject });


export const AGallery_Categories_Call = (resolve: Function, reject: Function) => ({ type: Type.GALLERY_CATEGORIES_CALL, resolve, reject })
export const AGallery_Categories_Add = (data: object) => ({ type: Type.GALLERY_CATEGORIES_ADD, payload: data });


export const AGallery_Call_Get = (id: number, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CALL_GET, payload: id, resolve, reject }
}

export const AGallery_Add_Get = (Body: object) => {
    return { type: Type.GALLERY_ADD_GET, payload: Body }
}

export const AGallery_Call_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CALL_CREATE, payload: body, resolve, reject }
}

export const AGallery_Add_Create = (id: number) => {
    return { type: Type.GALLERY_ADD_CREATE, payload: id }
}

export const AGallery_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => ({ type: Type.GALLERY_CALL_CREATE_INIT, payload: Id, resolve, reject });
export const AGallery_Add_Create_Init = (payload: any) => ({ type: Type.GALLERY_ADD_CREATE_INIT, payload });

export const AGallery_Call_Update = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CALL_UPDATE, payload: body, resolve, reject }
}

export const AGallery_Call_Delete = (id: number[], resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CALL_DELETE, payload: id, resolve, reject }
}

export const AGallery_Add_List = (body: object) => {
    return { type: Type.GALLERY_ADD_LIST, payload: body }
}

export const AGallery_Call_MetadataList = (body: object, resolve: Function, reject: Function, activePreview: Function) => ({ type: Type.GALLERY_CALL_METADATA_LIST, payload: body, resolve, reject, activePreview})
export const AGallery_Add_MetadaList = (body: object) => ({ type: Type.GALLERY_ADD_METADATA_LIST, payload: body })

export const AGallery_Content_Call_Get = (id: number, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CONTENT_CALL_GET, payload: id, resolve, reject }
}

export const AGallery_Content_Add_Get = (Body: object) => {
    return { type: Type.GALLERY_CONTENT_ADD_GET, payload: Body }
}

export const AGallery_Content_Call_Create = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CONTENT_CALL_CREATE, payload: body, resolve, reject }
}

export const AGallery_Content_Call_Update = (body: object, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CONTENT_CALL_UPDATE, payload: body, resolve, reject }
}

export const AGallery_Content_Call_Delete = (id: number, resolve: Function, reject: Function) => {
    return { type: Type.GALLERY_CONTENT_CALL_DELETE, payload: id, resolve, reject }
}

export const AGallery_Content_Add_List = (body: object) => {
    return { type: Type.GALLERY_CONTENT_ADD_LIST, payload: body }
}

export const AGallery_Content_Call_MetadataList = (body: object) => {
    return { type: Type.GALLERY_CONTENT_CALL_METADATA_LIST, payload: body }
}

export const AGallery_Content_Add_MetadaList = (body: object) => {
    return { type: Type.GALLERY_CONTENT_ADD_METADATA_LIST, payload: body }
}

export const AGallery_Content_Call_Type = () => {
    return { type: Type.GALLERY_CONTENT_CALL_TYPE }
}

export const AGallery_Content_Add_Type = (Body: object) => {
    return { type: Type.GALLERY_CONTENT_ADD_TYPE, payload: Body }
}

export const AGallery_Content_Export_Excel = (payload: any, resolve: Function, reject: Function) => ({ type: Type.GALLERY_CONTENT_EXPORT_EXCEL, payload, resolve, reject });
export const AGallery_Export_Excel = (payload: any, resolve: Function, reject: Function) => ({ type: Type.GALLERY_EXPORT_EXCEL, payload, resolve, reject });
export const AGALLERY_SETTINGS_CALL = (resolve:Function, reject:Function) => ({ type: Type.GALLERY_SETTINGS_CALL, resolve, reject });
export const AGALLERY_SETTINGS_UPDATE_CALL = (data: object, resolve:Function, reject:Function) => ({ type: Type.GALLERY_UPDATE_SETTINGS_CALL, payload: data, resolve, reject });


/**
*  @keyword EVENTS
* 
**/

export const AEvent_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_CALL_CREATE_INIT, resolve, reject, payload: Id });
export const AEvent_Add_Create_Init = (body: object) => ({ type: ActionType.EVENT_ADD_CREATE_INIT, payload: body });

export const AEvent_Call_Get = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_CALL_GET, payload: id, resolve, reject })
export const AEvent_Add_Get = (body: object) => ({ type: ActionType.EVENT_ADD_GET, payload: body })

export const AEvent_Call_Create = (body: Object, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_CALL_CREATE, payload: body, resolve, reject })
export const AEvent_Call_Update = (body: Object, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_CALL_UPDATE, payload: body, resolve, reject })
export const AEvent_Call_Delete = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_CALL_DELETE, payload: id, resolve, reject })
export const AEvent_Call_Export = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_EXPORT_EXCEL_CALL, payload: body, resolve, reject })

export const AEvent_Add_List = (body: object) => ({ type: ActionType.EVENT_ADD_LIST, payload: body })
export const AEvent_Call_MetadataList = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.EVENT_CALL_METADATA_LIST, payload: body, resolve, reject })
export const AEvent_Add_MetadataList = (body: object) => ({ type: ActionType.EVENT_ADD_METADATA_LIST, payload: body })

export const AEvent_Call_CategoryList = () => ({ type: ActionType.EVENT_CALL_CATEGORY_LIST })
export const AEvent_Add_CategoryList = (body: object) => ({ type: ActionType.EVENT_ADD_CATEGORY_LIST, payload: body })

export const AEVENT_SETTINGS_CALL = (resolve:Function, reject:Function) => ({ type: Type.EVENT_SETTINGS_CALL, resolve, reject });
export const AEVENT_SETTINGS_UPDATE_CALL = (data: object, resolve:Function, reject:Function) => ({ type: Type.EVENT_UPDATE_SETTINGS_CALL, payload: data, resolve, reject });
/**
*  @keyword HTMLTEMPLATE
* 
**/

export const AHtmlTemplate_Call_Get = (Id: Number, resolve: Function, reject: Function) => { return { type: ActionType.HTMLTEMPLATE_CALL_GET, payload: Id, resolve, reject } }
export const AHtmlTemplate_Add_Get = (body: object) => { return { type: ActionType.HTMLTEMPLATE_ADD_GET, payload: body } }
export const AHtmlTemplate_Call_Create = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.HTMLTEMPLATE_CALL_CREATE, payload: body, resolve, reject } }
export const AHtmlTemplate_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => { return { type: ActionType.HTMLTEMPLATE_CALL_CREATE_INIT, payload: Id, resolve, reject } }
export const AHtmlTemplate_Call_Update = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.HTMLTEMPLATE_CALL_UPDATE, payload: body, resolve, reject } }
export const AHtmlTemplate_Call_Delete = (Id: number, resolve: Function, reject: Function) => { return { type: ActionType.HTMLTEMPLATE_CALL_DELETE, payload: Id, resolve, reject } }
export const AHtmlTemplate_Add_List = (body: object) => { return { type: ActionType.HTMLTEMPLATE_ADD_LIST, payload: body } }
export const AHtmlTemplate_Call_MetadataList = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.HTMLTEMPLATE_CALL_METADATA_LIST, payload: body, reject, resolve })
export const AHtmlTemplate_Add_MetadataList = (body: object) => ({ type: ActionType.HTMLTEMPLATE_ADD_METADATA_LIST, payload: body })
export const AHtmlTemplate_Call_ListModules = () => { return { type: ActionType.HTMLTEMPLATE_CALL_LIST_MODULES, payload: null } }
export const AHtmlTemplate_Add_ListModules = (body: object) => { return { type: ActionType.HTMLTEMPLATE_ADD_LIST_MODULES, payload: body } }
export const AHtmlTemplate_Call_ListbyModule = (module: string) => { return { type: ActionType.HTMLTEMPLATE_CALL_LIST_BY_MODULE, payload: module } }
export const AHtmlTemplate_Call_Export_Excel = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.HTMLTEMPLATE_CALL_EXPORT_EXCEL, payload: body, resolve, reject } }
/**
*  @keyword EMAILTEMPLATE
* 
**/

export const AEmailTemplate_Call_Get = (Id: Number, resolve: Function, reject: Function) => { return { type: ActionType.EMAILTEMPLATE_CALL_GET, payload: Id, resolve, reject } }
export const AEmailTemplate_Add_Get = (body: object) => { return { type: ActionType.EMAILTEMPLATE_ADD_GET, payload: body } }

export const AEmailTemplate_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => { return { type: ActionType.EMAILTEMPLATE_CALL_CREATE_INIT, payload: Id, resolve, reject } }
export const AEmailTemplate_Call_Create = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.EMAILTEMPLATE_CALL_CREATE, payload: body, resolve, reject } }
export const AEmailTemplate_Call_Update = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.EMAILTEMPLATE_CALL_UPDATE, payload: body, resolve, reject } }
export const AEmailTemplate_Call_Delete = (Id: number, resolve: Function, reject: Function) => { return { type: ActionType.EMAILTEMPLATE_CALL_DELETE, payload: Id, resolve, reject } }

export const AEmailTemplate_Call_MetadataList = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.EMAILTEMPLATE_CALL_METADATA_LIST, payload: body, resolve, reject })
export const AEmailTemplate_Add_MetadataList = (body: object) => ({ type: ActionType.EMAILTEMPLATE_ADD_METADATA_LIST, payload: body })
export const AEmailTemplate_Add_List = (body: object) => { return { type: ActionType.EMAILTEMPLATE_ADD_LIST, payload: body } }

export const AEmailTemplate_Call_Export_Excel = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.EMAILTEMPLATE_CALL_EXPORT_EXCEL, payload: body, resolve, reject } }
/**
*  @keyword Reports
* 
**/
export const AREPORTS_ACCESS_LISTS_INIT_CALL = (data:any, resolve:Function, reject:Function) => ({type: ActionType.REPORTS_ACCESS_LISTS_INIT_CALL, payload: data, resolve, reject});
export const AREPORTS_ACCESS_LISTS_INIT_ADD = (data:any) => ({type: ActionType.REPORTS_ACCESS_LISTS_INIT_ADD, payload: data});
export const AREPORTS_ACCESS_EXPORTS = (data:any, resolve:Function, reject:Function) => ({type: ActionType.REPORTS_ACCESS_EXPORTS, payload: data, resolve, reject});

export const AREPORTS_AUDIT_LISTS_INIT_CALL = (data:any, resolve:Function, reject:Function) => ({type: ActionType.REPORTS_AUDIT_LISTS_INIT_CALL, payload: data, resolve, reject});
export const AREPORTS_AUDIT_LISTS_INIT_ADD = (data:any) => ({type: ActionType.REPORTS_AUDIT_LISTS_INIT_ADD, payload: data});
export const AREPORTS_AUDIT_EXPORTS = (data:any, resolve:Function, reject:Function) => ({type: ActionType.REPORTS_AUDIT_EXPORTS, payload: data, resolve, reject});

/**
*  @keyword FORM
* 
**/
export const AForm_Call_Get_Registry_File = (data: any, resolve: Function, reject: Function) => ({ type: ActionType.FORM_CALL_GET_REGISTRY_FILE, payload: data, resolve, reject });
export const AForm_Call_Get = (Id: Number, resolve: Function, reject: Function) => { return { type: ActionType.FORM_CALL_GET, payload: Id, resolve, reject } }
export const AForm_Add_Get = (body: object) => { return { type: ActionType.FORM_ADD_GET, payload: body } }
export const AForm_Call_Create_Init = (Id: number, resolve: Function, reject: Function) => ({ type: ActionType.FORM_CALL_CREATE_INIT, payload: Id, resolve, reject })
export const AForm_Add_Create_Init = (body: object) => ({ type: ActionType.FORM_ADD_CREATE_INIT, payload: body })
export const AForm_Call_Create = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.FORM_CALL_CREATE, payload: body, resolve, reject } }
export const AForm_Call_Update = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.FORM_CALL_UPDATE, payload: body, resolve, reject } }
export const AForm_Call_Delete = (Ids: Number[], resolve: Function, reject: Function) => { return { type: ActionType.FORM_CALL_DELETE, payload: Ids, resolve, reject } }
export const AForm_Add_List = (body: object) => { return { type: ActionType.FORM_ADD_LIST, payload: body } }
export const AForm_Call_MetadataList = (payload: object, resolve: Function, reject: Function, extraFunction: Function) => { return { type: ActionType.FORM_CALL_METADATA_LIST, payload, resolve, reject, handlePreview: extraFunction } }
export const AForm_Add_MetadataList = (body: object) => { return { type: ActionType.FORM_ADD_METADATA_LIST, payload: body } }

export const AForm_Lists_Call_List = (body: any, resolve: Function, reject: Function) => { return { type: ActionType.FORM_LISTS_CALL_LIST, payload: body, resolve, reject } }
export const AForm_Lists_Add_List = (body: object) => { return { type: ActionType.FORM_LISTS_ADD_LIST, payload: body } }
export const AForm_Lists_Call_Get = (Id: number, resolve: Function, reject: Function) => { return { type: ActionType.FORM_LISTS_CALL_GET, payload: Id, resolve, reject } }
export const AForm_Lists_Add_Get = (body: any) => { return { type: ActionType.FORM_LISTS_ADD_GET, payload: body } }
export const AForm_Lists_Call_Create = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.FORM_LISTS_CALL_CREATE, payload: body, resolve, reject } }
export const AForm_Lists_Call_Update = (body: object, resolve: Function, reject: Function) => { return { type: ActionType.FORM_LISTS_CALL_UPDATE, payload: body, resolve, reject } }
export const AForm_Lists_Call_Delete = (Id: number, resolve: Function, reject: Function) => { return { type: ActionType.FORM_LISTS_CALL_DELETE, payload: Id, resolve, reject } }
export const AForm_Lists_Call_Export = (data: number, resolve: Function, reject: Function) => { return { type: ActionType.FORM_LISTS_CALL_EXPORT, payload: data, resolve, reject } }

export const AForm_Call_Get_Preview = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.FORM_CALL_GET_PREVIEW, payload: body, resolve, reject })
export const AForm_Add_Get_Preview = (body: object) => ({ type: ActionType.FORM_ADD_GET_PREVIEW, payload: body })
export const AForm_Call_Generate_Form = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.FORM_ADD_GENERATE_FORM, payload: body, resolve, reject })
export const AForm_Add_Generate_Form = (body: object) => ({ type: ActionType.FORM_ADD_GENERATE_FORM, payload: body })

export const AForm_Call_Control_Type = (resolve: Function, reject: Function) => { return { type: ActionType.FORM_CALL_CONTROL_TYPE, resolve, reject } };
export const AForm_Add_List_Behavior = (body: object) => { return { type: ActionType.FORM_ADD_LIST_BEHAVIOR, payload: body } };
export const AForm_Add_Control_Type = (body: object) => { return { type: ActionType.FORM_ADD_CONTROL_TYPE, payload: body } };
export const AForm_Add_Data_Type = (body: object) => { return { type: ActionType.FORM_ADD_DATA_TYPE, payload: body } };
export const AForm_Call_List_Registry = (id: number, resolve: Function, reject: Function, download: Function) => ({ type: ActionType.FORM_CALL_LIST_REGISTRY, payload: id, resolve, reject, FunctionDownload: download })
export const AForm_Add_List_Registry = (body: object) => { return { type: ActionType.FORM_ADD_LIST_REGISTRY, payload: body } }

export const AForm_Call_Registry_Export_Excel = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.FORM_CALL_REGISTRY_EXPORT_EXCEL, payload: body, resolve, reject });
export const AForm_Call_Export_Excel = (body: any, resolve: Function, reject: Function) => ({ type: ActionType.FORM_CALL_EXPORT_EXCEL, payload: body, resolve, reject });

/**
*  @keyword ADMIN
* 
**/

export const ADMIN_ASSIGN_ROLES = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_ASSIGN_ROLES, payload: body, resolve, reject });
export const ADMIN_LIST_ADD = (body: object) => ({ type: ActionType.ADMIN_LIST_ADD, payload: body });
export const ADMIN_METADATA_CALL = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_METADATA_CALL, payload: body, resolve, reject });
export const ADMIN_METADATA_ADD = (body: object) => ({ type: ActionType.ADMIN_METADATA_ADD, payload: body });

export const ADMIN_REGISTER_CALL = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_REGISTER_CALL, payload: body, resolve, reject });
export const ADMIN_UPDATE_CALL = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_UPDATE_CALL, payload: body, resolve, reject });
export const ADMIN_GET_CALL = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_GET_CALL, payload: id, resolve, reject });
export const ADMIN_GET_ADD = (body: object) => ({ type: ActionType.ADMIN_GET_ADD, payload: body });
export const ADMIN_DELETE_CALL = (body: number, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_DELETE_CALL, payload: body, resolve, reject });
export const ADMIN_BATCH_DELETE_CALL = (body: number[], resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_BATCH_DELETE_CALL, payload: body, resolve, reject });
export const ADMIN_BATCH_PRE_ACTIVE = (body: number[], resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_BATCH_PRE_ACTIVE, payload: body, resolve, reject });
export const ADMIN_ACTIVE_ACCOUNT = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ADMIN_ACTIVE_ACCOUNT, payload: body, resolve, reject });

/**
*  @keyword ROL
* 
**/

export const ROL_LIST_ADD = (body: object) => ({ type: ActionType.ROL_LIST_ADD, payload: body });
export const ROL_METADATA_CALL = (body: object, resolve: Function, reject: Function, getGroup:any) => ({ type: ActionType.ROL_METADATA_CALL, payload: body, resolve, reject, getGroup });
export const ROL_METADATA_ADD = (body: object) => ({ type: ActionType.ROL_METADATA_ADD, payload: body });

export const ROL_REGISTER_CALL = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ROL_CREATE_CALL, payload: body, resolve, reject });
export const ROL_UPDATE_CALL = (body: object, resolve: Function, reject: Function) => ({ type: ActionType.ROL_UPDATE_CALL, payload: body, resolve, reject });
export const ROL_GET_CALL = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ROL_GET_CALL, payload: id, resolve, reject });
export const ROL_CREATE_INIT = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ROL_CREATE_INIT, payload: id, resolve, reject });
export const ROL_GET_ADD = (body: object) => ({ type: ActionType.ROL_GET_ADD, payload: body });
export const ROL_BATCH_DELETE_CALL = (body: number[], resolve: Function, reject: Function) => ({ type: ActionType.ROL_BATCH_DELETE_CALL, payload: body, resolve, reject });
export const ROL_EXPORT_EXCEL_CALL = (body: any, resolve: Function, reject: Function) => ({ type: ActionType.ROL_EXPORT_EXCEL_CALL, payload: body, resolve, reject });


/**
*  @keyword COURSES
* 
**/

export const AELEARNING_COURSES_CALL_GET = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_COURSES_CALL_GET, resolve, reject, payload: id });
export const AELEARNING_COURSES_ADD_GET = (data: object) => ({ type: ActionType.ELEARNING_COURSES_ADD_GET, payload: data });
export const AELEARNING_COURSES_CALL_UPDATE = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_COURSES_CALL_UPDATE, resolve, reject, payload: data });
export const AELEARNING_COURSES_ADD_LIST = (data: object) => ({ type: ActionType.ELEARNING_COURSES_ADD_LIST, payload: data });
export const AELEARNING_COURSES_CALL_METADATA = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_COURSES_CALL_METADATA, resolve, reject, payload: data });
export const AELEARNING_COURSES_ADD_METADATA = (data: object) => ({ type: ActionType.ELEARNING_COURSES_ADD_METADATA, payload: data });
export const AELEARNING_COURSES_EXPORT_EXCEL = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_COURSES_EXPORT_EXCEL, resolve, reject, payload: data });
export const AELEARNING_COURSES_SYNCHRONIZE = (resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_COURSES_SYNCHRONIZE, resolve, reject });
export const AELEARNING_COURSES_ASSIGN = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_COURSES_ASSIGN, resolve, reject, payload: data });

/**
*  @keyword CANVAS
* 
**/

export const AELEARNING_CANVAS_COURSE_CALL_GET = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_CALL_GET, resolve, reject, payload: id });
export const AELEARNING_CANVAS_COURSE_ADD_GET = (data: object) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_ADD_GET, payload: data });
export const AELEARNING_CANVAS_COURSE_CALL_UPDATE = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_CALL_UPDATE, resolve, reject, payload: data });
export const AELEARNING_CANVAS_COURSE_ADD_LIST = (data: object) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_ADD_LIST, payload: data });
export const AELEARNING_CANVAS_COURSE_CALL_METADATA = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_CALL_METADATA, resolve, reject, payload: data });
export const AELEARNING_CANVAS_COURSE_ADD_METADATA = (data: object) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_ADD_METADATA, payload: data });
export const AELEARNING_CANVAS_COURSE_SYNCHRONIZE = (resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_CANVAS_COURSE_SYNCHRONIZE, resolve, reject });

/**
*  @keyword GROUP
* 
**/

export const AELEARNING_GROUP_CALL_CREATE_INIT = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_CREATE_INIT, resolve, reject, payload: id });
export const AELEARNING_GROUP_CALL_GET = (id: number, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_GET, resolve, reject, payload: id });
export const AELEARNING_GROUP_ADD_GET = (data: object) => ({ type: ActionType.ELEARNING_GROUP_ADD_GET, payload: data });
export const AELEARNING_GROUP_CALL_CREATE = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_CREATE, resolve, reject, payload: data });
export const AELEARNING_GROUP_CALL_UPDATE = (data: object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_UPDATE, resolve, reject, payload: data });
export const AELEARNING_GROUP_CALL_DELETE = (data: any, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_DELETE, resolve, reject, payload: data });
export const AELEARNING_GROUP_ADD_LIST = (data: object) => ({ type: ActionType.ELEARNING_GROUP_ADD_LIST, payload: data });
export const AELEARNING_GROUP_CALL_METADATA = (data:object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_METADATA, resolve, reject, payload: data});
export const AELEARNING_GROUP_ADD_METADATA = (data:object) => ({ type: ActionType.ELEARNING_GROUP_ADD_METADATA, payload: data});
export const AELEARNING_GROUP_EXPORT_EXCEL = (data:object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_EXPORT_EXCEL, resolve, reject, payload: data});
export const AELEARNING_GROUP_CALL_ASSIGN= (data:object, resolve: Function, reject: Function) => ({ type: ActionType.ELEARNING_GROUP_CALL_ASSIGN, resolve, reject, payload: data});

/**
*  @keyword MASTERPAGE
* 
**/

export const AMASTERPAGE_CALL_METADATA = (data:object, resolve:Function, reject:Function) => ({ type: ActionType.MASTER_PAGE_CALL_METADATA, payload:data, resolve, reject });
export const AMASTERPAGE_ADD_METADATA = (data:object) => ({ type: ActionType.MASTER_PAGE_ADD_METADATA, payload:data });
export const AMASTERPAGE_ADD_LIST = (data:object) => ({ type: ActionType.MASTER_PAGE_ADD_LIST, payload:data });
export const AMASTERPAGE_CALL_GET = (data:any, resolve:Function, reject:Function) => ({ type: ActionType.MASTER_PAGE_CALL_GET, payload:data, resolve, reject });
export const AMASTERPAGE_ADD_GET = (data:object) => ({ type: ActionType.MASTER_PAGE_ADD_GET, payload:data });
export const AMASTERPAGE_CALL_UPDATE = (data:object, resolve:Function, reject:Function) => ({ type: ActionType.MASTER_PAGE_CALL_UPDATE, payload:data, resolve, reject });
export const AMASTER_PAGE_ASSIGNED_ELEMENT = (data:object, resolve:Function, reject:Function) => ({ type: ActionType.MASTER_PAGE_ASSIGNED_ELEMENT, payload:data, resolve, reject });
export const AMASTER_PAGE_CALL_EXPORT_EXCEL = (data:object, resolve:Function, reject:Function) => ({ type: ActionType.MASTER_PAGE_CALL_EXPORT_EXCEL, payload:data, resolve, reject });

/**
*  @keyword RECYCLEBIN
* 
**/

export const ARecyclebin_Add_List = (body: any) => ({ type: ActionType.RECYCLEBIN_ADD_LIST, payload: body });
export const ARecyclebin_Add_Modules = (body: any) => ({ type: ActionType.RECYCLEBIN_ADD_MODULES, payload: body });
export const ARecyclebin_Call_RetrieveItem = (data: any, resolve: Function, reject: Function) => ({ type: ActionType.RECYCLEBIN_CALL_RETRIEVEITEM, payload: data, resolve, reject });
export const ARecyclebin_Call_BatchRetrieve = (data: any, resolve: Function, reject: Function) => ({ type: ActionType.RECYCLEBIN_CALL_BATCHRETRIEVE, payload: data, resolve, reject });
export const ARecyclebin_Call_MetadataList = (data: any, funcRestore: Function, resolve: Function, reject: Function) => ({ type: ActionType.RECYCLEBIN_CALL_METADATALIST, payload: data, funcRestore, resolve, reject });
export const ARecyclebin_Add_MetadataList = (data: any) => ({ type: ActionType.RECYCLEBIN_ADD_METADATALIST, payload: data });
export const ARecyclebin_Export_Excel = (data: any, resolve: Function, reject: Function) => ({ type: ActionType.RECYCLEBIN_EXPORT_EXCEL, payload: data, resolve, reject });

/**
*  @keyword settings
* 
**/

export const ASettings_List_RRSS = (resolve: Function, reject: Function) => ({ type: Type.SETTINGS_LIST_RRSS, resolve, reject });
export const ASettings_List_RRSS_ADD = (data: any) => ({ type: Type.SETTINGS_LIST_RRSS_ADD, payload: data });
export const ASettings_Update = (data: any, resolve: Function, reject: Function) => ({ type: Type.SETTINGS_UPDATE, payload: data, resolve, reject });
export const ASettings_Export_Excel_RRSS = (resolve: Function, reject: Function) => ({ type: Type.SETTINGS_EXPORTA_EXCEL_RRSS, resolve, reject });

/**
*  @keyword UPLOAD
* 
**/

export const AUpload = (body: FormData, resolve: Function, reject: Function, file: string) => ({ type: Type.UPLOAD_CALL, payload: body, resolve, reject, file });


/**
*  @keyword extra - change module
* 
**/

export const AConfirmChangeModule_CALL = (value: boolean, verifyRedirect: boolean) => ({ type: Type.CONFIRM_CHANGE_MODULE_CALL, payload: {value, verifyRedirect} });
export const AConfirmChangeModule_ADD = (value:any) => ({ type: Type.CONFIRM_CHANGE_MODULE_ADD, payload: value });
export const AIsSuperModule_CALL = (value: boolean) => ({ type: Type.IS_SUPER_MODULE_CALL, payload: value });
export const AIsSuperModule_ADD = (value: boolean) => ({ type: Type.IS_SUPER_MODULE_ADD, payload: value });
export const ACHANGE_VALUE_BUTTONS_GENERAL_ADD = (value: boolean) => ({ type: Type.ACHANGE_VALUE_BUTTONS_GENERAL_ADD, payload: value })
export const ACHANGE_VALUE_BUTTONS_GENERAL_CALL = (value: boolean) => ({ type: Type.ACHANGE_VALUE_BUTTONS_GENERAL_CALL, payload: value })

/**
*  @keyword COMMON module
* 
**/

export const ACommmon_Cancel_Creation = (value: string, resolve: Function, reject: Function) => ({ type: Type.COMMON_CANCEL_CREATION, payload: value, resolve, reject })
