import React from 'react'; 
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from './../../constants';
import API_SERVICE from './../../../API/api';
import * as Actions from './../../actions';
import { Link } from 'react-router-dom';
import Functions from './../../../functions';
import { APP_SETTINGS } from '../../../constants/app_settings';

function* EGroup_MetadataList(action:any):any {
    try{
        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.ELEARNING_GROUP_METADATA)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={ "/e-learning/speciality/edit/" + record.Id }>{text}</Link>;
                }
            });

            yield put(Actions.AELEARNING_GROUP_ADD_METADATA(metadata));
        }

        const data = (yield call(API_SERVICE.ELEARNING_GROUP_LISTA, action.payload)).data.result;
        yield put(Actions.AELEARNING_GROUP_ADD_LIST(data));

        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Create(action:any):any {
    try{
        const data = (yield call(API_SERVICE.ELEARNING_GROUP_CREATE, action.payload)).data.message;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Update(action:any):any {
    try{
        const data = (yield call(API_SERVICE.ELEARNING_GROUP_UPDATE, action.payload)).data.message;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Get(action:any):any {
    try{
        const data = (yield call(API_SERVICE.ELEARNING_GROUP_GET, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        yield put(Actions.AELEARNING_GROUP_ADD_GET(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Create_Init(action:any):any {
    try{
        const data = (yield call(API_SERVICE.ELEARNING_GROUP_CREATE_INIT, action.payload)).data.result;
        if(data.data) data.data = Functions.ObjectLowerCase(data.data);
        yield put(Actions.AELEARNING_GROUP_ADD_GET(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Delete(action:any):any {
    const searchList = {profile: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page};
    try{
        const message = (yield call(API_SERVICE.ELEARNING_GROUP_BATCH_DELETE, action.payload)).data.message;
        const data = (yield call(API_SERVICE.ELEARNING_GROUP_LISTA, searchList)).data.result;
        yield put(Actions.AELEARNING_GROUP_ADD_LIST(data));

        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Excel(action:any): any{
    try{
        const excel = (yield call(API_SERVICE.ELEARNING_GROUP_EXPORT_EXCEL, action.payload)).data.result;
        action.resolve(excel);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EGroup_Assign(action:any):any {
    try{
        const { payload: { id, data } } = action;
        const message = (yield call(API_SERVICE.ELEARNING_COURSES_ASSIGN, id, data)).data.message;
        action.resolve(message);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WGroup_MetadataList():any { return yield takeLatest( ActionType.ELEARNING_GROUP_CALL_METADATA, EGroup_MetadataList); }
export function* WGroup_Create():any { return yield takeLatest(ActionType.ELEARNING_GROUP_CALL_CREATE, EGroup_Create); }
export function* WGroup_Update():any { return yield takeLatest(ActionType.ELEARNING_GROUP_CALL_UPDATE, EGroup_Update); }
export function* WGroup_Get():any { return yield takeLatest(ActionType.ELEARNING_GROUP_CALL_GET, EGroup_Get); }
export function* WGroup_Create_Init():any { return yield takeLatest(ActionType.ELEARNING_GROUP_CALL_CREATE_INIT, EGroup_Create_Init); }
export function* WGroup_Delete():any { return yield takeLatest(ActionType.ELEARNING_GROUP_CALL_DELETE, EGroup_Delete); }
export function* WGroup_Excel():any { return yield takeLatest(ActionType.ELEARNING_GROUP_EXPORT_EXCEL, EGroup_Excel); }
export function* WGroup_Assign():any { return yield takeLatest(ActionType.ELEARNING_GROUP_CALL_ASSIGN, EGroup_Assign); }