import React from 'react';
import { takeLatest, call, put } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import { AHtmlTemplate_Add_Get, AHtmlTemplate_Add_List, AHtmlTemplate_Add_ListModules } from '../actions';
import * as Actions from '../actions';
import { Link } from 'react-router-dom';
import Functions from '../../functions';
import { APP_SETTINGS } from '../../constants/app_settings';

function* EHtmlTemplate_Get(action:any):any {
    try{
        const data = (yield call(API_SERVICE.HtmlTemplate_Get, action.payload)).data.result;
        data.data = Functions.ObjectLowerCase(data.data);
        yield put(AHtmlTemplate_Add_Get(data));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EHtmlTemplate_Create_Init(action:any):any {
    try{
        const data = (yield call(API_SERVICE.HtmlTemplate_Create_Init, action.payload)).data.result;
        const temp = Functions.ObjectLowerCase(data);
        yield put(AHtmlTemplate_Add_Get(temp));
        action.resolve();
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EHtmlTemplate_Update(action:any):any {
    try{
        const result = (yield call(API_SERVICE.HtmlTemplate_Update, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EHtmlTemplate_Create(action:any):any {
    try{
        const result = (yield call(API_SERVICE.HtmlTemplate_Create, action.payload)).data;
        action.resolve(result);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EHtmlTemplate_Delete(action:any):any {
    try{
        const message = (yield call(API_SERVICE.HtmlTemplate_Delete, action.payload.ids)).data.message;
        const templateData = {perfil: 1, pageNumber: action.payload.current, pageSize: APP_SETTINGS().items_per_page};
        const data = (yield call(API_SERVICE.HtmlTemplate_List, templateData)).data.result;
        yield put (Actions.AHtmlTemplate_Add_List(data));
        action.resolve(message);
    } catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EHtmlTemplate_ListByModule(action:any):any {
    try{
        const data = (yield call(API_SERVICE.HtmlTemplate_ListByModule, action.payload)).data.result;
        yield put(AHtmlTemplate_Add_List(data));
    }
    catch(e){

    }
}

function* EHtmlTemplate_MetadataList(action:any):any {
    try{

        if(action.payload === undefined) {
            //este caso es solo para que se liste por primera vez.
            action.payload = {perfil: 1, pageNumber: 1, pageSize: APP_SETTINGS().items_per_page}
            const metadata = (yield call(API_SERVICE.HtmlTemplate_MetadataList)).data.result;
            metadata.forEach((element: any) => {
                if(element.link) {
                  element.render = (text: any, record: any) => <Link to={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_HTML_TEMPLATE}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/${record.id}`}>{text}</Link>;
                }
            });

            yield put (Actions.AHtmlTemplate_Add_MetadataList(metadata));
        }

        const data = (yield call(API_SERVICE.HtmlTemplate_List, action.payload)).data.result;
        yield put (Actions.AHtmlTemplate_Add_List(data));

        action.resolve();
    } 
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

function* EHtmlTemplate_ListModule(action:any): any{
    try{
        const data = (yield call(API_SERVICE.HtmlTemplate_ListModules)).data.result;
        yield put(AHtmlTemplate_Add_ListModules(data));
    }
    catch{

    }
}

function* EHtmlTemplate_Export_Excel(action:any): any{
    try{
        const excel = (yield call(API_SERVICE.HtmlTemplate_Export_Excel, action.payload)).data.result;
        action.resolve(excel);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WHtmlTemplate_Create_Init():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_CREATE_INIT, EHtmlTemplate_Create_Init); }
export function* WHtmlTemplate_Get():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_GET, EHtmlTemplate_Get); }
export function* WHtmlTemplate_Update():any { return  yield takeLatest(ActionType.HTMLTEMPLATE_CALL_UPDATE, EHtmlTemplate_Update); }
export function* WHtmlTemplate_Create():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_CREATE, EHtmlTemplate_Create); }
export function* WHtmlTemplate_Delete():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_DELETE, EHtmlTemplate_Delete); }
export function* WHtmlTemplate_MetadataList():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_METADATA_LIST, EHtmlTemplate_MetadataList); }
export function* WHtmlTemplate_ListModule():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_LIST_MODULES, EHtmlTemplate_ListModule); }
export function* WHtmlTemplate_ListByModule():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_LIST_BY_MODULE, EHtmlTemplate_ListByModule); }
export function* WHtmlTemplate_Export_Excel():any { return yield takeLatest(ActionType.HTMLTEMPLATE_CALL_EXPORT_EXCEL, EHtmlTemplate_Export_Excel); }