import React from 'react';
import './../assets/style/dashboard.scss';
import { Route, Switch } from 'react-router-dom';

import { 
    adminDashboard,
    adminRecycle,
    adminList,
    adminForm,
    settings
} from '../modules/loadable';

class Admin extends React.Component<any, any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <>
                <Switch>
                    {/*--------------------------------------------------------------- */}
                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN_RECYCLE}`} component={adminRecycle}/>

                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN_USERS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_EDIT}/:id`} component={adminForm}/>                    
                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN_USERS}/${process.env.REACT_APP_ROUTE_PATH_MAIN_GENERIC_FORM_CREATE}`} component={adminForm}/>
                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN_USERS}`} component={adminList}/>

                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN_CONFIG}`} component={settings}/>
                    <Route path={`/${process.env.REACT_APP_ROUTE_PATH_MAIN_ADMIN}`} component={adminDashboard}/>
                </Switch>
            </>
        );
    }
}

export default Admin;