import { takeLatest, call } from 'redux-saga/effects';
import ActionType from '../constants';
import API_SERVICE from '../../API/api';
import Functions from '../../functions';

function* ECommon_Cancel_Creation(action:any):any {
    try{
        const data = (yield call(API_SERVICE.Commmon_Cancel_Creation, action.payload)).data;
        action.resolve(data);
    }
    catch(error){
        const err:any = error;
        const errMessage = err.response.data.responseException.exceptionMessage;
        const newErr = Functions.getExeceptions(errMessage);
        action.reject(newErr);
    }
}

export function* WCommon_Cancel_Creation():any { return yield takeLatest( ActionType.COMMON_CANCEL_CREATION, ECommon_Cancel_Creation); }
