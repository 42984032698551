import React from 'react';
import { message } from 'antd';
//plantilla para las funciones

export const getExeception = (err:any) => {
    if(err.errors){
        const values = Object.entries(err.errors);
        const getMessages: Array<string> = [];

        
        values?.forEach((item:any) => {
            const newMessages = item[1];
            let temp = "";
            newMessages?.map((message:any) => temp += `${message} <br/> `);
            getMessages.push(temp);
        })

        return getMessages;
    }
    else if(err.message === 'Not Authorized'){
        return 'Usuario no autorizado';
    }
    else if(err.exceptionMessage) {
        return `${err.exceptionMessage}`;
    }
    else {
        return err;
    }
}

export const showException = (err:any) => {
    if(Array.isArray(err)) {
        /*err.forEach((item:any) => {
            const msgError = <div dangerouslySetInnerHTML={{__html: item}}/>
            message.error(msgError)
        });*/
        const allMessages:string = err.join(' ');
        message.error( <div dangerouslySetInnerHTML={{__html: allMessages}}/> )
    }
    else
        message.error(err);
}