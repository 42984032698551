import ActionType from "./../../constants";

const initialState = {

};

const RGroup = (state = initialState, action: any) => {
    switch (action.type) {
        case ActionType.ELEARNING_GROUP_ADD_GET:
            return {
                ...state,
                onlyOne: action.payload
            }
        case ActionType.ELEARNING_GROUP_ADD_METADATA:
            return {
                ...state,
                metadata: action.payload
            }
        case ActionType.ELEARNING_GROUP_ADD_LIST:
            return {
                ...state,
                list: action.payload
            }
        default:
            return state
    }
}

export default RGroup;