import React from 'react';
import Logo from './../assets/img/logo.svg';
import './../assets/style/login.scss';
// import './../style/login.scss';
//import logo from './../assets/img/logo.svg';
// import { Switch, Route } from 'react-router'

interface ILoginWrapper {
    Title: Function,
    children: React.ReactNode
}

class LoginWrapper extends React.PureComponent<ILoginWrapper> {

    render() {
        return (
            <div className="login__container">
                <div className="panel">
                    <div className="panel__info">
                        {this.props.Title()}
                    </div>
                    <div className="form__container">
                        <div className="header">
                            <div>
                                <Logo />
                            </div>
                        </div>
                        <div className="body">
                            <div className="title">
                                <h2>Administrador</h2>
                            </div>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoginWrapper;